import { makeRequest } from '@/utils/makeRequest'

// Harcode here...
const page_size = 10

const state = {
    count: 0,
    listData: {
        items: [],
        pagination: {}
    },
    notificationsNew: {
        items: []
    },
    isListPageRequesting: false,
    isListPagePaginationRequesting: false,
    singleData: null,
    isSingleDataRequesting: false
}

const getters = {

}

const actions = {
    // Dispatched from Footer
    fetchNotificationsCount: async ({ commit, state }) => {
        try {
            const response = await makeRequest('get', 'api-notification-count')
            const { data: {
                count
            } } = response

            commit('setNotificationCount', count)
        } catch(err) {
            throw err
        }
    },
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchNotificationsOnLoad: async ({ commit, state }, params = {}) => {
        commit('setListRequestingFlag', true)

        try {
            // two calls in parallel, one for new and one for old notifications
            const [responseUnseen, responseSeen] = await Promise.all([

                makeRequest('get', 'api-notifications', { ...params, 'seen': false, 'page_size': 'all' }),
                makeRequest('get', 'api-notifications', { ...params, 'seen': true, page_size })
                
            ])

            const dataUnseen = responseUnseen.data
            const dataSeen = responseSeen.data

            commit('setNotificationsNew', dataUnseen)
            commit('setListData', dataSeen)
            
            commit('setListRequestingFlag', false)

        } catch(err) {
            commit('setListRequestingFlag', false)
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchNotificationsPaginated: async ({ commit, state }, params = {}) => {
        commit('setListPaginationRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api-notifications', { ...params, 'seen': true, page_size })
            const { data } = response

            // This will set merge items & update pagination
            commit('setListPaginatedData', data)
            commit('setListPaginationRequestingFlag', false)
        } catch(err) {
            commit('setListPaginationRequestingFlag', false)
            throw err
        }
    },
    // Single Data
    // Dispatched from router
    fetchSingleNotification: async ({ commit, state, dispatch }, params = {}) => {
        commit('setSingleNotificationRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api-notification-detail', params)
            const { data } = response

            // Set single data store value
            commit('setSingleNotificationData', data)
            commit('setSingleNotificationRequestingFlag', false)

            // Update count in footer
            dispatch('fetchNotificationsCount')
        } catch(err) {
            commit('setSingleNotificationRequestingFlag', false)
            throw err
        }
    },
    markAllAsSeen: async ({ commit, state, dispatch }) => {
        commit('setListRequestingFlag', true)

        try {
            // request to mark all notifications as seen
            await makeRequest('get', 'api-notification-mark-as-seen')

            // update the screen and update the counter
            dispatch('fetchNotificationsOnLoad')
            dispatch('fetchNotificationsCount')

        } catch(err) {
            commit('setListRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    setNotificationCount: (state, value) => {
        state.count = value
    },
    // Onload
    setListRequestingFlag: (state, value) => {
        state.isListPageRequesting = value
    },
    setListData: (state, listData) => {
        state.listData = listData
    },
    setNotificationsNew: (state, data) => {
        state.notificationsNew = data
    },
    // Pagination
    setListPaginationRequestingFlag: (state, value) => {
        state.isListPagePaginationRequesting = value
    },
    setListPaginatedData: (state, listData) => {
        const { items, pagination } = listData
        state.listData = {
            pagination,
            items: [ ...state.listData.items, ...items ]
        }
    },
    // Single Notification
    setSingleNotificationRequestingFlag: (state, value) => {
        state.isSingleDataRequesting = value
    },
    setSingleNotificationData: (state, data) => {
        state.singleData = data
    },
    setSingleNotificationSeenValue: (state, value) => {
        state.singleData = {
            ...state.singleData,
            is_seen: value
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
