<template>
	<div class="settings-page">
        <Header :pageTitle="pageTitle"/>
            <Loader v-if="isRequesting" />
            <div class="settings-container" :class="{ 'disabled': !selectedCompanyNumber }" v-else>
                <div class="alert alert-error" v-if="!selectedCompanyNumber">
                    {{'on_site.settings.missing_selected_company_info'.trans()}}
                </div>
                <div class="setting-option">
                    <div class="white">{{'on_site.settings.email_settings'.trans()}}</div>
                    <div class="setting-container" :class="{ 'requesting': requestingFlags.CallOff.value }">
                        <div class="text">
                            <p class="title">{{'on_site.settings.calloff_emails'.trans()}}</p>
                            <p class="info">{{'on_site.settings.calloff_emails_info'.trans()}}</p>
                        </div>
                        <div class="toggler" @click="handleSettingChange('CallOff')">
                            <div class="toggler-button" :class="{ 'active': settings.CallOff }"></div>
                        </div>
                    </div>
                    <div class="setting-container" :class="{ 'requesting': requestingFlags.Reminder.value }">
                        <div class="text">
                            <p class="title">{{'on_site.settings.reminder_emails'.trans()}}</p>
                            <p class="info">{{'on_site.settings.reminder_emails_info'.trans()}}</p>
                        </div>
                        <div class="toggler" @click="handleSettingChange('Reminder')">
                            <div class="toggler-button" :class="{ 'active': settings.Reminder }"></div>
                        </div>
                    </div>
                </div>


                <div class="setting-option">
                    <div class="white">{{'on_site.settings.messages'.trans()}}</div>
                    <div class="setting-container" :class="{ 'requesting': requestingFlags.PushNotification.value }">
                    <div class="text">
                        <p class="title">{{'on_site.settings.push_notifications'.trans()}}</p>
                        <p class="info">{{'on_site.settings.push_notifications_info'.trans()}}</p>
                    </div>
                    <div class="toggler" @click="handleSettingChange('PushNotification')">
                        <div class="toggler-button" :class="{ 'active': settings.PushNotification }"></div>
                    </div>
                </div>
                </div>

                <div class="setting-option">
                    <div class="white">{{'on_site.settings.design'.trans()}}</div>
                    <div class="design-setting-container" :class="{ 'requesting': requestingFlags.Theme.value }">
                        <button
                            class="design-theme-button"
                            v-for="button in themeOptions"
                            :key="`item-${button.id}`"

                            :disabled="settings.Theme == button.id"
                            :class="{ 'active': settings.Theme == button.id}"
                            @click="handleThemeChange(button.id)"
                        >
                            {{ button.text }}
                        </button>
                    </div>
                </div>
            </div>
        <Footer/>
	</div>
</template>

<script setup>
import Header from '@/components/Common/Header.vue'
import Footer from '@/components/Common/Footer.vue'
import Loader from '@/components/Common/Loader.vue'

import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { makeRequest } from '@/utils/makeRequest.js'

import OneSignalHelper from '@/utils/OneSignalHelper'

const store = useStore()
const pageTitle = 'on_site.page-title.settings'.trans()

// View specific
const storeValues = store.state.settings

const selectedCompanyNumber = computed(() =>
    store.state.user.selectedCompany
        ? store.state.user.selectedCompany.company_nr
        : null
)

// Page load flag
const isRequesting = computed(() => storeValues.isRequesting)

// Computed prop for store connected values
// Can't contain flags, since whole object must be "reactive"
const settings = computed(() => ({
    CallOff: storeValues.data && storeValues.data.CallOff,
    Reminder: storeValues.data && storeValues.data.Reminder,
    Tips: storeValues.data && storeValues.data.Tips,
    PushNotification: storeValues.data && storeValues.data.PushNotification,
    Theme: storeValues.data && storeValues.data.Theme
}))

// Button array for themes
const themeOptions = computed(() => storeValues.options.design && storeValues.options.design.theme.selector)

// Requesting flags
const requestingFlags = {
    CallOff: ref(false),
    Reminder: ref(false),
    Tips: ref(false),
    PushNotification: ref(false),
    Theme: ref(false)
}

// Change handler
const handleSettingChange = async (settingName) => {
    const requestingFlag = requestingFlags[settingName]
    
    // Already requesting
    if (requestingFlag.value) {
        return
    }

    requestingFlag.value = true

    try {
        // Toggle value
        const newValue = !storeValues.data[settingName]

        // Make request
        await makeRequest('post', 'api_update_profile_settings', {
            type: settingName,
            status: newValue,
        })

        // Update store values => this will re-render view
        store.dispatch('settings/updateUserSingleSetting', {
            settingName,
            value: newValue
        })

        // Handle change for push notifications
        if (settingName === 'PushNotification') {
            store.dispatch('user/updateNotificationSubscriptionState', newValue)

            if(newValue) {
                // Show notification permission
                OneSignalHelper.setUserSubscription()

                // Subscribe user
                OneSignalHelper.subscribeUser()
            } else {

                // Unsubscribe user
                OneSignalHelper.unSubscribeUser()
            }
        }

        requestingFlag.value = false
    } catch(err) {
        requestingFlag.value = false
        throw err
    }
}

const handleThemeChange = async (statusValue) => {
    const settingName = 'Theme'
    const requestingFlag = requestingFlags[settingName]

    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    
    // Already requesting
    if (requestingFlag.value) {
        return
    }

    requestingFlag.value = true

    try {
        // Make request
        await makeRequest('post', 'api_update_profile_settings', {
            type: settingName,
            status: statusValue,
        })

        // Update store values => this will re-render view
        store.dispatch('settings/updateUserSingleSetting', {
            settingName,
            value: statusValue
        })

        // Update dark mode value is userData
        // => this will change class on app-container (in App.vue)
        if (statusValue == 30) {
            store.commit('settings/setDarkMode', true)
        } else if(statusValue == 20) {
            store.commit('settings/setDarkMode', false)
        } else if(statusValue == 10) {
            if(isDarkMode) {
                store.commit('settings/setDarkMode', true)
            } else {
                store.commit('settings/setDarkMode', false)
            }
        }

        store.dispatch('user/updateSelectedTheme', statusValue)

        requestingFlag.value = false
    } catch(err) {
        requestingFlag.value = false
        throw err
    }
}
</script>

<style lang="sass" scoped>
.settings-page
    padding-top: 51px
    padding-bottom: 81px
    min-height: calc(100vh - 51px - 81px)

    .loader-container
        padding-top: 10vh

    .settings-container
        margin: 10px auto 0 auto
        padding-left: 20px
        padding-right: 20px
        width: 90%
        min-width: 250px

        @media (max-width: 480px)
            width: 100%

        .white
            align-items: center
            border-radius: 3px
            box-sizing: border-box
            color: $text-dark-gray
            display: flex
            font-family: PoppinsMedium
            font-size: 16px
            height: 50px
            text-align: left
            width: 100%

        .setting-option
            &:not(:last-child)
                .white
                    border-bottom: 1px solid $gray-blue

        .setting-container
            align-items: center
            border-bottom: 1px solid rgba($border-gray, 0.3)
            display: flex
            justify-content: space-between
            text-align: left

            .text
                .title
                    margin-bottom: 2px
                    font-family: PoppinsBold
                    font-size: 12px
                    letter-spacing: 0.3px
                    color: $text-black

                .info
                    margin-top: 0px
                    font-family: PoppinsLight
                    font-size: 10.5px
                    letter-spacing: 0.26px
                    color: $text-black

            .toggler
                margin-left: 20px
                border-radius: 14px
                height: 24px
                min-width: 45px
                background-color: $white
                box-shadow: 0 2px 4px #01000029

                cursor: pointer

                outline: none
                -webkit-tap-highlight-color: rgba(0,0,0,0)

                .toggler-button
                    margin-top: 2px
                    margin-left: 4px
                    width: 20px
                    height: 20px
                    border-radius: 14px
                    background-color: $red
                    transition: .3s

                    &.active
                        background-color: $bright-green
                        margin-left: 21px

            &.requesting
                cursor: not-allowed
                .toggler
                    opacity: 0.68
                    pointer-events: none

        .design-setting-container
            display: flex
            flex-direction: column

            .design-theme-button
                margin-bottom: 10px
                border: none
                border-radius: 4px
                padding-left: 11px
                padding-top: 10px
                padding-bottom: 10px
                background-color: $white
                color: $gray-blue

                font-family: PoppinsSemiBold
                font-size: 12px
                letter-spacing: 0.3px
                text-align: center

                cursor: pointer

                outline: none
                -webkit-tap-highlight-color: rgba(0,0,0,0)

                &.active
                    color: $white
                    background: $button-blue

                &:disabled
                    cursor: not-allowed
                    
            &.requesting
                cursor: not-allowed
                .design-theme-button
                    opacity: 0.68
                    pointer-events: none

        &.disabled
            .setting-container
                cursor: not-allowed
                .toggler
                    opacity: 0.68
                    pointer-events: none

            .design-setting-container
                cursor: not-allowed
                .design-theme-button
                    opacity: 0.68
                    pointer-events: none

.dark-mode
    .settings-page
        .settings-container
            .white
                background-color: $dark-button-gray
                color: $dark-text-color
                padding: 0 10px

            .setting-option
                .design-setting-container
                    margin-top: 10px

        .settings-container
            .text
                .title
                    color: $white

                .info
                    color: $white

            .toggler
                background-color: $text-lighter-gray

        .design-setting-container
            .design-theme-button
                background-color: $text-lighter-gray
                color: $dark-text-color
</style>