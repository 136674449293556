<template>
	<div class="contract-items-page">
		<Header :pageTitle="contractName" />
		<SubHeader :currentPage="'on_site.menu.calloff_list'.trans()"/>



        <div class="calloff-items-container">
            <FilterSearch
                :contract-items="true"
                filter-action="calloffList/fetchCalloffItemsOnLoad"
                :filter-parameter="getFiltersParameter"
                filter-route="filters/fetchCalloffItemsFilterItems"
                page-name="calloffItems"
                parameter-name="contract_number"
                :parameter-value="contractNumber"
                :query-parameter="getQueryParameter"
                searchbar-placeholder="on_site.search_filter.calloff.placeholder"
            />
            <Loader>
                <div class="filter-day" v-if="filters.date">
                    <span>{{'on_site.calloff-list.delivery_day'.trans()}}</span>: {{ filters.date.replaceAll('-', '.') }}<span></span>
                </div>
                <template v-for="(block, i) in paginationBlocks">
                    <Loader :prevent-loading-screen="i < current_page">
                        <div class="contract-items">
                            <div class="filter-parent-container" v-if="hasResults">
                                <CalloffItemCard
                                    v-for="item in block"
                                    :item="item"
                                    :key="item.id"
                                />
                            </div>
                        </div>
                    </Loader>
                </template>
                <div class="contract-items-title empty" v-if="!hasResults">
                    <span class="empty-title">{{'on_site.calloff-items.empty'.trans()}}</span>
                    <div class="empty-screen-icon-container">
                        <img class="contract-items" src="@/assets/icons/amount-empty.svg" alt="empty_contract-items"/>
                    </div>
                    <span class="empty-message">{{'on_site.calloff-items.empty_message'.trans()}}</span>
                </div>
            </Loader>
		</div>
		<Footer/>
	</div>
</template>

<script setup>
	import { computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'
    import { isProxy, toRaw } from 'vue';

	import Header from '@/components/Common/Header.vue'
	import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'
	import CalloffItemCard from '@/components/CallOffs/CalloffItemCard.vue'
	import FilterSearch from "@/components/Common/FilterSearch.vue";

	const store = useStore()
	const route = useRoute()

	const { contracts: { selectedContract: { contract_name: contractName, contract_number: contractNumber }}} = store.state
	const items = computed(() => store.getters['calloffList/getCalloffItems'])

	// Pagination and has results flag
	const hasResults = computed(() => items.value.length > 0)

	const pagination = computed(() => store.getters['calloffList/getCalloffPagination'])
	const { current_page } = pagination.value

	const paginationBlocks = computed(() => items.value.reduce((resultArray, item, index) => {
		const { total_items } = pagination.value
		const chunkIndex = Math.floor(index/total_items)

		resultArray[chunkIndex] = resultArray[chunkIndex] ? [...resultArray[chunkIndex], item] : [item]

		return resultArray
	}, []))

	// Query parameter from search component that needs to be put into pagination for loading more results
	let paginationQueryParameter = ''
	function getQueryParameter(val) {
		paginationQueryParameter = val
	}

	// Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFiltersParameter(val) {
		filtersParameter = val
	}

    const filters = computed(() => store.getters['filters/getCalloffFilters'])

	onMounted(() => {
        window.onscroll = () => {
            const {current_page, has_next_page} = pagination.value
            const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
            if (bottomOfWindow && has_next_page && !$('.contracts-container').hasClass('hidden')) {
                const length = computed(() => store.getters['getLoadingCalls'])
                if (length.value.length === 0) {
                    store.dispatch('calloffList/fetchCalloffItemsPaginated', {
                        contract_id: store.state.contracts.selectedContract.contract_id,
                        page: parseInt(current_page) + 1,
                        'filterString': filtersParameter
                    })
                }
            }
        }
	})

	onUnmounted(() => {
		// store.commit('filters/setSelectedFilters', '')
		// store.commit('filters/setSelectedQuery', '')
		// window.onscroll = () => {}
	})
</script>

<style lang="sass" scoped>
.contract-items-page
    min-height: calc(100vh - 51px - 48px - 81px)
    padding-bottom: 81px
    // footer + some extra height
    padding-top: 51px + 48px + 11px
        // header + subheader + margin from design

    .filters-container
        margin: 5px 0px 10px 0px

    .calloff-items-container
        display: flex
        flex-wrap: wrap
        justify-content: space-evenly
        padding: 0 24px
        position: relative

        .filter-day
            font-family: PoppinsMedium
            font-size: 12px
            letter-spacing: 0.02px
            line-height: 16px
            text-align: left
            color: #8E959C
            width: 100%
            text-align: left
            padding-bottom: 10px


    .contract-items
        width: 100%

        .filter-parent-container
            display: grid
            gap: 10px
            grid-template-columns: 1fr 1fr

            @media(max-width: 767px)
                grid-template-columns: 1fr

            &.hidden
                display: none

    .contract-items-title
        font-family: PoppinsMedium
        font-size: 12px
        letter-spacing: 0.02px
        line-height: 16px
        color: $text-gray
        text-align: left

        &.empty
            margin-top: 15px
            padding-left: 0
            margin-left: 0
            text-align: center
            font-family: PoppinsMedium
            font-size: 12px
            color: $text-gray

            .empty-screen-icon-container
                margin-bottom: 10px

                img
                    height: 200px

            .empty-title
                font-family: PoppinsBold
                font-size: 16px

    &.filter-open
        height: 100vh

        .contract-items
            display: none

        .calloff-items-container
            height: 100%

.dark-mode
    .contract-items-page
        .calloff-items-container
            .filter-day
                color: $white

</style>