const state = {
    configurationData: {},
}

const getters = {

}

const actions = {
    fetchConfigurationData: async ({ state, commit, rootState }) => {
        const configurationRoute = Routing.generate('api_get_configuration', { 'device_type': 'pwa' }, true)

        const userIsLoggedIn = rootState.userLoggedIn

        try {
            let response = {}

            if (userIsLoggedIn) {
                response = await axios({
                    method: 'get',
                    url: configurationRoute,
                    headers: {
                        'Authorization': `Bearer ${rootState.accessToken}`
                    }
                })
            } else {
                response = await axios.get(configurationRoute)
            }

            const oldConfigurationData = JSON.stringify(state.configurationData)

            // if there is more than one only save the first one from the response
            if (Array.isArray(response.data)) {
                commit('setConfigurationData', response.data[0])

            } else {
                commit('setConfigurationData', response.data)
            }

            // if the configuration is new/different then it needs to be shown
            if (state.configurationData && (JSON.stringify(state.configurationData) != oldConfigurationData)) {
                commit('setShowConfiguration', true)
            } else {
                commit('setShowConfiguration', false)
            }

            // if the configuration is permanent then it needs to be shown
            if (state.configurationData && state.configurationData.is_permanent) {
                commit('setShowConfiguration', true)
            }

            // if the configuration gets deleted/expires then dont show it or if the message is empty
            if (!state.configurationData || (state.configurationData && !state.configurationData.message)) {
                commit('setShowConfiguration', false)
            }

            commit('setDoneRequesting', true)

        } catch (err) {
            throw err
        }
    }
}

const mutations = {
    setConfigurationData(state, value) {
        state.configurationData = value
    },
    setShowConfiguration(state, value) {
        state.showConfiguration = value
    },
    setDoneRequesting(state, value) {
        state.doneRequesting = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}