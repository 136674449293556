<template>
    <div class="qr-scanner-page">
        <MessagesHeader :pageTitle="pageTitle" :qrPage="true"/>
		
		<div class="qr-scanner-error" v-if="error">
			<h3 class="error-title">
				{{errorTitle}}
			</h3>

			<img class="error-image" src="@/assets/icons/maintenance.svg" alt="error">

			<p class="error-text">
				{{error}}
			</p>
		</div>

		<QrcodeStream :track="paintOutline" :camera="camera" @decode="onDecode" @init="onInit" v-else>
			<Loader v-show="isLoadingCamera" />
		</QrcodeStream>

        <Footer/>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import MessagesHeader from '@/components/Messages/MessagesHeader.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'
	import QrcodeStream from '@/components/QRCodeStream/QrcodeStream.vue'
	import { makeRequest } from '@/utils/makeRequest.js'
	import { showErrorPopup } from '@/utils/globalSwalPopups'
	import OneSignalHelper from '@/utils/OneSignalHelper'
	import Swal from 'sweetalert2'

	const store = useStore()
	const router = useRouter()

	const companyNumber = ref(store.state.user.selectedCompany.company_nr)
	const darkMode = ref(store.state.settings.dark_mode_activated)
	const pageTitle = 'on_site.page-title.qr-scanner'.trans()
	const camera = ref('auto')
	const isLoadingCamera = ref(true)
	const error = ref('')
	const errorTitle = ref('')

	store.commit('setQuasiOverlayStatus', {'qrIsOpen': true})


	// Trigger when the QR data is read
	async function onDecode(scanResult) {
		const url = Routing.generate('api_delivery_qr_code_upload', {}, true)
		const payload = new FormData()

		payload.append('company_number', companyNumber.value)
		payload.append('data', scanResult)

		isLoadingCamera.value = true
		
		try {
			const QRdataResponse = await axios({
				method: 'post',
				url,
				data: payload,
				headers: {
					'Authorization': `Bearer ${store.state.accessToken}`
				}
			})
			const { data } = QRdataResponse

			// change company if necessary
			if (data.data.switch_company) {
				store.commit('user/setSelectedCompany', {'company_name': data.data.company_name, 'company_nr': parseInt(data.data.company_number)})
			}

			// change selected contract
			store.commit('contracts/setSelectedContract', {
				'contract_id': data.data.contract_id,
				'contract_number': data.data.contract_number, 
				'contract_name': data.data.construction_site_name
			})
			
			// redirect to delivery single
			router.push({ name: 'delivery-single', params: {
				'ids': data.data.delivery_id,
				'show_scan_message': true,
				'construction_site_name': data.data.construction_site_name
				}
			})

		} catch(error) {
			if (error.response && error.response.status == 503) {
				OneSignalHelper.unSubscribeUser()

				// Clear store values
				store.commit('userLoggedOut')
				store.commit('setAccessToken', '')
				store.commit('user/setUserData', null)
				store.commit('user/setSelectedCompany', null)
				store.commit('setLastAccessedRoute', '/dashboard')

				// Redirect to maintenance
				router.push({ name: 'maintenance' })

			} else if (error.response && error.response.status == 401) {
				OneSignalHelper.unSubscribeUser()

				// Store actions
				store.commit('userLoggedOut')
				store.commit('setAccessToken', '')
				store.commit('user/setUserData', null)
				store.commit('user/setSelectedCompany', null)
				store.commit('setLastAccessedRoute', '/dashboard')

				// Redirect to login
				router.push({ name: 'login' })

			} else {
				if (error.response.data.errors.length) {

					// combine all errors messages
					const popupErrorMessage = error.response.data.errors
						.map(
							item => item.message
						).reduce(
							(prev, curr) => prev + '<br>' + curr
						)

					showQRErrorPoppup(popupErrorMessage)

				} else {
					showQRErrorPoppup()
				}

				isLoadingCamera.value = false
			}
		}

		// After the decode, turn off and on camera in order to be able to scan the same QR again if necessary
		camera.value = 'off'
	}


	// Trigger when the QR component is initialized
	async function onInit(promise) {
		try {
			await promise

			isLoadingCamera.value = false
			error.value = ''

		} catch(err) {
			isLoadingCamera.value = false
			
			if (err.name === 'NotAllowedError') {
				error.value = 'on_site.qr.camera_error.not_allowed'.trans()
				errorTitle.value = 'on_site.qr.camera_error.not_allowed.title'.trans()

			} else if (err.name === 'NotFoundError' || err.name === 'OverconstrainedError') {
				error.value = 'on_site.qr.camera_error.not_found'.trans()
				errorTitle.value = 'on_site.qr.camera_error.not_found.title'.trans()

			} else if (err.name === 'NotReadableError') {
				error.value = 'on_site.qr.camera_error.already_in_use'.trans()
				errorTitle.value = 'on_site.qr.camera_error.already_in_use.title'.trans()

			} else if (err.name === 'StreamApiNotSupportedError') {
				error.value = 'on_site.qr.camera_error.browser_old'.trans()
				errorTitle.value = 'on_site.qr.camera_error.browser_old.title'.trans()

			} else if (err.name === 'NotSupportedError' || err.name === 'InsecureContextError') {
				error.value = 'ERROR: secure context required (HTTPS, localhost)'

			} else {
				error.value = `ERROR: Camera error (${err.name})`
			}
		}
	}


	// Mark the QR code outline with red color
	function paintOutline (detectedCodes, ctx) {
		for (const detectedCode of detectedCodes) {
			const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

			ctx.strokeStyle = "red"

			ctx.beginPath()
			ctx.moveTo(firstPoint.x, firstPoint.y)

			for (const { x, y } of otherPoints) {
				ctx.lineTo(x, y)
			}
			ctx.lineTo(firstPoint.x, firstPoint.y)
			ctx.closePath()
			ctx.stroke()
		}
	}


	// Show error popup and turn the camera back on after confirming
	function showQRErrorPoppup(text = 'on_site.global_error_popup.text'.trans()) {
		Swal.fire({
			customClass: {
				container: darkMode.value ? 'swal-popups-dark' : 'swal-popups-light',
			},
			icon: 'error',
			title: '',
			html: text,
			confirmButtonText: 'on_site.global_error_popup.button_text'.trans()

		}).then(result => {
			if (result.isConfirmed || result.isDismissed) {
				camera.value = 'auto'
			}
		})
	}
</script>

<style lang="sass" scoped>
.qr-scanner-page
	height: calc(100vh - 51px)
	padding-top: 51px

	.qr-scanner-error
		text-align: center
		padding-left: 24px
		padding-right: 24px
		letter-spacing: 0.02px
		color: $text-gray

		.error-title
			max-width: 368px
			margin: 25px auto 60px auto
			font-size: 16px
			font-family: PoppinsBold

		.error-text
			max-width: 368px
			margin: 60px auto 100px auto
			font-size: 12px
			font-family: PoppinsMedium
			line-height: 15px

	.loader-container
		padding-top: 30px

	.destroy
		position: absolute
		bottom: 80px
		left: 0px
</style>