<template>
    <router-link :to="{ name: 'delivery-single', params: {'ids': ids} }" class="delivery-card" @click="saveCurrentRoute">
        <div class="delivery-note-header">
            <div class="delivery-note-number">
                <span>{{ 'on_site.delivery_note.delivery_note'.trans() }}</span>&nbsp;&nbsp;
                <span>{{ deliveryNoteNumber ? deliveryNoteNumber : '—' }}</span>
            </div>

            <div class="delivery-note-plate">
                <div class="plate-img">
                    <img alt="Stars" src="../../assets/icons/stars3x.png"/>
                </div>
                <span>{{ plateData ? plateData : '—' }}</span>
            </div>
        </div>

        <div class="delivery-note-materials">
            <div class="delivery-note-material" v-for="item in materials" :key="`item-${item.number}`">
                <div class="material-name">{{ item.name ? item.name : '—' }}</div>
                <div class="material-amount">
                    <img alt="Mountain" src="../../assets/icons/amount-gray.svg"/>
                    {{ item.quantity ? item.quantity + item.quantity_unit : '—' }}
                </div>
            </div>
        </div>

        <table class="delivery-note-table">
            <tr>
                <td>{{ 'on_site.delivery_note.supplier'.trans() }}</td>
                <td>{{ supplierName ? supplierName : '—' }}</td>
            </tr>
            <tr>
                <td>{{ 'on_site.delivery_note.factory'.trans() }}</td>
                <td>{{ factoryName ? `${factoryName}` : '—' }}</td>
            </tr>
            <tr v-if="open == '1'">
                <td class="time-label">{{ 'on_site.delivery_note.delivery_note_time'.trans() }}</td>
                <td class="time">{{ weighingTime ? `${weighingTime} Uhr` : '—' }}</td>
            </tr>
            <tr v-else>
                <td class="time-label">{{ 'on_site.delivery_note.delivery_note_time_delivered'.trans() }}</td>
                <td class="time">{{ confirmedTime ? `${confirmedTime} Uhr` : '—' }}</td>
            </tr>
        </table>
    </router-link>
</template>

<script setup>
    import { ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'

    const store = useStore()
    const route = useRoute()

    const props = defineProps({
        id: String,
        ids: String,
        status: String,
        deliveryNoteNumber: String,
        plateData: String,
        supplierName: String,
        factoryName: String,
        factoryNumber: String,
        confirmedTime: String,
        weighingTime: String,
        materials: Array,
        open: String
    })

    function saveCurrentRoute() {
        store.commit('deliveryItems/setDeliverySingleComingFrom', route.name)
    }
</script>

<style lang="sass" scoped>
.dark-mode
    .delivery-card
        background-color: $dark-button-gray
        border: 1px solid $component-border

        .delivery-note-plate
            background-color: $white

        .delivery-note-header
            .delivery-note-number
                color: $white

        .delivery-note-material
            color: $white

            .material-amount
                color: $dark-text-color

        .delivery-note-table
            td
                color: $white

                &.time
                    color: $dark-text-color

.delivery-card
    border-radius: 3px
    width: 94%
    min-width: 250px
    min-height: 158px
    max-width: 408px
    padding: 6px 10px 6px 10px
    background-color: $white
    margin: 5px 8px 8px 8px
    filter: drop-shadow(0px 3px 3px #01000029)
    box-sizing: border-box
    text-align: left
    text-decoration: none

    .delivery-note-header
        padding-bottom: 10px
        display: flex
        justify-content: space-between
        border-bottom: 1px solid rgba(98, 112, 134, 0.5)

        .delivery-note-number
            display: flex
            align-items: center
            padding-top: 5px
            font-family: PoppinsBold
            font-size: 14px
            letter-spacing: 0.07px
            line-height: 21px
            color: $text-black

        .delivery-note-plate
            display: flex
            min-width: 100px
            height: 28px
            border: 1px solid rgba(130, 130, 130, 0.25)
            border-radius: 2px
            padding-right: 5px
            box-shadow: 0px 2px 2px #01000015

            .plate-img
                width: 16px
                height: 100%
                border-radius: 2px 0px 0px 2px
                background-color: $light-blue

                img
                    margin-top: 6px
                    margin-left: 1px
                    width: 14px

            span
                margin-top: 2px
                padding-left: 8px
                font-family: PoppinsRegular
                font-size: 14px
                color: $title-gray

    .delivery-note-material
        display: flex
        justify-content: space-between
        align-items: flex-end
        padding: 6px 6px 6px 3px
        font-family: PoppinsSemiBold
        font-size: 13px
        letter-spacing: 0.07px
        border-bottom: 1px solid rgba(98, 112, 134, 0.5)
        color: $text-black

        .material-amount
            white-space: nowrap
            color: $gray-blue

        img
            display: inline-block
            margin-right: 5px

    .delivery-note-table
        margin-top: 12px

        tr
            td
                font-family: PoppinsMedium
                font-size: 12px
                letter-spacing: 0.02px
                color: $text-black

                max-width: 230px
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap

                &:first-child
                    padding-right: 15px

                &.time-label, &.time
                    line-height: 22px

                &.time
                    font-family: PoppinsBold
                    font-size: 15px
                    color: $text-lighter-gray
</style>
