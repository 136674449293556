<template>
    
</template>

<script setup>
    import Swal from 'sweetalert2/src/sweetalert2.js'
	import { useStore } from 'vuex'

	const store = useStore()

	const announcementData = store.state.announcement.announcementData

	Swal.fire({
		customClass: {
			container: store.state.settings.dark_mode_activated ? 'swal-popups-dark' : 'swal-popups-light',
			popup: 'swal-announcement-popup'
		},
		allowOutsideClick: false,
		showConfirmButton: false,
		showCloseButton: announcementData.is_permanent ? false : true,
		html: announcementData.message
	})
</script>

<style lang="sass">
.swal-announcement-popup
	.swal2-html-container
		margin-top: 40px
		font-family: PoppinsRegular

	.swal2-close
		&:focus
			box-shadow: none
</style>