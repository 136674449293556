<template>
    <button class="loader" :class="boolClasses, className" @click="clickEvent" v-if="!to">
        <span class="loader-container" v-html="loaderContent" />
        <span class="content">
            <slot />
        </span>
    </button>
    <router-link :to="to" :class="boolClasses, className" v-if="to">
        <slot />
    </router-link>
</template>

<script setup>
    const boolProps = [
        "big",
        "border",
        "borderRadius3",
        "isLoading",
        "lightBlue",
        "red",
        "orange",
        "blue",
        "green",
        "gray",
        "gray2",
        "textGray",
        "grayBlue",
        "transparent",
        "white",
        "bold",
        "small",
        "mediumText",
        "fullWidth",
        "disabled",
        "darkmode"
    ]

    const props = defineProps({
        big: Boolean,
        border: Boolean,
        borderRadius3: Boolean,
        className: String,
        clickEvent: Function,
        isLoading: Boolean,
        lightBlue: Boolean,
        orange: Boolean,
        red: Boolean,
        blue: Boolean,
        green: Boolean,
        gray: Boolean,
        gray2: Boolean,
        textGray: Boolean,
        grayBlue: Boolean,
        transparent: Boolean,
        white: Boolean,
        bold: Boolean,
        small: Boolean,
        mediumText: Boolean,
        fullWidth: Boolean,
        disabled: Boolean,
        darkmode: Boolean,
        to: String
    })
    const {
        className,
        clickEvent,
        to
    } = props

    const boolClasses = boolProps.map(color =>
        props[color] && color.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    )

    const loaderContent = `
        <svg class="submit-button-loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
            <defs>
                <style type="text/css"><![CDATA[
                    .animated-bag-spinner-small {
                        transform-origin: 20px 20px;
                        animation: loading-spinner 0.9s linear infinite;
                    }

                    @keyframes loading-spinner {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                ]]></style>
            </defs>

            <path opacity="0.4" fill="#fff" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
            <path fill="#fff" class="animated-bag-spinner-small" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(224.883 20 20)">
            </path>
        </svg>
    `
</script>

<style scoped lang="sass">
button,
a
    // Remove blue outline on mobile
    border: none
    border-radius: 6px
    color: $white
    cursor: pointer
    display: flex
    flex-basis: fit-content
    gap: 10px
    height: fit-content
    justify-content: center
    outline: none
    padding: 10px 15px
    text-decoration: none
    transition: 0.3s
    -webkit-tap-highlight-color: rgba(0,0,0,0)

    &.red
        background-color: $red

    &.orange
        background-color: #ffa500

    &.blue
        background-color: $text-blue

    &.green
        background-color: $green

    &.light-blue
        background-color: $button-blue

    &.text-gray
        background-color: $text-gray
        color: $white

    &.gray
        background-color: $buttons-gray

    &.gray2
        background-color: $button-background-gray
        color: $text-lighter-gray
        transition: 0.3s

        &:hover
            background: $dark-blue
            color: $white

    &.gray-blue
        background-color: $gray-blue

    &.transparent
        background-color: transparent
        color: $names-gray

    &.white
        background-color: $white
        border: 1px solid rgba($text-lighter-gray, .5)
        color: $text-lighter-gray

    &.bold
        font-size: 16px
        line-height: 25px
        font-weight: normal
        font-family: 'PoppinsMedium'

    &.big
        font-size: 16px
        padding: 12px 10px
        width: 100%

    &.small
        font-size: 12px
        padding: 6px 10px

    &.medium-text
        font-family: PoppinsMedium

    &.full-width
        width: 100%

    &.disabled
        background-color: $text-gray
        pointer-events: none

    &.darkmode
        background: $dark-button-gray
        border: 1px solid $border-gray
        color: $dark-text-color

    &.border
        border: 1px solid $white

    &.border-radius3
        border-radius: 3px

    &.loader
        position: relative

        .loader-container
            display: none
            left: 0
            height: 100%
            padding-bottom: 3px
            padding-top: 3px
            position: absolute
            top: 0
            width: 100%

            svg
                height: 100%
                width: auto

        &.is-loading
            pointer-events: none

            .content
                opacity: 0

            .loader-container
                display: flex
                align-items: center
                justify-content: center

        .content
            align-items: center
            display: flex
            gap: 10px
</style>