<template>
	<div class="message--single-page">
		<MessagesHeader :pageTitle="'on_site.page-title.messages'.trans()" :messagesPage="true"/>
		<div class="message-container">
			<router-link :to="{ name: 'messages' }" class="back-button">
				<img src="@/assets/icons/arrow-white.svg">
				{{ 'on_site.notification.single.back-button'.trans() }}
			</router-link>
			<div class="message-loader-container" v-if="isRequesting">
				<Loader />
			</div>
			<div class="message-inner" v-else>
				<div class="message-wrapper">
					<!-- Same components from list -->
					<DeliveryNote
						v-if="data.type === 'scale_note.created'"
						:data="data"
						:showFull=true
					/>
					<CallOff
						v-else
						:data="data"
						:showFull=true
					/>
					<!-- Will be rendered only on single, not list -->
					<div class="actions-container">
						<div class="button-container">
								<Button
                :to="{ name: 'messages' }"
                red
                bold
									@click="removeNotification"
									:text="removeMessageText"
									:isLoading="isRemoving"
								>{{ removeMessageText }}</Button>
						</div>
						<div class="button-container">
							<!-- @todo Set propper link to contract detail -->
							<router-link
								:to="{ name: 'delivery-single', params: { ids: deliveryNoteId }}"
								class="button blue bold"
								v-if="data.type === 'scale_note.created'"
							>
								{{ 'on_site.notification.single.delivery_note.detail_button'.trans() }}
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script setup>
import Footer from "@/components/Common/Footer.vue"
import Loader from "@/components/Common/Loader.vue"
import Button from "@/components/Common/Button.vue"

import MessagesHeader from '@/components/Messages/MessagesHeader.vue'
import DeliveryNote from '@/components/Messages/types/DeliveryNote.vue'
import CallOff from '@/components/Messages/types/CallOff.vue'

import { makeRequest } from '@/utils/makeRequest'

import { computed, ref } from "vue"
import { useStore } from "vuex"

const store = useStore()
const pageTitle = "on_site.page-title.messages".trans()

const storeValues = store.state.messages
const isRequesting = computed(() => storeValues.isSingleDataRequesting)

const data = computed(() => storeValues.singleData)

const deliveryNoteId = data.value.reference_id

// Flags
const isRemoving = ref(false)

// Messages
const removeMessageText = computed(() => storeValues.singleData.type === 'scale_note.created'
	? 'on_site.notification.single.delivery_note.remove_button'.trans()
	: 'on_site.notification.single.calloff.remove_button'.trans()
)

/**
 * Actions
 */

// Remove notification
const removeNotification = async () => {
	try {
		isRemoving.value = true
		const notificationId = storeValues.singleData.id

		// Make request
		await makeRequest('get', 'api-notification-delete', {
			id: notificationId
		})

		// Update count in footer
		store.dispatch('messages/fetchNotificationsCount')

		isRemoving.value = false

	} catch(error) {
		isRemoving.value = false
		throw error
	}
}

</script>

<style lang="sass">
.message--single-page
	background: $text-blue
	min-height: calc(100vh - 50px)

	padding-top: 51px
	padding-bottom: 81px

	.main-header
		border-bottom: 2px solid $background-gray

	.message-container
		margin-left: 10px
		margin-right: 10px
		padding-top: 14px

		.back-button
			color: $white
			display: flex
			font-family: 'PoppinsSemiBold'
			font-size: 14px
			font-weight: normal
			line-height: 21px
			margin-bottom: 10px
			padding-left: 16px
			text-align: left
			text-decoration: none

			img
				width: 6px
				height: auto
				margin-right: 7px
				transform: scaleX(-1)

		.message-loader-container
			display: flex
			align-items: center
			justify-content: center
			max-width: 500px
			min-height: 200px
			margin-left: auto
			margin-right: auto
			padding-bottom: 15px
			padding-top: 2px
			border-radius: 4px
			box-shadow: 0 3px 3px #00000029
			background: $white

		.message-inner
			margin: 0 auto

			background: $white
			max-width: 500px

			border-radius: 4px
			box-shadow: 0 3px 3px #00000029 !important

			.message-removed
				padding: 30px 15px 30px 12px

				img
					height: auto
					margin-bottom: 10px
					width: 45px

				p
					margin: 0
					color: $red
					font-size: 16px
					line-height: 19px
					font-weight: normal
					font-family: 'PoppinsBold'

			.message-wrapper
				border-radius: inherit

				.list-item
					border-radius: inherit
					box-shadow: unset

				.actions-container
					padding: 5px 15px 2px 12px

					.button-container
						margin-bottom: 10px

						button,
						.button
							width: 100%
							border-radius: 4px

							display: flex
							align-items: center
							justify-content: center

						a
							text-decoration: none

.dark-mode
	.message--single-page
		.message-container
			.message-inner
				background-color: $dark-button-gray
				border: 1px solid $component-border

				.list-item
					border: none

			.message-loader-container
				background-color: $dark-button-gray
				border: 1px solid $component-border
</style>