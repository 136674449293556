import { makeRequest } from '@/utils/makeRequest'

const state = {
    data: {
        available_incoterms: [],
        incoterm: {'id': null},
        truck_type_options: [],
        truck_type: {'id': null},
        cart_item_quantity_raw: null,
        cart_item_quantity_unit: 't',
        amount_warning_limit_raw: 0,
        cart_item_quantity: 't',
        hourly_amount_raw: null,
        hourly_amount: 't/h',
        note: null,
        night_shift: false,
        date_start_pretty: null,
        date_end_pretty: null,
        date_start_time: null,
        date_end_time: null,
        available_contact_persons: [],
        contact_person: {'id': null},
        id: null
    },
}

const getters = {
    getCalloffData: (state) => state.data
}

const actions = {
    fetchCartData: async ({ commit, state }, id) => {
        try {
            const response = await makeRequest('get', 'api_init_call_off_cart', {'contract_item_id': id})
            const { data } = response

            commit('setCalloffCreationData', data)
            
        } catch(err) {
            throw err
        }
    },
    fetchContractItemData: async ({ commit, state }, id) => {
        try {
            const response = await makeRequest('get', 'api_get_contract_item_single', {'contract_item_id': id})
            const { data } = response

            commit('setCalloffCreationData', data)
            
        } catch(err) {
            throw err
        }
    },
    submitFirstStepData: async ({ commit, state }, data) => {
        try {
            await makeRequest('post', 'api_init_call_off_cart', data)
        } catch(err) {
            throw err
        }
    },
    submitSecondStepData: async ({ commit, state }, data) => {
        try {
            await makeRequest('post', 'api_call_off_cart_update_contact_person', data)
            
        } catch(err) {
            throw err
        }
    }
}

const mutations = {
    setCalloffCreationData: (state, data) => {
        state.data = {
            ...state.data,
            ...data
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}