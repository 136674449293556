<template>
    <div class="overview-filters-page" :class="{'inside-subheader': subFilter}">
        <Button
            :border="border"
            blue
            :green="checkFiltersSet()"
            small
            :click-event="toggleFiltersPopup"
            :key="displayMenu"
        >
            <img alt="..." src="@/assets/icons/filter.svg"/>
        </Button>

        <div class="overview-filters-container" :class="!displayMenu && 'hidden'">
            <div class="filters-container" v-if="!subFilter">
                <Searchbar
                    :disableSelect="'true'"
                    placeholder="on_site.calloff_overview.search.placeholder"
                />
                <Button
                    green
                    small
                    :click-event="toggleFiltersPopup"
                >
                    <img alt="..." src="@/assets/icons/filter.svg"/>
                </Button>
            </div>

            <Button
                big
                borderRadius3
                full-width
                gray-blue
                medium-text
                :key="allFilters"
                :click-event="resetFilters"
                v-show="showFilters || showDatepicker"
            >
                {{ "on_site.filters.delete_all_filters".trans() }}
            </Button>
            <div class="filters-main-content" v-show="showFilters" :key="showFilters">
                <div class="filter-tags-container">
                    <div class="filter-tags" v-for='(value, name) in items' :key="`item-${name}`">
                        <div class="section">
                            <p class="filter-name">{{ `on_site.calloff_overview.filter.type.${name}`.trans() }}</p>
                            <div class="tags-container">
                                <div class="filter-tag" :class="name, { 'active' : filterTag.selected === true}"
                                     v-for='(filterTag, index) in value'
                                     :key="`item-${index}`"
                                     @click="sortFilters($event)"
                                     :data-filter-id="filterTag.id"
                                     :data-filter-type="name"
                                >
                                    <img v-if="name == 'status' && filterTag.id == 0" alt=" " src="../../assets/icons/icon-planned.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 1" alt=" " src="../../assets/icons/icon-binding-request.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 2" alt=" " src="../../assets/icons/icon-cancellation-request.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 3" alt=" " src="../../assets/icons/icon-delivery-refused.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 4" alt=" " src="../../assets/icons/icon-delivery-confirmed.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 5" alt=" " src="../../assets/icons/icon-delivery-cancelled.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 20" alt=" " src="../../assets/icons/icon-in-review.svg"/>
                                    <img v-if="name == 'status' && filterTag.id == 30" alt=" " src="../../assets/icons/icon-change-request.svg"/>
                                    {{ filterTag.name }}
                                    <img class="cancel-x" v-if="name == 'status' && !darkModeActivated" alt=" " src="../../assets/icons/cancel.svg"/>
                                    <img class="cancel-x" v-if="name == 'status' && darkModeActivated" alt=" " src="../../assets/icons/cancel-white.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fiilter-tags">
                        <div class="section">
                            <div class="flatpickr-container" v-if="showDatepicker">
                                <p class="filter-name">{{ 'on_site.filter_category.datepicker'.trans() }}</p>
                                <flat-pickr v-model="dateFilter"
                                            placeholder="Select date"
                                            :config="config"
                                            id="date-input"
                                            class="date-input"
                                ></flat-pickr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filters-main-content-empty" v-show="!showFilters">
                {{'on_site.filter_page.empty'.trans()}}
            </div>
            <div class="filters-main-content-empty" v-show="!showFilters && selectedFilters !== '' && selectedQuery !== ''">
                {{'on_site.filter_page.empty_because_of_search_term'.trans()}}
            </div>
            <Button
                bold
                borderRadius3
                full-width
                green
                :key="allFilters"
                :click-event="setFilters"
                v-show="showFilters || showDatepicker || isFilterSet"
            >
                {{ "on_site.filters.apply_filters".trans() }}
            </Button>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, onUpdated, ref, toRaw} from 'vue'
import { useStore } from 'vuex'
import Searchbar from '@/components/Common/Searchbar.vue'
import Loader from '@/components/Common/Loader.vue'
import { updateUrlParameterMulti } from '@/utils/updateFilterUrl.js'
import { updateUrlParameter } from '@/utils/updateFilterUrlSingle.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { German } from 'flatpickr/dist/l10n/de'
import Button from "@/components/Common/Button.vue";

const loading = computed(() => store.getters['getLoadingCalls'])

const {
    border,
    filterAction,
    filterParameter,
    filterRoute,
    subFilter,
    contractItems,
    parameterName,
    parameterValue,
    pageName,
    showDatepicker
} = defineProps({
    border: Boolean,
    filterAction: String,
    filterParameter: Function,
    filterRoute: String,
    subFilter: {
        type: Boolean,
        default: false
    },
    contractItems: {
        type: Boolean,
        default: false
    },
    parameterName: String,
    parameterValue: String,
    pageName: String,
    showDatepicker: {
        type: Boolean,
        default: true
    },
    filtersDisabled: {
        type: Boolean,
        default: false
    }
})

function deepEqual(x, y) {
    return (x && y && typeof x === 'object' && typeof y === 'object') ?
        (Object.keys(x).length === Object.keys(y).length) &&
        Object.keys(x).reduce(function(isEqual, key) {
            return isEqual && deepEqual(x[key], y[key]);
        }, true) : (x === y);
}

function checkFiltersSet() {
    const defaultFilters = {
        material_category: [0, 1, 2, 3, 4],
        status: [0, 1, 20, 4, 30]
    }

    let currentFilters = { ...store.getters['filters/getCalloffFilters'] }

    delete(currentFilters.contract_number)

    if(typeof currentFilters.date !== 'undefined') {
        return true
    }

    if(Object.keys(currentFilters).length === 0) {
        return false
    }

    currentFilters.status = toRaw(currentFilters.status)

    currentFilters.material_category = toRaw(currentFilters.material_category)

    return !deepEqual(defaultFilters, currentFilters)
}

const store = useStore()
const activeFilters = ref([])
const displayMenu = ref(false)
let isFilterSet = ref(false)

const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

onMounted(() => {
    if(parameterName && parameterValue) {
        store.dispatch(filterRoute, {
            [parameterName]: parameterValue,
        })
    } else {
        store.dispatch(filterRoute)
    }
})

// Variable for creating the all filters string
let allFilters = ''

// Datepicker configuration
const dateFilter = ref('')

const config = {
    inline: true,
    dateFormat: "d-m-Y",
    locale: German,
    monthSelectorType: "static",
    onReady: (_, __, fp) => {
        $(fp.calendarContainer).addClass('default-flatpickr-style').addClass('max-width')

        if (darkModeActivated.value) $(fp.calendarContainer).addClass('dark-mode-style')
    },
    onDayCreate: function(dObj, dStr, fp, dayElem){
        // Logic for adding a class to weekend days
        const dayOfWeek = dayElem.dateObj.getDay()
        const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

        if(isWeekend) {
            dayElem.classList.add('weekendDay')
        }
    }
}

// Logic for updating all filters with date
let selectedDate = ''
function setFilterDate() {
    const dateFilterValue = dateFilter.value

    selectedDate = dateFilterValue

    allFilters = updateUrlParameter(allFilters, 'date', dateFilterValue)

    let payload = {
        [parameterName]: parameterValue,
        'filterString': allFilters
    }

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    store.dispatch(filterRoute, payload)
}

let items = computed(() => store.state.filters.calloffItemsFilterItems)

const showFilters = computed(() => {
    const totalItemCount = Object.values(items.value).reduce((x, y) => Object.values(x).length + Object.values(y).length, 0)
    const isShowFilters = totalItemCount !== 0

    return isShowFilters
})

const selectedQuery = computed(() => store.state.filters.selectedQuery)
const selectedFilters = computed(() => store.state.filters.selectedFilters)

// Logic for opening/closing filters popup and hidding contracts when filters popup is open
function toggleFiltersPopup() {
    displayMenu.value = !displayMenu.value

    if(displayMenu.value) {
        openFiltersPopup()
    } else {
        closeFiltersPopup()
    }

    $('html').removeClass(subFilter ? 'no-scroll' : 'no-pull-to-refresh')
}

function openFiltersPopup() {
    displayMenu.value = true
    $('.contract-items-page').addClass('filter-open')
}

function closeFiltersPopup() {
    displayMenu.value = false
    $('.contract-items-page').removeClass('filter-open')
}

// Logic for sorting filters when one is clicked
const sortFilters = (event) => {
    // at least one filter has to be set
    let $target = $(event.target)
    if($(event.target).closest('.section').find('.filter-tag.active').length === 1 && $target.hasClass('active')) {
        return
    }

    event.target.classList.toggle('active')
}

function setFilters(event) {
    let payload = {
        [parameterName]: parameterValue
    }

    $('.tags-container .filter-tag.active').each(function (index, object) {
        let $element = $(object)

        let elementData = $element.data()

        if(!payload[elementData.filterType]) {
            payload[elementData.filterType] = []
        }

        payload[elementData.filterType].push(elementData.filterId)
    })

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    if($('#date-input').val()) {
        payload['date'] = $('#date-input').val()
    }

    store.commit('filters/setCalloffFilters', payload)

    store.dispatch(filterAction, payload)

    filterParameter(allFilters)

    // Close filter popup
    $('.overview-filters-container').addClass('hidden')
    $('.contracts-container').removeClass('hidden')

    store.commit('filters/setCalloffFilters', payload)

    $('.filter-parent-container').removeClass('hidden')

    $('html').removeClass('no-scroll')
    $('html').removeClass('no-pull-to-refresh')
    displayMenu.value = false

    closeFiltersPopup()
}

function resetFilterTagsFrontend (payload) {
    let categoryElements = $('[data-filter-type="material_category"]')

    categoryElements.each(function (index, element) {
        let elementData = $(element).data()

        if (payload.material_category.includes(elementData.filterId)) {
            $(element).addClass('active')
        } else {
            $(element).removeClass('active')
        }
    })

    let statusElements = $('[data-filter-type="status"]')

    statusElements.each(function (index, element) {
        let elementData = $(element).data()

        if (payload.status.includes(elementData.filterId)) {
            $(element).addClass('active')
        } else {
            $(element).removeClass('active')
        }
    })
}

function resetFilters() {
    store.dispatch(filterRoute, {
        [parameterName]: parameterValue,
    })

    let payload = {
        [parameterName]: parameterValue,
        'filterString': ''
    }

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    store.commit('filters/setCalloffFilters', {})

    store.dispatch(filterAction, payload)

    resetFilterTagsFrontend(payload)

    // Reset filters popup - disable buttons, deactivate filter tags
    // $('.filter-tag').removeClass('active')

    // Close filter popup and show item container
    $('.overview-filters-container').addClass('hidden')
    $('.contracts-container').removeClass('hidden')

    allFilters = ''
    filterParameter(allFilters)

    dateFilter.value = ''

    $('.filter-parent-container').removeClass('hidden')

    $('html').removeClass('no-scroll')
    $('html').removeClass('no-pull-to-refresh')
    activeFilters.value = []
    displayMenu.value = false

    closeFiltersPopup()
}
</script>

<style lang="sass" scoped>
.overview-filters-page
    .overview-filters-container
        background-color: $background-gray
        box-shadow: 0 3px 6px #01000029
        display: flex
        flex-direction: column
        gap: 10px
        height: calc(100% + 40px)
        left: 0
        overflow: auto
        padding: 11px 24px 60px
        position: absolute
        top: -10px
        transition: 1s
        width: 100%
        z-index: 2

        &.hidden
            display: none

        .filters-main-content
            .flatpickr-container
                > label,
                input
                    display: none

            .section
                overflow: initial
                padding: 0

        .filters-main-content-empty
            margin-top: 30px
            margin-left: 24px
            text-align: left

        .filters-title
            margin: 0px
            padding-top: 12px
            padding-left: 24px
            font-family: PoppinsMedium
            font-size: 12px
            letter-spacing: 0.02px
            line-height: 16px
            text-align: left
            color: $text-gray

        .filter-tags-container
            margin: 12px 0
            text-align: left

            .filter-tags
                display: flex
                flex-direction: column
                margin-bottom: 15px

                .filter-name
                    margin-bottom: 5px
                    font-family: PoppinsBold
                    font-size: 12px
                    letter-spacing: 0.02px
                    line-height: 16px
                    color: $dark-blue

                .tags-container
                    display: flex
                    flex-wrap: wrap
                    gap: 4px
                    padding: 0 0 6px

                    .filter-tag
                        opacity: 0.5
                        align-items: center
                        background-color: $white
                        border: 1px solid transparent
                        border-radius: 4px
                        box-shadow: 0 3px 6px rgba(1,0,0,.1607843137)
                        color: $text-black
                        cursor: pointer
                        display: flex
                        font-family: PoppinsRegular
                        font-size: 14px
                        font-weight: normal
                        height: 35px
                        letter-spacing: 0.84px
                        line-height: 21px
                        margin: 0 0 4px
                        padding: 5px 8px
                        position: relative
                        text-transform: uppercase
                        text-align: left
                        transition: 0.3s
                        width: calc(100%/4 - 4px)

                        @media (max-width: 720px)
                            width: calc(100%/3 - 3px)

                        @media (max-width: 540px)
                            width: calc(100%/2 - 2px)

                        @media (max-width: 380px)
                            width: 100%

                        &.status
                            padding: 8px 8px 8px 30px
                            opacity: 0.5

                            .cancel-x
                                opacity: 0
                                pointer-events: none
                                position: absolute
                                right: 8px
                                top: 10px
                                transition: 0.3s

                            img
                                &:not(.cancel-x)
                                    height: 16px
                                    left: 8px
                                    position: absolute
                                    top: 8px
                                    width: 16px

                            &[data-filter-id='30'],
                            &[data-filter-id='40']
                                img
                                    &:not(.cancel-x)
                                        top: 7px

                        &.active, &.selected
                            background-color: $white !important
                            border-color: $dark-blue
                            color: $dark-blue
                            opacity: 1

                            .cancel-x
                                opacity: 1

    &.inside-subheader
        .overview-filters-container
            top: 46px

// fix for safari web mode
.app-container
    &:not(.standalone)
        &.ios
            .apply-filters-button
                margin-bottom: 20px

        &.ios-old
            .apply-filters-button
                margin-bottom: 60px

.dark-mode
    .overview-filters-page
        .overview-filters-container
            background-color: $dark-button-gray

            .filters-main-content-empty
                color: $dark-text-color

            .filter-tags-container
                .filter-tags
                    .filter-name
                        color: $dark-text-color

                    .tags-container
                        .filter-name
                            color: $dark-text-color

                        .filter-tag
                            background-color: $text-black
                            color: $dark-text-color

                            img
                                &:not(.cancel-x)
                                    filter: brightness(3)

                            &.active
                                background: $button-blue !important
                                color: $white

            .filters-main-content
                .section
                    .flatpickr-container
                        .filter-name
                            color: $dark-text-color
                            font-family: PoppinsBold

    .select2-container--default.select2-container--disabled .select2-selection--single
        background-color: $text-black !important
        color: $dark-text-color !important
</style>

<style lang="sass">
.dark-mode
    .overview-filters-page
        .select2-container--default.select2-container--disabled .select2-selection--single
            background-color: $text-black !important
            color: $dark-text-color !important

    .filter-datepicker-container
        .filter-name
            color: $dark-text-color

        .flatpickr-calendar
            background-color: $dark-button-gray

            .flatpickr-months
                .flatpickr-prev-month,
                .flatpickr-next-month
                    svg
                        fill: $dark-text-color

                .flatpickr-current-month
                    color: $dark-text-color

            .flatpickr-weekdays
                .flatpickr-weekday
                    color: $white

            .flatpickr-days
                .dayContainer
                    .flatpickr-day
                        color: $dark-text-color

                        &.selected
                            background-color: $button-blue

                        &.weekendDay
                            background-color: $flatpicker-dark

                            &.selected
                                background-color: $button-blue

                        &:hover
                            border: $button-blue
                            background-color: $button-blue

            .flatpickr-weekdaycontainer span:nth-child(7n+6),
            .flatpickr-weekdaycontainer span:nth-child(7n)
                background-color: $flatpicker-dark

.filter-datepicker-container
    margin: 0 auto
    width: 94%

    .flatpickr-input
        display: none

    .filter-name
        margin-bottom: 5px
        font-family: PoppinsBold
        font-size: 12px
        letter-spacing: 0.02px
        line-height: 16px
        color: $dark-blue
        text-align: left

    .flatpickr-calendar
        width: 100%
        max-width: 355px
        margin: 0 auto
        box-shadow: none

    .flatpickr-months
        .flatpickr-current-month
            font-family: PoppinsMedium
            font-size: 18px
            line-height: 27px
            color: $dark-blue

        .flatpickr-next-month
            right: 5px

    .flatpickr-rContainer
        margin: 0 auto

    .flatpickr-weekdays
        font-family: PoppinsLight
        font-size: 16px
        height: 50px
        color: $text-lighter-gray

        .flatpickr-weekdaycontainer
            height: 50px

            .flatpickr-weekday
                line-height: 50px

    .flatpickr-days
        min-width: 350px

        .dayContainer
            min-width: 350px

            .flatpickr-day
                min-width: 50px
                height: 50px
                font-family: PoppinsRegular
                font-size: 13px
                line-height: 49px
                color: $dark-blue

                &.selected
                    border: none
                    color: $white
                    background-color: $dark-blue

                &.weekendDay
                    border-radius: 0px
                    background-color: $datepicker-gray

                    &.selected
                        background-color: $dark-blue

            .prevMonthDay
                color: $text-lighter-gray

    .flatpickr-weekdaycontainer span:nth-child(7n+6),
    .flatpickr-weekdaycontainer span:nth-child(7n)
        background-color: $datepicker-gray

@media (max-width: 360px)
    .filter-datepicker-container
        .flatpickr-days
            min-width: 300px

            .dayContainer
                min-width: 300px

                .flatpickr-day
                    min-width: 43.82px
                    height: 43.82px
                    line-height: 47px
</style>