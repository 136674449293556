<template>
    <div class="sub-header" :class="{'filters-active': showFilters}">
        <SubHeaderCompanySelect v-if="showCompanySelect" />
        <SubHeaderNavigation
                :current-page="currentPage"
                :filter-action="filterAction"
                :filter-route="filterRoute"
                :filter-parameter="getFilterParameter"
                :page-name="pageName"
                :parameter-name="parameterName"
                :parameter-value="parameterValue"
                :show-datepicker="showDatepicker"
                :sub-filter="subFilter"
                v-else
        />
        <div class="filter-wrapper" v-if="showFilters">
            <FilterSearch
                    :filter-action="filterAction"
                    :filter-route="filterRoute"
                    :filter-parameter="getFilterParameter"
                    :parameter-name="parameterName"
                    :parameter-value="parameterValue"
                    :page-name="pageName"
                    :show-datepicker="showDatepicker"
            />
        </div>
    </div>
</template>

<script setup>
import SubHeaderCompanySelect from './SubHeaderCompanySelect.vue'
import SubHeaderNavigation from './SubHeaderNavigation.vue'
import FilterSearch from "@/components/Common/FilterSearch.vue";

const props = defineProps({
    showCompanySelect: {
        type: Boolean,
        default: false
    },
    currentPage: {
        type: String,
        default: ''
    },
    showFilters: {
        type: Boolean,
        default: false
    },
    filterAction: {
        type: String,
        default: ''
    },
    filterRoute: {
        type: String,
        default: ''
    },
    subFilter: {
        type: Boolean,
        default: false
    },
    parameterName: {
        type: String,
        default: ''
    },
    parameterValue: {
        type: String,
        default: ''
    },
    pageName: {
        type: String,
        default: ''
    },
    showDatepicker: {
        type: Boolean,
        default: false
    },
    filtersDisabled: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['filterParameter'])

// Filters parameter from filters popup - used for pagination and date parameter for page title
const getFilterParameter = (val) => {
    emit('filterParameter', val)
}
</script>

<style lang="sass" scoped>
.sub-header
  margin-top: 2px
  background-color: $text-blue
  border-top: 2px solid $background-gray
  box-shadow: 0 3px 6px #01000029

  z-index: 9997

  top: 49px
  position: fixed

  width: 100%
  min-width: 250px
  max-width: 900px

  .filter-wrapper
    padding: 24px
</style>

<style lang="sass">
.sub-header
  position: relative

  // Style for subheader when it contains the filter button
  &.filters-active
    .navigation-menu-toggler-container
      padding-right: 70px

    .overview-filters-page
      .filters-button-open
        position: absolute
        top: 9px
        right: 24px
        display: flex
        align-items: center
        z-index: 9997
        height: 28px
        padding: 0

        img
          margin: 0 auto

    .overview-filters-container
      top: 46px
</style>