import { makeRequest } from '@/utils/makeRequest'

const state = {
    recentDailyAmounts: {},
    dailyAmountItems: {
        items: [],
        pagination: {}
    },
    totalAmountItems: {
        items: [],
        pagination: {}
    },
    amountShowTonnage: true,
    isAmountsRequesting: false,
    isAmountsPaginationRequesting: false,
}

const getters = {

}

const actions = {
    /**
     * Gets max 5 last daily amounts that user visited
     * 
     * @param {object} params - Optional extra parameters. By default it includes the selected company's number stored in user module.
     */
    fetchRecentDailyAmounts: async (
        { commit, rootState }, 
        params = { 'company_number': rootState.user.selectedCompany.company_nr }
    ) => {
        commit('setAmountItemsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_materials_quantity_daily_latest', params)
            const { data } = response

            commit('setRecentDailyAmounts', data)
            commit('setAmountItemsRequestingFlag', false)

        } catch(err) {
            commit('setAmountItemsRequestingFlag', false)
            throw err
        }
    },
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchDailyAmountsOnLoad: async (
        { commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        commit('setAmountItemsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_materials_quantity_daily', params )
            const { data } = response

            // This will also reset items & pagination
            commit('setDailyAmountsData', data)
            commit('setAmountItemsRequestingFlag', false)
        } catch(err) {
            commit('setAmountItemsRequestingFlag', false)
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchDailyAmountItemsPaginated: async ({ commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        commit('setAmountsPaginationRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_materials_quantity_daily', params )
            const { data } = response

            // This will set merge items & update pagination
            commit('setDailyAmountItemsPaginatedData', data)
            commit('setAmountsPaginationRequestingFlag', false)
        } catch(err) {
            commit('setAmountsPaginationRequestingFlag', false)
            throw err
        }
    },
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchTotalAmountsOnLoad: async (
        { commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        commit('setAmountItemsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_materials_quantity_total', params )
            const { data } = response

            // This will also reset items & pagination
            commit('setTotalAmountsData', data)
            commit('setAmountItemsRequestingFlag', false)
        } catch(err) {
            commit('setAmountItemsRequestingFlag', false)
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchTotalAmountItemsPaginated: async ({ commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        commit('setAmountsPaginationRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_materials_quantity_total', params )
            const { data } = response

            // This will set merge items & update pagination
            commit('setTotalAmountItemsPaginatedData', data)
            commit('setAmountsPaginationRequestingFlag', false)
        } catch(err) {
            commit('setAmountsPaginationRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    // Onload
    setAmountItemsRequestingFlag: (state, value) => {
        state.isAmountsRequesting = value
    },
    setRecentDailyAmounts: (state, value) => {
        state.recentDailyAmounts = value
    },
    setDailyAmountsData: (state, dailyAmountItems) => {
        state.dailyAmountItems = dailyAmountItems
    },
    setRecentTotalAmounts: (state, value) => {
        state.recentTotalAmounts = value
    },
    setTotalAmountsData: (state, totalAmountItems) => {
        state.totalAmountItems = totalAmountItems
    },
    // // Pagination
    setAmountsPaginationRequestingFlag: (state, value) => {
        state.isAmountsPaginationRequesting = value
    },
    setDailyAmountItemsPaginatedData: (state, dailyAmountItems) => {
        const { items, pagination } = dailyAmountItems
        state.dailyAmountItems = {
            pagination,
            items: [ ...state.dailyAmountItems.items, ...items ]
        }
    },
    setTotalAmountItemsPaginatedData: (state, totalAmountItems) => {
        const { items, pagination } = totalAmountItems
        state.totalAmountItems = {
            pagination,
            items: [ ...state.totalAmountItems.items, ...items ]
        }
    },
    setAmountShowTonnage: (state, value) => {
        state.amountShowTonnage = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
