import store from '@/store/index'

const NetworkHelper = {
    checkStatus: () => {
        return window.navigator.onLine
    },
    listenForChange: () => {
        window.addEventListener('offline', function(e) {
            // Show flash message
            store.dispatch('flashMessage/showFlash', {
                type: 'error',
                message: 'on_site.network.no-connection'.trans()
            })
        })

        window.addEventListener('online', function(e) {
            // Show flash message
            store.dispatch('flashMessage/showFlash', {
                message: 'on_site.network.connection-back'.trans()
            })
        })
    }
}

export default NetworkHelper
