import store from '@/store/index'
import router from '@/router/index'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { makeRequest } from '../makeRequest.js'
import { showErrorPopup } from '../globalSwalPopups'
import OneSignalHelper from '../OneSignalHelper'

/**
 * Checks if the user accepted the Terms of Use
 *
 * @param - No input parameters. Reads the store instead.
 */
export function checkTermsOfServiceChange() {
    if (store.state.user.data && store.state.user.data.accepted_terms_of_use_date == null) {
		const popupContent = `
			<span>${'on_site.tos.change_popup.text_start'.trans()}</span>
			<br>
			<span class="redirect-to-tos">
				${'on_site.tos.change_popup.text_link'.trans()}
			</span>
			<br>
			<span>${'on_site.tos.change_popup.text_end'.trans()}</span>
		`

		let swalClass = 'swal-popups-light'

		if(store.state.settings && store.state.settings.dark_mode_activated) {
			swalClass = 'swal-popups-dark'
		}

		Swal.fire({
			icon: 'warning',
			title: 'on_site.tos.change_popup.title'.trans(),
			html: popupContent,
			confirmButtonText: 'on_site.tos.change_popup.button_text'.trans(),
			allowOutsideClick: false,
			showCloseButton: true,
			customClass: {
				container: swalClass,
				title: 'tos-title',
				htmlContainer: 'text-align-left',
				actions: 'buttons-align-right'
			},
			showLoaderOnConfirm: true,
			didOpen: (popup) => {
				const linkToToS = $(popup).find('.redirect-to-tos')

				// when popup opens bind the click event
				linkToToS.on('click', () => {
					router.push({ name: 'terms-of-use', params: {'changed': 'true'} })
					Swal.close()
				})
			},
			preConfirm: async function(popup) {
				try {
					return makeRequest('post', 'accept-terms-of-use')
						.then(response => {
							if (!response == false) {
								store.commit('user/setUserDataToS')

								Swal.fire({
									icon: 'success',
									title: 'on_site.tos_change.success.title'.trans(),
									text: 'on_site.tos_change.success.text'.trans(),
									confirmButtonText: 'on_site.tos_change.success.button_text'.trans()
								})
							}
						})
				} catch {
					showErrorPopup()
				}

				// unbind event on closing
				$(popup).find('.redirect-to-tos').off('click')
			}

		}).then(async function(result) {
			if (typeof result.dismiss !== 'undefined') {
				// send logout request
				await makeRequest('get', 'api-logout')

				// handle onesignal unsubscribe
				OneSignalHelper.unSubscribeUser()

				// Clear store data
				store.commit('userLoggedOut')
				store.commit('setAccessToken', '')
				store.commit('user/setUserData', null)
				store.commit('user/setSelectedCompany', null)
				store.commit('setLastAccessedRoute', '/dashboard')

				// redirect to login
				router.push({ name: 'login' })
			}
		})
	}
}