<script setup>
    import { useStore } from "vuex";
    import { computed } from "vue";

    const { preventLoadingScreen } = defineProps({
        preventLoadingScreen: Boolean
    })
    const store = useStore()
    const length = computed(() => store.getters['getLoadingCalls'])
</script>

<template>
    <div class="loader-container" title="Loading..." v-if="length.length > 0 && !preventLoadingScreen">
        <svg version="1.1" class="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
            <path opacity="0.4" fill="#fff" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
            <path fill="#fff" class="animated-bag-spinner-small" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(224.883 20 20)">
            </path>
        </svg>
    </div>
    <slot v-else :key="length"/>
</template>

<style lang="sass" scoped>
.loader-container
    display: flex
    align-items: center
    justify-content: center

    svg
        width: 120px
        height: 120px

        path
            fill: $text-blue

        .animated-bag-spinner-small
            transform-origin: 20px 20px
            animation: loading-spinner 0.9s linear infinite

    &.small
        svg
            width: 80px
            height: 80px

    @keyframes loading-spinner
        from
            transform: rotate(0deg)

        to
            transform: rotate(360deg)

.dark-mode
    .loader-container
        path
            fill: $white
</style>