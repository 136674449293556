import { makeRequest } from '@/utils/makeRequest'

const state = {
    contractFilterItems: {
        items: []
    },
    dailyAmountFilterItems: {
        items: []
    },
    totalAmountFilterItems: {
        items: []
    },
    incomingDeliveryFilterItems: {
        items: []
    },
    confirmedDeliveryFilterItems: {
        items: []
    },
    contractItemsFilterItems: {
        items: []
    },
    calloffItemsFilterItems: {
        items: []
    },
    isFiltersRequesting: false,
    selectedFilters: '',
    selectedQuery: '',
    selectedDate: '',
    calloffFilters: {}
}

const getters = {
    getCalloffFilters: (state, getters, rootState) => {
        return rootState.filters.calloffFilters
    }}

const actions = {
    // Filter data
    fetchContractFilterItems: async (
        { commit, rootState }, 
        params = { 'company_number': rootState.user.selectedCompany.company_nr }
    ) => {
        try {
            const response = await makeRequest('get', 'api_contract_filter_options_list', params )
            const { data } = response

            // This will also reset items
            commit('setContractFiltersData', data)
        } catch(err) {
            throw err
        }
    },
    // Daily amount filter items
    fetchDailyAmountFilterItems: async (
        { commit, rootState }, params = {}) => {
        try {
            const response = await makeRequest('get', 'api_materials_quantity_daily_filter_options_list', params )
            const { data } = response

            // This will also reset items
            commit('setDailyAmountFiltersData', data)
        } catch(err) {
            throw err
        }
    },
    // Total amount filter items
    fetchTotalAmountFilterItems: async (
        { commit, rootState }, params = {}) => {
        try {
            const response = await makeRequest('get', 'api_materials_quantity_total_filter_options_list', params )
            const { data } = response

            // This will also reset items
            commit('setTotalAmountFiltersData', data)
        } catch(err) {
            throw err
        }
    },
    // Incoming delivery filter items
    fetchIncomingDeliveryFilterItems: async (
        { commit, rootState }, params = {}) => {
        try {
            const response = await makeRequest('get', 'api_deliveries_incoming_filter_options', params )
            const { data } = response

            // This will also reset items
            commit('setIncomingDeliveryFiltersData', data)
        } catch(err) {
            throw err
        }
    },
    // Confirmed delivery filter items
    fetchConfirmedDeliveryFilterItems: async (
        { commit, rootState }, params = {}) => {
        try {
            const response = await makeRequest('get', 'api_deliveries_confirmed_filter_options', params )
            const { data } = response

            // This will also reset items
            commit('setConfirmedDeliveryFiltersData', data)
        } catch(err) {
            throw err
        }
    },
    fetchContractItemsFilterItems: async (
        { commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        try {
            const response = await makeRequest('get', 'api_get_contract_item_filter_options', params )
            const { data } = response

            // This will also reset items
            commit('setContractItemsFiltersData', data)
        } catch(err) {
            throw err
        }
    },
    fetchCalloffItemsFilterItems: async (
        { commit, rootState },
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        try {
            const response = await makeRequest('get', 'api_get_calloff_item_filter_options', params )
            const { data } = response

            // This will also reset items
            commit('setCalloffItemsFiltersData', data)
        } catch(err) {
            throw err
        }
    },
}

const mutations = {
    // Onload
    setFiltersRequestingFlag: (state, value) => {
        state.isFiltersRequesting = value
    },
    setContractFiltersData: (state, contractFilterItems) => {
        state.contractFilterItems = contractFilterItems
    },
    setDailyAmountFiltersData: (state, dailyAmountFilterItems) => {
        state.dailyAmountFilterItems = dailyAmountFilterItems
    },
    setTotalAmountFiltersData: (state, totalAmountFilterItems) => {
        state.totalAmountFilterItems = totalAmountFilterItems
    },
    setIncomingDeliveryFiltersData: (state, incomingDeliveryFilterItems) => {
        state.incomingDeliveryFilterItems = incomingDeliveryFilterItems
    },
    setConfirmedDeliveryFiltersData: (state, confirmedDeliveryFilterItems) => {
        state.confirmedDeliveryFilterItems = confirmedDeliveryFilterItems
    },
    setContractItemsFiltersData: (state, contractItemsFilterItems) => {
        state.contractItemsFilterItems = contractItemsFilterItems
    },
    setCalloffItemsFiltersData: (state, calloffItemsFilterItems) => {
        state.calloffItemsFilterItems = calloffItemsFilterItems
    },
    setSelectedFilters: (state, filtersData) => {
        state.selectedFilters = filtersData
    },
    setSelectedQuery: (state, queryData) => {
        state.selectedQuery = queryData
    },
    setSelectedDate: (state, dateData) => {
        state.selectedDate = dateData
    },
    setCalloffFilters: (state, filters) => {
        state.calloffFilters = filters
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
