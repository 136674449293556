<template>
    <div class="calloff-detail-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader :currentPage="currentPage"/>
        <div v-if="calloffDetailData">
            <div class="calloff-header-container">
                <div class="calloff-button-container">
                    <Button
                        blue
                        medium-text
                        big
                        :to="{ name: 'calloff-list', params: { id : calloffDetailData.contract_id } }"
                    >
                        {{ "on_site.calloff_detail.button.show_calloff_list".trans() }}
                    </Button>
                </div>
                <div class="calloff-status" :data-status-id="calloffDetailData.status">
                    <img v-if="calloffDetailData.status == 0" alt=" " src="../../assets/icons/icon-planned.svg"/>
                    <span v-if="calloffDetailData.status == 0" >{{ "on_site.calloff_detail.status.planned".trans() }}</span>

                    <img v-if="calloffDetailData.status == 1" alt=" " src="../../assets/icons/icon-binding-request.svg"/>
                    <span v-if="calloffDetailData.status == 1" >{{ "on_site.calloff_detail.status.binding_request".trans() }}</span>

                    <img v-if="calloffDetailData.status == 2" alt=" " src="../../assets/icons/icon-cancellation-request.svg"/>
                    <span v-if="calloffDetailData.status == 2" >{{ "on_site.calloff_detail.status.cancellation_request".trans() }}</span>

                    <img v-if="calloffDetailData.status == 3" alt=" " src="../../assets/icons/icon-delivery-refused.svg"/>
                    <span v-if="calloffDetailData.status == 3" >{{ "on_site.calloff_detail.status.delivery_refused".trans() }}</span>

                    <img v-if="calloffDetailData.status == 4" alt=" " src="../../assets/icons/icon-delivery-confirmed.svg"/>
                    <span v-if="calloffDetailData.status == 4" >{{ "on_site.calloff_detail.status.delivery_confirmed".trans() }}</span>

                    <img v-if="calloffDetailData.status == 5" alt=" " src="../../assets/icons/icon-delivery-cancelled.svg"/>
                    <span v-if="calloffDetailData.status == 5" >{{ "on_site.calloff_detail.status.delivery_cancelled".trans() }}</span>

                    <img v-if="calloffDetailData.status == 20" alt=" " src="../../assets/icons/icon-in-review.svg"/>
                    <span v-if="calloffDetailData.status == 20" >{{ "on_site.calloff_detail.status.in_review".trans() }}</span>

                    <img v-if="calloffDetailData.status == 30" alt=" " src="../../assets/icons/icon-change-request.svg"/>
                    <span v-if="calloffDetailData.status == 30" >{{ "on_site.calloff_detail.status.change_request".trans() }}</span>
                </div>
                <div class="calloff-headline-container">
                    <img src="../../assets/icons/amount-dark-white.svg" v-if="darkModeActivated"/>
                    <img src="../../assets/icons/amount-dark-blue.svg" v-if="!darkModeActivated"/>
                    <h3>{{ ('on_site.' + calloffDetailData.material.group_text).trans() || '—' }}</h3>
                </div>
                <table class="calloff-detail-table">
                    <tr>
                        <td>{{ 'on_site.calloff_detail.material_number'.trans() }}:</td>
                        <td>{{ calloffDetailData.material.number || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'on_site.calloff_detail.factory_name'.trans() }}:</td>
                        <td>{{ calloffDetailData.factory.name || '—' }}</td>
                    </tr>
                </table>

                <div class="calloff-amounts-table-container">
                    <p class="amounts-title">{{ 'on_site.calloffs.amounts-title'.trans() }}</p>
                    <table class="calloff-table">
                        <tr>
                            <td class="icon-container">
                                <img alt=" " src="../../assets/icons/circle-dark-green-full.svg"/>
                                {{ 'on_site.calloffs.amount'.trans() }}
                            </td>
                            <td >{{ calloffDetailData.target_quantity_display || '—' }}</td>
                        </tr>
                        <tr>
                            <td class="icon-container">
                                <img alt=" " src="../../assets/icons/circle-dark-gray-full.svg"/>
                                {{ 'on_site.calloffs.delivered'.trans() }}
                            </td>
                            <td>{{ calloffDetailData.delivered_quantity_display || '—' }}</td>
                        </tr>
                        <tr>
                            <td class="icon-container open-amount">
                                <img alt=" " src="../../assets/icons/circle-dark-blue-full.svg"/>
                                {{ 'on_site.calloffs.open'.trans() }}
                            </td>
                            <td class="open-amount">{{ calloffDetailData.remaining_quantity_display || '—' }}</td>
                        </tr>
                    </table>
                </div>

                <table class="calloff-detail-table">
                    <tr>
                        <td>{{ "on_site.calloff_detail.material_type".trans() }}:</td>
                        <td>{{ calloffDetailData.material.category || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.material_number".trans() }}:</td>
                        <td>{{ calloffDetailData.material.number || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.material_name".trans() }}:</td>
                        <td>{{ calloffDetailData.material.name || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.incoterm".trans() }}:</td>
                        <td>{{ calloffDetailData.incoterm.title || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.truck_type".trans() }}:</td>
                        <td>{{ calloffDetailData.truck_type.title || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.daily_tonnage".trans() }}:</td>
                        <td :class="{ changed : calloffDetailData.call_off_previous_data && calloffDetailData.daily_tonnage.value !=  calloffDetailData.call_off_previous_data.quantity_raw }" >
                            {{ calloffDetailData.daily_tonnage.value + ' ' +  calloffDetailData.daily_tonnage.unit  || '—' }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.hourly_tonnage".trans() }}:</td>
                        <td>{{ calloffDetailData.hourly_tonnage_formatted + ' ' +  calloffDetailData.hourly_unit  || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.start_time".trans() }}:</td>
                        <td :class="{ changed : calloffDetailData.call_off_previous_data && (calloffDetailData.delivery_start_date !=  calloffDetailData.call_off_previous_data.delivery_date || calloffDetailData.delivery_start_time !=  calloffDetailData.call_off_previous_data.delivery_time) }">
                            {{ calloffDetailData.delivery_start_date + ' ' + calloffDetailData.delivery_start_time || '—' }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.calloff_detail.end_time".trans() }}:</td>
                        <td :class="{ changed : calloffDetailData.call_off_previous_data &&  (calloffDetailData.delivery_end_date !=  calloffDetailData.call_off_previous_data.delivery_end_date || calloffDetailData.delivery_end_time !=  calloffDetailData.call_off_previous_data.delivery_end_time) }">
                            {{ calloffDetailData.delivery_end_date + ' ' + calloffDetailData.delivery_end_time || '—' }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else>
            <div class="loader-container" title="Loading...">
                <svg version="1.1" class="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                        <path opacity="0.4" fill="#fff" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
                    <path fill="#fff" class="animated-bag-spinner-small" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                            C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(224.883 20 20)">
                        </path>
                    </svg>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script setup>
import {ref, onUnmounted, onMounted, computed} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'

import Header from '@/components/Common/Header.vue'
import SubHeader from '@/components/SubHeader/SubHeader.vue'
import Footer from '@/components/Common/Footer.vue'
import Button from "@/components/Common/Button.vue";
import {makeRequest} from "@/utils/makeRequest";

const route = useRoute()
const store = useStore()
const id = ref(route.params.id)
const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

const pageTitle = 'on_site.menu.calloff_detail'.trans()
const currentPage = 'on_site.menu.calloff_detail'.trans()

const calloffDetailData = ref(null)

async function fetchDetailData(id) {
    try {
        const response = await makeRequest('get', 'api_calloff_detail', {'id': id})
        calloffDetailData.value = response
    } catch (err) {
        console.error('Error fetching detail data:', err)
    }
}

onMounted(async() => {
    window.onscroll = () => {}
    fetchDetailData(id.value)
})

onUnmounted(() => {
})
</script>

<style lang="sass" scoped>
.loader-container
    display: flex
    align-items: center
    justify-content: center

    svg
        width: 120px
        height: 120px

        path
            fill: $text-blue

        .animated-bag-spinner-small
            transform-origin: 20px 20px
            animation: loading-spinner 0.9s linear infinite

    &.small
        svg
            width: 80px
            height: 80px

    @keyframes loading-spinner
        from
            transform: rotate(0deg)

        to
            transform: rotate(360deg)

.calloff-detail-page
    padding: 99px 0 51px
    min-height: calc(100vh - 51px - 48px - 81px)

    .calloff-header-container
        padding: 25px

        .calloff-status
            align-items: center
            display: flex

            img
                max-height: 28px
                max-width: 28px
                position: relative

            span
                font-family: PoppinsMedium
                font-size: 15px
                letter-spacing: 0.07px
                margin-left: 10px
                text-transform: uppercase

            &[data-status-id='0']
                color: $button-blue

            &[data-status-id='1']
                color: $green

            &[data-status-id='3']
                color: $dark-red

            &[data-status-id='4']
                color: $green

            &[data-status-id='5']
                color: $dark-red

            &[data-status-id='20']
                color: $dark-blue

                img
                    top: -2px

            &[data-status-id='30']
                color: $orange

                img
                    top: -3px

        .calloff-headline-container
            align-items: center
            display: flex
            margin: 3px 0 0
            position: relative

            img
                left: 0
                margin: 0 5px
                position: absolute
                top: 8px
                z-index: 3

            h3
                color: $dark-blue
                font-family: PoppinsMedium
                font-size: 15px
                font-weight: normal
                letter-spacing: 0.15px
                line-height: 24px
                margin: 0
                padding: 5px 5px 0 38px
                position: relative
                z-index: 2

        .calloff-detail-table
            tr
                td
                    font-family: PoppinsMedium
                    font-size: 12px
                    letter-spacing: 0.06px
                    line-height: 18px
                    text-align: left
                    vertical-align: top

                    &:first-child
                        padding-right: 10px

                td.changed
                    color: rgb(255, 157, 0)

                &.night-shift
                    td
                        &:first-child
                            padding-top: 4px

        .calloff-button-container
            margin-bottom: 20px

        .calloff-amounts-table-container
            border-top: 1px solid $button-blue
            border-bottom: 1px solid $button-blue
            margin: 10px 0
            padding: 10px 0

            .amounts-title
                color: $text-gray
                font-family: PoppinsMedium
                font-size: 12px
                letter-spacing: 0.02px
                line-height: 16px
                margin: 0
                padding: 0 3px
                text-align: left

            .calloff-table
                font-family: PoppinsMedium
                font-size: 13px
                letter-spacing: 0.03px
                line-height: 20px

                .icon-container
                    min-width: 160px
                    padding-right: 30px
                    text-align: left

                    img
                        margin-right: 4px

                .open-amount
                    color: $button-blue

                td
                    &:last-child
                        text-align: right

.dark-mode
    .loader-container
        path
            fill: $white

    .calloff-detail-page
        color: $white

        .calloff-header-container
            .calloff-headline-container
                h3
                    color: $white

            .calloff-amounts-table-container
                border-color: $white
</style>
