<script setup>
    import { onMounted, onBeforeUnmount } from 'vue'
    import AmountCard from '@/components/ContractDetails/AmountCard.vue'

    import '@/utils/slick/slick.min.js'

    const props = defineProps({
        items: Array
    })

    onMounted(() => {
        $('.daily-amount-slider').slick({
            arrows: false,
            dots: true
        })
    })

    onBeforeUnmount(() => {
		// Remove slick instance, called before unmount while the html is still reachable
		$('.daily-amount-slider').slick('unslick')
	})
</script>

<template>
    <div class="daily-amount-slider">
        <AmountCard
            v-for="item in props.items"
            :key="`item-${item.id}`"

            :isSliderItem="true"
            :materialName="item.material_designation"
            :materialNumber="item.material_number"
            :targetAmountRaw="item.target_quantity_raw"
            :targetAmount="item.target_quantity"
            :deliveredAmountRaw="item.delivered_quantity_raw"
            :deliveredAmount="item.delivered_quantity"
            :incomingAmountRaw="item.incoming_quantity_raw"
            :incomingAmount="item.incoming_quantity"
            :overDeliveredAmount="item.over_delivered_quantity"
            :overDeliveredAmountRaw="item.over_delivered_quantity_raw"
            :openAmountRaw="item.remaining_quantity_raw"
            :openAmount="item.remaining_quantity"
            :factoryName="item.factory_name"
            :factoryNumber="item.factory_number"
            :quantityUnit="item.quantity_unit"
        />
    </div>
</template>