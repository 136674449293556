import { makeRequest } from "@/utils/makeRequest"

const state = {
    data: {
        theme: 10,
    },
    selectedCompany: null,
    buttonPermissions: [],
    dashboardButtons: [],
    installPromptEvent: null,
    isRequesting: false
}

const getters = {

}

const actions = {
    updateSelectedTheme: ({ state, commit }, value = '10') => {
        const userData = {
            ...state.data,
            theme: value
        }

        commit('setUserData', userData)
    },
    updateNotificationSubscriptionState: ({ state, commit }, value = true) => {
        const userData = {
            ...state.data,
            has_notification_subscription: value
        }

        commit('setUserData', userData)
    },
    // Update only user name => called on profile updates
    updateUserName: ({ state, commit }, userName = '') => {
        const userData = {
            ...state.data,
            name: userName
        }

        commit('setUserData', userData)
    },
    fetchButtonPermissions: async ({ rootState, commit }) => {
        commit('setRequestingFlag', true)
        
        try {
            const response = await makeRequest('get', 'api_section_permissions', {'company_number': rootState.user.selectedCompany.company_nr})
            const { data } = response
            
            commit('setButtonPermissions', data)
            commit('setRequestingFlag', false)

        } catch(err) {

            commit('setRequestingFlag', false)
            throw err
        }
    },
    fetchDashboardButtons: async ({ rootState, commit }) => {
        commit('setRequestingFlag', true)
        
        try {
            const response = await makeRequest('get', 'api_dashboard_buttons', {'company_number': rootState.user.selectedCompany.company_nr})
            const { data } = response
            
            commit('setDashboardButtons', data)
            commit('setRequestingFlag', false)

        } catch(err) {
            commit('setRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    setUserData: (state, userData) => {
        state.data = userData
    },
    setSelectedCompany: (state, companyData) => {
        state.selectedCompany = companyData
    },
    setUserDataToS: (state) => {
        // just needs to be not null for now, format later if needed
        state.data.accepted_terms_of_use_date = Date.now()
    },
    setButtonPermissions: (state, permissions) => {
        state.buttonPermissions = permissions
    },
    setDashboardButtons: (state, data) => {
        state.dashboardButtons = data
    },
    setInstallPromptEvent: (state, event) => {
        state.installPromptEvent = event
    },
    setRequestingFlag: (state, value = true) => {
        state.isRequesting = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
