<template>
    <div class="calloff-creation-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader :currentPage="currentPage"/>
        <Loader>
            <div class="calloff-creation-top-container">
                <div class="calloff-creation-title">
                    <img alt="Green" src="../../assets/icons/icon-binding-request.svg"/>
                    <span>{{ 'on_site.calloffs.binding-request'.trans() }}</span>
                </div>

                <ContractItemCard
                    :creationCard="true"
                    :key="contractItemCardData.id"
                    :item="contractItemCardData"
                />
            </div>

            <div class="calloff-creation-main-container">
                <div class="calloff-creation-main-central-content">
                    <p class="step-name">{{ 'on_site.calloff_creation.step_1_name'.trans() }}</p>
<!--                    <component @changeStep="changeStep" :is="currentStepComponent"/>-->
                    <CalloffCreationStepOne @changeStep="changeStep" v-if="calloffCreationData && stepNumber === 1" />
                    <CalloffCreationStepTwo @changeStep="changeStep" v-if="stepNumber === 2" />
                    <CalloffCreationStepThree @changeStep="changeStep" v-if="stepNumber === 3" />
                </div>
            </div>
        </Loader>
        <Footer/>
    </div>
</template>

<script setup>
import {computed, ref, onUnmounted, onMounted} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'

import Header from '@/components/Common/Header.vue'
import SubHeader from '@/components/SubHeader/SubHeader.vue'
import Footer from '@/components/Common/Footer.vue'
import Loader from '@/components/Common/Loader.vue'
import ContractItemCard from '@/components/CallOffs/ContractItemCard.vue'
import CalloffCreationStepOne from '@/components/CalloffCreation/CalloffCreationStepOne.vue'
import CalloffCreationStepTwo from '@/components/CalloffCreation/CalloffCreationStepTwo.vue'
import CalloffCreationStepThree from '@/components/CalloffCreation/CalloffCreationStepThree.vue'
import Swal from 'sweetalert2'

const store = useStore()
const route = useRoute()
const calloffCreationData = ref(null)
const stepNumber = ref(1)

const pageTitle = store.state.contracts.selectedContract.contract_name
const currentPage = 'on_site.menu.calloff_creation'.trans()

const contractItemCardData = computed(() => store.getters['calloffCreation/getCalloffData'])

// Logic for changing steps, depends on the number that child component emitted
const changeStep = (newStepNumber) => {
    stepNumber.value = newStepNumber
}

let CALLOFF_LOCKED = false
let BACKLINK_LOCKED = false

let menuButtonsReplaced = { value: false }
let navigationButtonsReplaced = { value: false }
let footerButtonsReplaced = { value: false }

onMounted(async() => {
    await store.dispatch('calloffCreation/fetchCartData', route.params.id)
    await store.dispatch('calloffCreation/fetchContractItemData', route.params.id)
    store.commit('calloffCreation/setCalloffCreationData', {
        contact_person: contractItemCardData.value.available_contact_persons.find(person => person.id === Number(store.state.user.data.id))
    })
    calloffCreationData.value = store.getters['calloffCreation/getCalloffData']
    store.dispatch('calloffCreation/fetchContractItemData', route.params.id)
})

onUnmounted(() => {
    store.commit('calloffCreation/setCalloffCreationData', {
        incoterm: {'id': null},
        truck_type: {'id': null},
        cart_item_quantity_raw: null,
        cart_item_quantity: 't',
        amount_warning_limit_raw: 0,
        hourly_amount_raw: null,
        hourly_amount: "t/h",
        note: null,
        night_shift: false,
        date_start_pretty: null,
        date_end_pretty: null,
        date_start_time: null,
        date_end_time: null,
        contact_person: {'id': null},
        id: null
    })
})

lockCalloff(true)

$(document).on('disable-lock-calloff', '.calloff-creation-main-central-content', function() {
    lockCalloff(false)
})

$(document).on('enable-lock-calloff', '.calloff-creation-main-central-content', function() {
    lockCalloff(true)
})

$(document).on('disable-lock-backlink', '.calloff-creation-main-central-content', function() {
    lockBacklink(false)
})

$(document).on('enable-lock-backlink', '.calloff-creation-main-central-content', function() {
    lockBacklink(true)
})

function lockCalloff(state) {
    CALLOFF_LOCKED = state

    if(state) {
        replaceButtons('.menu-container a.menu-button', menuButtonsReplaced)
        replaceButtons('.navigation-menu a.navigation-button', navigationButtonsReplaced)
        replaceButtons('.footer a.link', footerButtonsReplaced)
    }
}

function lockBacklink(state) {
    BACKLINK_LOCKED = state
}

function replaceLinkWithDiv(element) {
    const newDiv = $('<div></div>')

    $.each(element.attributes, function() {
        newDiv.attr(this.name, this.value)
    })

    newDiv.html($(element).html())

    $(element).replaceWith(newDiv)
}

function replaceButtons(selector, replacedFlag) {
    const buttons = $(selector)

    if (buttons.length === 0 && !replacedFlag.value) {
        setTimeout(function () {
            replaceButtons(selector, replacedFlag)
        }, 300)
    } else {
        buttons.each(function () {
            replaceLinkWithDiv(this)
        })

        replacedFlag.value = true
    }
}

function showCalloffAbortPopup(backLink) {
    Swal.fire({
        icon: 'warning',
        title: 'on_site.calloff_creation.abort.title'.trans(),
        html: 'on_site.calloff_creation.abort.text'.trans(),
        showCancelButton: true,
        confirmButtonText: 'on_site.calloff_creation.abort.confirm.button_text'.trans(),
        cancelButtonText: 'on_site.calloff_creation.abort.cancel.button_text'.trans(),
        customClass: {
            popup: 'calloff-abort-popup'
        }
    }).then(result => {
        if (result.isConfirmed) {
            goTo(backLink)
        }

        if (result.isDismissed) {
            closeMenus()
        }
    })
}

function goTo(link) {
    history.pushState(null, null, link)
    window.location.href = link
}

function closeMenus() {
    const mainHamburger = $('.main-header .hamburger')
    const navigationMenu = $('.sub-header-navigation .navigation-menu')
    const navigationMenuToggler = $('.sub-header-navigation .navigation-menu-toggler')

    if(mainHamburger.hasClass('is-Active')) {
        mainHamburger.trigger('click')
    }

    if(navigationMenu.hasClass('open')) {
        navigationMenuToggler.trigger('click')
    }
}

$(document).ready(function() {
    const backLink = history.state.back ? history.state.back : window.location.pathname

    // Prevent browser back logic if CALLOFF_LOCKED is true
    $(window).on('popstate', function(event) {
        if(CALLOFF_LOCKED || BACKLINK_LOCKED) {
            history.forward()
        } else {
            goTo(backLink)
        }

        if(CALLOFF_LOCKED) {
            showCalloffAbortPopup(backLink)
        }
    })

    history.pushState({ page: 1 }, '', '')

    $(document).on('click', '.main-menu .menu-button, .navigation-menu .navigation-button, .footer .link', function(e) {
        if(CALLOFF_LOCKED) {
            e.preventDefault()
            const href = $(this).attr('href')

            history.forward()
            showCalloffAbortPopup(href)
        }
    })
})
</script>

<style lang="sass" scoped>
.calloff-creation-page
    padding-top: 51px + 48px
    // header + subheader
    padding-bottom: 51px
    // footer
    min-height: calc(100vh - 51px - 48px - 81px)

    .calloff-creation-top-container
        max-width: 374px
        margin: 0px auto 0px auto

        .calloff-creation-title
            margin-left: 10px
            margin-top: 10px
            text-align: left

            img
                max-height: 20px
                margin-right: 10px
                    max-width: 20px
                    position: relative
                    top: 5px

            span
                font-family: PoppinsSemiBold
                font-size: 15px
                letter-spacing: 0.07px
                line-height: 23px
                text-transform: uppercase
                color: $green

    .calloff-creation-main-container
        background-color: $text-blue
        min-height: calc(100vh - 417px)

        .calloff-creation-main-central-content
            max-width: 374px
            margin: 0px auto 0px auto
            padding: 10px

            .step-name
                margin: 1px 0px 7px 6px
                font-family: PoppinsMedium
                font-size: 12px
                letter-spacing: 0.02px
                text-align: left
                color: $white
</style>
