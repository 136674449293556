<template>
    <div class="register-page">
        <router-link :to="{ name: 'login' }">
            <img class="register-page-close" alt="Show password" src="@/assets/icons/close-gray.svg">
        </router-link>
        <div class="register-name-inputs">
            <input class="standard-input" :class="{ 'warning': warnings.firstName.value }" v-model="firstName" :placeholder="placeholders.firstName" @input="removeWarning('firstName')"/>
            <input class="standard-input" :class="{ 'warning': warnings.lastName.value }" v-model="lastName" :placeholder="placeholders.lastName" @input="removeWarning('lastName')"/>
        </div>

        <div class="register-number-inputs">
            <div class="register-number-input">
                <Select2 class="register-phone-select-container" :options="[...phoneOptions]" :settings="phoneSelectSettings" v-model="phoneCountry"/>
                <input class="standard-input" :class="{ 'warning': warnings.phone.value }" type="number" v-model="phone" :placeholder="placeholders.phone" @input="removeWarning('phone')"/>
            </div>
            <div class="register-number-input">
                <Select2 class="register-phone-select-container" :options="[...faxOptions]" :settings="phoneSelectSettings" v-model="faxCountry"/>
                <input class="standard-input" type="number" v-model="fax" :placeholder="placeholders.fax"/>
            </div>
            <div class="register-number-input">
                <Select2 class="register-phone-select-container" :options="[...mobileOptions]" :settings="phoneSelectSettings" v-model="mobileCountry"/>
                <input class="standard-input" type="number" v-model="mobile" :placeholder="placeholders.mobile"/>
            </div>
        </div>

        <div class="register-factory-position">
            <Select2 class="register-position-select-container" :class="{ 'warning': warnings.position.value }" :options="positionOptions" :settings="positionSettings" v-model="position" :placeholder="placeholders.position" @select="removeWarning('position')"/>
        </div>
        <div class="register-email-input">
            <input class="standard-input" :class="{ 'warning': warnings.email.value }" v-model="eMail" :placeholder="placeholders.email" @input="removeWarning('email')"/>
        </div>
        <div class="register-password-container">
            <div class="register-password-input">
                <input id="register-password" class="standard-input" :class="{ 'warning': warnings.password.value }" v-model="passwordValue" :type="passwordInputType" :placeholder="placeholders.password" @keyup="regexPassword(), removeWarning('password')"/>
                <img class="show-pass-icon password" @click="togglePasswordVisibility" alt="Show password" :src="togglePasswordVisibilityIcon">
            </div>
            <div class="register-password-input-confirm">
                <input id="register-password-repeat" class="standard-input" :class="{ 'warning': !passwordsEqual }" v-model="passwordRepeatValue" :type="passwordRepeatInputType" :placeholder="placeholders.confirmPassword"/>
                <img class="show-pass-icon password-repeat" @click="togglePasswordVisibility" alt="Show password" :src="togglePasswordRepeatVisibilityIcon">
            </div>
            <div class="password-tips">
                <p class="password-warning" :class="{ 'is-valid': passwordLengthGood }" data-type="symbols">{{'on_site.minimal_8_letters'.trans()}}</p>
                <p class="password-warning" data-type="number">{{'on_site.minimal_one_number'.trans()}}</p>
                <p class="password-warning" data-type="uppercase">{{'on_site.minimal_one_uppercase_letter'.trans()}}</p>
                <p class="password-warning" data-type="lowercase">{{'on_site.minimal_one_lowercase_letter'.trans()}}</p>
                <p class="password-warning" data-type="special_char">{{'on_site.minimal_one_special_sign'.trans()}}</p>
            </div>
        </div>
        <div class="register-code-container">
            <input class="standard-input" :class="{ 'warning': warnings.invitationCode.value }" v-model="invitationCode" :placeholder="placeholders.invitationCode" @input="removeWarning('invitationCode')"/>
        </div>
        <div class="register-terms-container">
            <div class="input-container">
                <label for="terms-of-use-checkbox" :class="{ 'warning': warnings.termsOfUse.value }">
                    <input id="terms-of-use-checkbox" type="checkbox" v-model="termsOfUse" @change="removeWarning('termsOfUse')">
                    <span> Ich stimme den 
                        <router-link :to="{ name: 'terms-of-use', params: valuesToSaveOnRedirect }">Nutzungbedingungen</router-link> zu.
                    </span>
                </label>
            </div>
            <div class="input-container">
                <label for="privacy-terms-checkbox" :class="{ 'warning': warnings.privacyTerms.value }">
                    <input id="privacy-terms-checkbox" type="checkbox" v-model="privacyTerms" @change="removeWarning('privacyTerms')">
                    <span> Ich stimme den  
                        <router-link :to="{ name: 'privacy-terms', params: valuesToSaveOnRedirect }">Datenschutzbestimmungen</router-link> zu.
                    </span>
                </label>
            </div>
        </div>
        <div class="register-buttons">
            <Button
                blue
                bold
                full-width
                :click-event="register"
            >
                {{'on_site.register.button'.trans()}}
            </Button>
            <Button
                bold
                full-width
                transparent
                :to="{ name: 'login' }"
            >
                {{'on_site.registration-cancel.button'.trans()}}
            </Button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Select2 from 'vue3-select2-component'
import { makeRequest } from '@/utils/makeRequest.js'
import { showErrorPopup } from '@/utils/globalSwalPopups.js'
import Button from "@/components/Common/Button.vue";

const store = useStore()
const router = useRouter()
const route = useRoute()

store.dispatch("getCountryCodes")

// Generating translations for placeholders
const placeholders = {
    firstName: 'on_site.register.placeholder.first_name'.trans(),
    lastName: 'on_site.register.placeholder.last_name'.trans(),
    phone: 'on_site.register.placeholder.phone'.trans(),
    fax: 'on_site.register.placeholder.fax'.trans(),
    mobile: 'on_site.register.placeholder.mobile'.trans(),
    position: 'on_site.register.placeholder.position'.trans(),
    email: 'on_site.login.placeholder.email'.trans(),
    password: 'on_site.login.placeholder.password'.trans(),
    confirmPassword: 'on_site.register.placeholder.confirm_password'.trans(),
    invitationCode: 'on_site.register.placeholder.invitation_code'.trans()
}


// Starting values for variables bound in html
const togglePasswordVisibilityIcon = ref(require('@/assets/icons/eye.svg'))
const togglePasswordRepeatVisibilityIcon = ref(require('@/assets/icons/eye.svg'))
const passwordInputType = ref('password')
const passwordRepeatInputType = ref('password')
const passwordValue = ref('')
const passwordRepeatValue = ref('')
const firstName = ref(route.params.firstName || '')
const lastName = ref(route.params.lastName || '')
const phone = ref(route.params.phone || '')
const fax = ref(route.params.fax || '')
const mobile = ref(route.params.mobile || '')
const eMail = ref(route.params.eMail || '')
const invitationCode = ref(route.params.invitationCode || '')
const termsOfUse = ref(route.params.termsOfUse || false)
const privacyTerms = ref(route.params.privacyTerms || false)


// Initializing variables that track validity of form fields
const warnings = {
    firstName: ref(false),
    lastName: ref(false),
    phone: ref(false),
    position: ref(false),
    email: ref(false),
    password: ref(false),
    invitationCode: ref(false),
    termsOfUse: ref(false),
    privacyTerms: ref(false)
}


// Configuration for the phone country dropdowns
const {phoneCountry: phoneOptions, faxCountry: faxOptions, mobileCountry: mobileOptions} = store.state.countryCodes

const phoneSelectSettings = {
    dropdownCssClass: 'register-phone-select-dropdown',
    minimumResultsForSearch: -1
}
const phoneCountry = ref(route.params.phoneCountry || '+49')
const faxCountry = ref(route.params.faxCountry || '+49')
const mobileCountry = ref(route.params.mobileCountry || '+49')


// Configuration for the factory position dropdown
const positionOptions = [
    { id:'6', text:'on_site.position.owner'.trans() },
    { id:'1', text:'on_site.position.foreman'.trans() },
    { id:'2', text:'on_site.position.buyer'.trans() },
    { id:'3', text:'on_site.position.audit'.trans() },
    { id:'4', text:'on_site.position.construction_manager'.trans() },
    { id:'5', text:'on_site.position.other'.trans() },
]
const positionSettings = {
    dropdownCssClass: 'register-position-select-dropdown',
    minimumResultsForSearch: -1
}
const position = ref(route.params.position || '')


// Toggling the red border on wrong password repeat
const passwordsEqual = computed(() => {
    return passwordValue.value == passwordRepeatValue.value
})


// Toggling the red reminder that password isnt long enough
const passwordLengthGood = computed(() => {
    return passwordValue.value.length >= 8
})


// Saving the values that need to be prefilled when coming back to registration screen
// Reactive is necessary to create an object made of refs
const valuesToSaveOnRedirect = reactive({
    firstName,
    lastName,
    phoneCountry,
    phone,
    faxCountry,
    fax,
    mobileCountry,
    mobile,
    position,
    eMail,
    invitationCode,
    termsOfUse,
    privacyTerms
})


//Logic for checking if the rest of the password conditions are fulfilled 
function regexPassword() {
    const passwordWarnings = $('.password-tips')

    const regexObj = {
        number: /[0-9]/,
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        special_char: /[#!@$%^+'.,:;&§[{}*=`°_\-\]\?]/
    }

    Object.keys(regexObj).forEach((key) => {
        if (passwordValue.value.match(regexObj[key])){
            passwordWarnings.find(`[data-type='${key}']`).addClass('is-valid')

        } else {
            passwordWarnings.find(`[data-type='${key}']`).removeClass('is-valid')
        }
    }) 
}


// Logic for toggling password visibility
function togglePasswordVisibility(event) {
    $(event.target).toggleClass('hide-password')

    if ($(event.target).hasClass('password')) {
        if ($(event.target).hasClass('hide-password')) {
            togglePasswordVisibilityIcon.value = require('@/assets/icons/eye_off.svg')
            passwordInputType.value = 'text'
        } else {
            togglePasswordVisibilityIcon.value = require('@/assets/icons/eye.svg')
            passwordInputType.value = 'password'
        }

    } else if ($(event.target).hasClass('password-repeat')) {
        if ($(event.target).hasClass('hide-password')) {
            togglePasswordRepeatVisibilityIcon.value = require('@/assets/icons/eye_off.svg')
            passwordRepeatInputType.value = 'text'
        } else {
            togglePasswordRepeatVisibilityIcon.value = require('@/assets/icons/eye.svg')
            passwordRepeatInputType.value = 'password'
        }
    }
}


// Logic for registration
async function register() {
    const formIsValid = validate()
    
    if (formIsValid) {
        const payload = {
            'first_name': firstName.value,
            'last_name': lastName.value,
            'phone_country': phoneCountry.value,
            'phone': phone.value,
            'fax_country': faxCountry.value,
            'fax': fax.value,
            'mobile_phone_country': mobileCountry.value,
            'mobile_phone': mobile.value,
            'company_position': position.value,
            'email': eMail.value,
            'password': passwordValue.value,
            'password_repeat': passwordRepeatValue.value,
            'invitation_token': invitationCode.value,
            'accept_terms_of_use': termsOfUse.value,
            'accept_privacy_policy': privacyTerms.value
        }

        try {
            const data = await makeRequest('post', 'api-register', payload, false)

            store.commit('setAccessToken', data.data.access_token)
            store.commit('userLoggedIn')

            router.push({ name: 'dashboard' })

        } catch {
            showErrorPopup()
        }
    }
}


// Logic for validating all input fields
function validate() {
    let formIsValid = true
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (firstName.value == '') {
        formIsValid = false
        warnings.firstName.value = true
    }
    if (lastName.value == '') {
        formIsValid = false
        warnings.lastName.value = true
    }
    if (phone.value == '') {
        formIsValid = false
        warnings.phone.value = true
    }
    if (position.value == '') {
        formIsValid = false
        warnings.position.value = true
    }
    if (!eMail.value.match(emailRegex)) {
        formIsValid = false
        warnings.email.value = true
    }
    if (invitationCode.value == '') {
        formIsValid = false
        warnings.invitationCode.value = true
    }
    if (termsOfUse.value == '') {
        formIsValid = false
        warnings.termsOfUse.value = true
    }
    if (privacyTerms.value == '') {
        formIsValid = false
        warnings.privacyTerms.value = true
    }

    // password constraints check
    $('.password-warning').each(function() {
        if (!$(this).hasClass('is-valid')) {
            formIsValid = false
            warnings.password.value = true

            return false
        }
    })

    // passwords equality check
    if (!passwordsEqual.value) {
        formIsValid = false
    }
    
    return formIsValid
}


// Remove warning on input value change
function removeWarning(fieldName) {
    warnings[fieldName].value = false
}


// If already logged in, redirect to dashboard
if (store.state.userLoggedIn) {
    router.push({ name: 'dashboard' })
}
</script>


<style lang="sass" scoped>
.register-page
    align-items: center
    background-color: $background-gray
    display: flex
    flex-direction: column
    margin: auto
    padding: 60px 0px
    position: relative
    width: 250px

    .register-page-close
        position: absolute
        right: 15px
        top: 15px

    .standard-input
        padding-left: 12px
        width: 250px
        color: $placeholder-gray

        &.warning
            border: 1px solid $bright-red

    label
        &.warning
            color: $bright-red

    .register-name-inputs
        display: flex
        flex-flow: column

    .register-number-inputs
        .register-number-input
            display: flex
            width: 250px

            .select-container
                width: 70px
                margin-bottom: 7px

            .standard-input
                margin-left: 5px
                width: 170px

    .register-password-container
        .register-password-input, .register-password-input-confirm
            position: relative

            .show-pass-icon
                position: absolute
                padding: 15px 10px 15px 10px
                top: 3px
                right: 8px

                &.hide-password
                    top: -1px

            #register-password-repeat
                box-sizing: border-box
                width: 250px
                
                &.warning
                    border: 1px solid $bright-red

        .password-tips
            width: 250px
            text-align: left

            .password-warning
                font-size: 12px
                color: $red

                &.is-valid
                    color: $green

    .register-code-container
        margin-top: 5px
        margin-bottom: 5px
    
    .register-terms-container
        width: 250px
        text-align: left

        .input-container
            display: flex
            align-items: center
            margin-bottom: 10px

            label
                display: flex
                align-items: center

            span
                margin-left: 20px
                margin-top: 2px
            
            span::before 
                content: ""
                display: block
                position: absolute
                margin-top: 2px
                width: 14px
                height: 14px
                top: 0
                left: 0
                background: #ffffff
                border: 1px solid #e4e5e7
                border-radius: 4px
                

            span::after 
                display: block
                position: absolute
                margin-top: 3px
                margin-left: 3px
                width: 10px
                height: 10px
                top: 0
                left: 0
                border-radius: 3px
                
            input[type="checkbox"] 
                opacity: 0
                position: absolute
                

            input[type="checkbox"]:checked + span::after 
                content: url("@/assets/icons/checkmark-blue.svg")
                background: transparent
                color: #fff
                
            label
                position: relative
                font-size: 11px

                a
                    text-decoration: none
                    color: $button-blue

    .register-buttons
        display: flex
        flex-direction: column
        margin-top: 10px
        width: 100%

        .submit-registration-button
            font-family: PoppinsMedium

        .cancel-registration-button
            margin-top: 10px

            a
                text-decoration: none
                color: $names-gray

.dark-mode
    .register-page
        background-color: $text-black

        .register-terms-container
            span
                color: $dark-text-color

                a
                    color: $white

        .register-buttons
            .cancel-registration-button
                a
                    color: $white
</style>

<style lang="sass">
.register-page
    .register-phone-select-container
        width: 100%

        .select2-container
            width: 100% !important

            .select2-selection
                height: 50px
                border: none

                .select2-selection__rendered
                    padding-top: 12px
                    font-size: 14px
                    color: $placeholder-gray

                .select2-selection__arrow
                    padding-top: 47px
                    margin-right: 5px

    .register-position-select-container
        width: 100%
        margin-bottom: 7px

        .select2-container
            width: 250px !important

            .select2-selection
                height: 52px
                border: none

                .select2-selection__rendered
                    padding-top: 12px
                    padding-left: 12px
                    font-size: 14px
                    text-align: left
                    color: $placeholder-gray

                    .select2-selection__placeholder
                        color: $placeholder-gray

                .select2-selection__arrow
                    padding-top: 47px
                    margin-right: 5px

        &.warning
            .select2-selection
                border: 1px solid $bright-red

.register-phone-select-dropdown,
.register-position-select-dropdown
    border-color: $white

    .select2-results__options
        border-bottom-left-radius: 4px
        border-bottom-right-radius: 4px

        .select2-results__option
            padding-top: 10px
            padding-bottom: 10px
            padding-left: 12px
            font-family: PoppinsRegular
            font-size: 14px
            color: $placeholder-gray

            &.select2-results__option--highlighted
                background-color: $light-blue
                color: $white
</style>