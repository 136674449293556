<script setup>
    import Header from '@/components/Common/Header.vue'
    import Footer from '@/components/Common/Footer.vue'
    import Loader from '@/components/Common/Loader.vue'

    import UserInfo from '@/components/Profile/UserInfo.vue'
    import UserForm from '@/components/Profile/UserForm.vue'
    import ChangePassword from '@/components/Profile/ChangePassword.vue'

    import { shield } from '@/utils/svgIcons'

    import { useStore } from 'vuex'
    import { computed, ref } from 'vue'
    import Button from "@/components/Common/Button.vue";

    const store = useStore()
    const pageTitle = 'on_site.page-title.profile'.trans()

    const isPasswordFormVisible = ref(false)

    // View specific
    const storeValues = store.state.settings

    const selectedCompanyNumber = computed(() =>
        store.state.user.selectedCompany
            ? store.state.user.selectedCompany.company_nr
            : null
    )

    // Page load flag
    const isRequesting = computed(() => storeValues.isRequesting)

    const togglePasswordForm = () => {
        const newValue = !isPasswordFormVisible.value
        isPasswordFormVisible.value = newValue
    }
</script>

<template>
  <div class="profile-page">
    <Header :pageTitle="pageTitle"/>
    <Loader v-if="isRequesting" />
    <div class="profile-container" v-else>
        <UserInfo />

        <Button
            blue
            bold
            :green="isPasswordFormVisible.value"
            :key="isPasswordFormVisible.value"
            :click-event="togglePasswordForm"
        >
            {{'on_site.profile.change_password_button'.trans()}}
            <span class="icon" v-html="shield" />
        </Button>

        <ChangePassword :class="{ 'hidden': !isPasswordFormVisible }" @on-close="togglePasswordForm" />
        <UserForm :class="{ 'hidden': isPasswordFormVisible }" />
    </div>
    <Footer/>
  </div>
</template>

<style lang="sass">
.profile-page
    display: flex
    position: relative
    box-shadow: 0 3px 6px #01000029

    padding-top: 51px
    padding-bottom: 81px
    min-height: calc(100vh - 55px)

    width: 100%

    .loader-container
        width: 100%
        padding-top: 10vh

        display: flex
        align-items: flex-start

    .profile-container
        display: flex
        flex-direction: column
        margin: 0px auto
        padding: 26px 20px 0px 20px
        text-align: left

        width: 100%
        max-width: 450px

        .form-group
            input
                background: $white
                width: 100%

        .user-form-container
            flex-grow: 1
            display: flex
            flex-direction: column

            &.hidden
                display: none

            .user-name-container
                p
                    margin: 14px 0px
                    font-family: PoppinsSemiBold
                    font-size: 12px
                    letter-spacing: 0.02px
                    color: $dark-blue

            .user-numbers-container
                margin-top: 25px
                margin-bottom: 25px
                flex-grow: 1

                .profile-number-input
                    display: flex
                    flex-direction: row

                    .select-container
                        margin-right: 5px

                        .select2-container
                            width: 93px !important
                            height: 42px !important

                            .select2-selection
                                border: 1px solid $input-border
                                border-bottom-right-radius: 0
                                border-top-right-radius: 0

                                height: 42px !important

                                .select2-selection__rendered
                                    height: 42px
                                    line-height: 42px

                                    font-size: 14px
                                    font-family: PoppinsRegular

                                .select2-selection__arrow
                                    b
                                        margin-top: 6px

                        &.has-error
                            .select2-container
                                .select2-selection
                                    border: 1px solid $login-error-red

                    .form-group
                        flex-grow: 1

                        input
                            height: 42px
                            line-height: 42px
                            border-top-left-radius: 0
                            border-bottom-left-radius: 0

                            &::-webkit-inner-spin-button
                                position: absolute
                                opacity: 0
                                visibility: none

            .save-profile-button
                width: 100%
                pointer-events: none

                &.button-enabled
                    pointer-events: auto
                    background-color: $button-blue

        .change-password-container
            margin-top: 18px
            //margin-bottom: 25px

            width: 100%
            max-width: 450px

            flex-grow: 1
            display: flex
            flex-direction: column

            &.hidden
                display: none

            .form-group
                margin-bottom: 12px

            .password-tips
                padding-left: 30px
                padding-top: 8px

                flex-grow: 1

                .tip
                    color: $red
                    font-size: 13px
                    font-weight: normal
                    font-family: PoppinsRegular
                    line-height: 18px

                    margin-bottom: 15px

                    &.valid
                        color: $green

            .buttons-container
                display: flex
                flex-direction: row

                justify-content: space-between

                button
                    margin: 0
                    width: calc(50% - 20px)

                    @media (max-width: 450px)
                        width: calc(50% - 10px)

        .save-profile-button
            margin: 0px auto
            font-family: PoppinsMedium
            font-size: 16px
            height: 50px

.country-select-dropdown
    border: 1px solid $input-border

    .select2-results__option
        font-family: PoppinsMedium
        font-size: 12px
        color: $text-blue

        padding: 10px 8px

        &--highlighted[aria-selected]
            background-color: $text-blue

.dark-mode
    .profile-page
        background-color: $text-black

        .profile-container
            .user-form-container
                .user-name-container
                    p
                        color: $dark-text-color

    .user-numbers-container
        .select2-selection--single
            background-color: $dark-button-gray

            .select2-selection__rendered
                color: $white
</style>