const state = {
    announcementData: {},
    showAnnouncement: false,
    doneRequesting: false
}

const getters = {

}

const actions = {
    fetchAnnouncementData: async ({ state, commit, rootState }) => {
        const announcementRoute = Routing.generate('api_get_annoucements', { 'device_type': 'pwa' }, true)

        const userIsLoggedIn = rootState.userLoggedIn

        try {
            let response = {}

            if (userIsLoggedIn) {
                response = await axios({
                    method: 'get',
                    url: announcementRoute,
                    headers: {
                        'Authorization': `Bearer ${rootState.accessToken}`
                    }
                })

            } else {
                response = await axios.get(announcementRoute)
            }
            
            const oldAnnouncementData = JSON.stringify(state.announcementData)

            // if there is more than one only save the first one from the response
            if (Array.isArray(response.data.data)) {
                commit('setAnnouncementData', response.data.data[0])

            } else {
                commit('setAnnouncementData', response.data.data)
            }

            // if the announcement is new/different then it needs to be shown
            if (state.announcementData && (JSON.stringify(state.announcementData) != oldAnnouncementData)) {
                commit('setShowAnnouncement', true)
            } else {
                commit('setShowAnnouncement', false)
            }

            // if the announcement is permanent then it needs to be shown
            if (state.announcementData && state.announcementData.is_permanent) {
                commit('setShowAnnouncement', true)
            }

            // if the announcement gets deleted/expires then dont show it or if the message is empty
            if (!state.announcementData || (state.announcementData && !state.announcementData.message)) {
                commit('setShowAnnouncement', false)
            }

            commit('setDoneRequesting', true)

        } catch (err) {
            throw err
        }
    }
}

const mutations = {
    setAnnouncementData(state, value) {
        state.announcementData = value
    },
    setShowAnnouncement(state, value) {
        state.showAnnouncement = value
    },
    setDoneRequesting(state, value) {
        state.doneRequesting = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}