import { makeRequest } from '@/utils/makeRequest'
import {createLogger, useStore} from "vuex";
import { isProxy, toRaw } from 'vue';
import store from '@/store'

const state = {
    contractItems: {
        items: [],
        pagination: {}
    }
}

const getters = {
    getCalloffItems: (state) => state.contractItems.items,
    getCalloffPagination: (state) => state.contractItems.pagination
}

const actions = {
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchCalloffItemsOnLoad: async (
        { commit, rootState },
        params = { 'contract_id' : contract_id }
    ) => {
        try {
            let contract_id = params['contract_id']

            params = toRaw(params)

            if(typeof params === 'undefined') {
                let params = {}
            }

            if(!params['material_category'] && !params['status']) {
                params['material_category'] = [0, 1, 2, 3, 4]
                params['status'] = [0, 1, 20, 4, 30]
            } else {
                $('.tags-container .filter-tag.active').each(function (index, object) {
                    let $element = $(object)

                    let elementData = $element.data()

                    if(!params[elementData.filterType]) {
                        params[elementData.filterType] = []
                    }

                    params[elementData.filterType].push(elementData.filterId)
                })
            }

            if(Object.keys(toRaw(rootState.filters.calloffFilters)).length > 0) {
                let urlParams = rootState.filters.calloffFilters

                params = urlParams
            }

            delete(params['query'])

            if($('#contract-overview-select').length > 0) {
                let queryParam = $('#contract-overview-select').val()

                if(typeof queryParam[0] !== 'undefined') {
                    params['query'] = queryParam[0]
                }
            }

            let rawParams = toRaw(params)

            if(typeof rawParams['material_category'] !== 'undefined') {
                rawParams['material_category'] = Array.from(new Set(rawParams['material_category']))
            }

            if(typeof rawParams['status'] !== 'undefined') {
                rawParams['status'] = Array.from(new Set(rawParams['status']))
            }

            delete(rawParams['contract_number'])

            rawParams['contract_id'] = contract_id

            if(!rawParams['contract_id']) {
                rawParams['contract_id'] = location.pathname.split('/')[2]
            }

            const response = await makeRequest('get', 'api_calloff_list', rawParams )
            const { data } = response

            resetFilterTagsFrontend(toRaw(params))

            // This will also reset items & pagination
            commit('setCalloffItemsData', data)
        } catch(err) {
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchCalloffItemsPaginated: async ({ commit, rootState },
        params = { 'contract_id': rootState.contracts.selectedContract.contract_id }
    ) => {
        try {
            const response = await makeRequest('get', 'api_calloff_list', params )
            const { data } = response

            // This will set merge items & update pagination
            commit('setContractItemsPaginatedData', data)
        } catch(err) {
            throw err
        }
    }
}

function resetFilterTagsFrontend (payload) {
    let categoryElements = $('[data-filter-type="material_category"]')

    categoryElements.each(function (index, element) {
        let elementData = $(element).data()

        if (payload.material_category.includes(elementData.filterId)) {
            $(element).addClass('active')
        } else {
            $(element).removeClass('active')
        }
    })

    let statusElements = $('[data-filter-type="status"]')

    statusElements.each(function (index, element) {
        let elementData = $(element).data()

        if (payload.status.includes(elementData.filterId)) {
            $(element).addClass('active')
        } else {
            $(element).removeClass('active')
        }
    })
}

const mutations = {
    setCalloffItemsData: (state, contractItems) => {
        state.contractItems = contractItems
    },
    setContractItemsPaginatedData: (state, contractItems) => {
        const { items, pagination } = contractItems
        state.contractItems = {
            pagination,
            items: [ ...state.contractItems.items, ...items ]
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
