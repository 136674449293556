import { makeRequest } from '@/utils/makeRequest'

const state = {
    data: {}
}

const getters = {
    getCalloffData: (state) => state.data
}

const actions = {
    fetchDetailData: async ({ commit, state }, id) => {
        try {
            const response = await makeRequest('get', 'api_calloff_detail', {'id': id})

            commit('setCalloffData', response)
        } catch(err) {
            throw err
        }
    }
}

const mutations = {
    setCalloffData: (state, data) => {
        state.data = {
            ...state.data,
            ...data
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}