import { makeRequest } from '@/utils/makeRequest'

const state = {
    contractItems: {
        items: [],
        pagination: {}
    }
}

const getters = {
    getContractItems: (state) => state.contractItems.items,
    getContractPagination: (state) => state.contractItems.pagination
}

const actions = {
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchContractItemsOnLoad: async (
        { commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        try {
            const response = await makeRequest('get', 'api_get_contract_items', params )
            const { data } = response

            // This will also reset items & pagination
            commit('setContractItemsData', data)
        } catch(err) {
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchContractItemsPaginated: async ({ commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        try {
            const response = await makeRequest('get', 'api_get_contract_items', params )
            const { data } = response

            // This will set merge items & update pagination
            commit('setContractItemsPaginatedData', data)
        } catch(err) {
            throw err
        }
    }
}

const mutations = {
    setContractItemsData: (state, contractItems) => {
        state.contractItems = contractItems
    },
    setContractItemsPaginatedData: (state, contractItems) => {
        const { items, pagination } = contractItems
        state.contractItems = {
            pagination,
            items: [ ...state.contractItems.items, ...items ]
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
