import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import flashMessage from './modules/flashMessage'
import settings from './modules/settings'
import messages from './modules/messages'
import contracts from './modules/contracts'
import contractItems from './modules/contractItems'
import calloffList from './modules/calloffList'
import calloffDetail from './modules/calloffDetail'
import calloffChange from './modules/calloffChange'
import filters from './modules/filters'
import amountItems from './modules/amountItems'
import deliveryItems from './modules/deliveryItems'
import calloffCreation from './modules/calloffCreation'
import help from './modules/help'
import announcement from './modules/announcement'
import configuration from './modules/configuration'
import {makeRequest} from "@/utils/makeRequest";

const disablePersistedStatePlugin = process.env.NODE_ENV === 'development' && process.env.VUE_APP_DISABLE_PERSISTED_STATE_PLUGIN === 'true'

export default createStore({
	state: {
		accessToken: '',
		userLoggedIn: false,
		lastAccessedRoute: {
			help: ['/dashboard'],
			qr: ['/dashboard'],
			messages: ['/dashboard'],
			any: ['/dashboard']
		},
		quasiOverlayStatus: {
			helpIsOpen: false,
			qrIsOpen: false,
			messagesIsOpen: false
		},
        countryCodes: {
            faxCountry: [],
            mobileCountry: [],
            phoneCountry: []
        },
        loadingCalls: []
	},
	getters: {
        getLoadingCalls: (state) => state.loadingCalls
    },
	mutations: {
		userLoggedIn(state) {
			state.userLoggedIn = true
		},
		userLoggedOut(state) {
			state.userLoggedIn = false
		},
		setAccessToken(state, value) {
			state.accessToken = value
		},
		setQuasiOverlayStatus(state, valueObject) {
			state.quasiOverlayStatus = {...state.quasiOverlayStatus, ...valueObject}
		},

		// TODO: probably should have been an action (and also a single array), refactor later if there is time
		setLastAccessedRoute(state, value) {

			// ignore all routes where user isnt logged in
			if (state.userLoggedIn) {

				// TODO: Only needed temporary to run once and change the people's local storage from string to array
				if (!Array.isArray(state.lastAccessedRoute.any)) {
					state.lastAccessedRoute = {
						help: ['/dashboard'],
						qr: ['/dashboard'],
						messages: ['/dashboard'],
						any: ['/dashboard']
					}
				}

				// first remove all existing duplicates of it, then continue the append logic
				Object.keys(state.lastAccessedRoute).map(elem => {
					state.lastAccessedRoute[elem] = state.lastAccessedRoute[elem].filter(elem => elem != value)
				})

				// only append to history if its different than the last accessed route, and only keep up to last 5
				if (state.lastAccessedRoute.any.length >= 5) state.lastAccessedRoute.any.pop()
				if (state.lastAccessedRoute.any[0] != value) state.lastAccessedRoute.any.unshift(value)

				// array of last accessed routes for help
				if (value != '/help') {

					// only keep the last 5 entries in the history array
					if (state.lastAccessedRoute.help.length >= 5) state.lastAccessedRoute.help.pop()

					if (value.includes('messages') && state.quasiOverlayStatus.messagesIsOpen && !state.lastAccessedRoute.help[0].includes('messages')) {
						state.lastAccessedRoute.help.unshift(value)
					} else if (value == '/qr-scanner' && state.quasiOverlayStatus.qrIsOpen && state.lastAccessedRoute.help[0] != '/qr-scanner') {
						state.lastAccessedRoute.help.unshift(value)
					} else if (!value.includes('messages') && value != '/qr-scanner' && state.lastAccessedRoute.help[0] != value) {
						state.lastAccessedRoute.help.unshift(value)
					}
				}

				// array of last accessed routes for qr
				if (value != '/qr-scanner') {

					// only keep the last 5 entries in the history array
					if (state.lastAccessedRoute.qr.length >= 5) state.lastAccessedRoute.qr.pop()

					if (value.includes('messages') && state.quasiOverlayStatus.messagesIsOpen && !state.lastAccessedRoute.qr[0].includes('messages')) {
						state.lastAccessedRoute.qr.unshift(value)
					} else if (value == '/help' && state.quasiOverlayStatus.helpIsOpen && state.lastAccessedRoute.qr[0] != '/help') {
						state.lastAccessedRoute.qr.unshift(value)
					} else if (!value.includes('messages') && value != '/help' && state.lastAccessedRoute.qr[0] != value) {
						state.lastAccessedRoute.qr.unshift(value)
					}
				}

				// array of last accessed routes for messages
				if (!value.includes('messages')) {

					// only keep the last 5 entries in the history array
					if (state.lastAccessedRoute.messages.length >= 5) state.lastAccessedRoute.messages.pop()

					if (value == '/qr-scanner' && state.quasiOverlayStatus.qrIsOpen && state.lastAccessedRoute.messages[0] != '/qr-scanner') {
						state.lastAccessedRoute.messages.unshift(value)
					} else if (value == '/help' && state.quasiOverlayStatus.helpIsOpen  && state.lastAccessedRoute.messages[0] != '/help') {
						state.lastAccessedRoute.messages.unshift(value)
					} else if (value != '/qr-scanner' && value != '/help'  && state.lastAccessedRoute.messages[0] != value) {
						state.lastAccessedRoute.messages.unshift(value)
					}
				}
			}
		},
        setCountyCodes: (state, value) => {
            state.countryCodes = value
        },
        addLoadingCall: (state, value) => {
            state.loadingCalls = [...state.loadingCalls, value]
        },
        removeLoadingCall: (state, value) => {
            state.loadingCalls = state.loadingCalls.filter(call => call !== value)
        },
        removeAllLoadingCalls: (state) => {
            state.loadingCalls = []
        }
    },
	actions: {
		removeTheRouteFromHistory: ({ state }, route) => {
			Object.keys(state.lastAccessedRoute).map(elem => {
				while (state.lastAccessedRoute[elem][0] == route || 
					(route == '/messages' && state.lastAccessedRoute[elem][0].includes('messages'))
				) {
					state.lastAccessedRoute[elem].shift()
				}
			})
		},
        getCountryCodes: async (
            { commit }
        ) => {
            try {
                const response = await makeRequest('get', 'api_phone_country_codes')

                const [faxCountry, mobileCountry, phoneCountry] = ["faxCountry", "mobileCountry", "phoneCountry"].map(country =>
                    Object.keys(response[country]).map((key, i) => ({
                        id: key,
                        text: response[country][key]
                    }))
                )

                commit('setCountyCodes', {faxCountry: faxCountry, mobileCountry: mobileCountry, phoneCountry: phoneCountry})
            } catch(err) {
                throw err
            }
        },
	},
	modules: {
		user,
		flashMessage,
		settings,
		messages,
		contracts,
		contractItems,
        calloffList,
        calloffDetail,
        calloffChange,
		filters,
		amountItems,
		deliveryItems,
		calloffCreation,
		help,
		announcement,
        configuration
	},
	plugins: disablePersistedStatePlugin ? [] : [createPersistedState()]
})