<template>
    <div
        class="flash-message-container"
        :class="[ type,  message ? 'visible' : '' ]"
        v-click-outside="close"
    >
        <img src="@/assets/icons/checkmark.svg" alt="checkmark_icon" v-if="type === 'sucess'" />
        <img src="@/assets/icons/close-red.svg" alt="close_icon" v-if="type === 'error'" />
        <p>{{ message }}</p>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const type = computed(() => store.state.flashMessage.type)
const message = computed(() => store.state.flashMessage.message)

// Custom directive callback
const close = () => {
    store.dispatch('flashMessage/hideFlash')
}

</script>

<style lang="sass">
.flash-message-container
    top: -100vh
    position: fixed

    width: 100%
    min-width: 250px
    max-width: 900px

    background: $white

    padding: 10px

    z-index: 9998
    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px
    box-shadow: 0 3px 6px #01000029

    display: flex
    flex-direction: row

    align-items: center
    justify-content: center

    transition: .5s

    img
        width: 34px
        height: auto
        margin-right: 10px

    p
        margin: 0
        color: $green
        font-size: 14px
        font-family: MontserratBold
        line-height: 19px

        text-align: left

    &.error
        p
            color: $red

    &.visible
        top: 51px

.login-page
    .flash-message-container
        &.visible
            top: 0

.dark-mode
    .flash-message-container
        background-color: $dark-button-gray
</style>