<template>
	<div class="home">
    	<Header :pageTitle="pageTitle" />
    	<SubHeader :showCompanySelect="true"/>

		<Announcement v-if="showAnnouncement"/>

		<Loader v-if="isRequestingContracts" />
		<div class="dashboard-container" v-else>
			<div class="contract-slider-container" v-if="contracts.length">
				<p class="contract-slider-title">{{'on_site.dashboard.contract_slider.title'.trans()}}</p>
				<div class="contract-slider">
					<ContractCard
						v-for="item in contracts"
						:key="`item-${item.id}`"

						:contractId="item.id"
						:contractName="item.name"
						:contractNumber="item.contract_number"
						:constructionSiteNumber="item.building_site_number"
						:customerNumber="item.customer_order_number"
						:startDate="item.contract_date_start"
						:endDate="item.contract_date_end"
						:supplierName="item.vk_org"
						:favorite="item.favorite"
						:todayDelivery="item.today_delivery"
						:todayCallCff="item.today_call_off"
					/>
				</div>
			</div>
			<div class="contract-slider-title empty" v-else>
				{{'on_site.recent_contracts.empty'.trans()}}
			</div>

			<div class="dashboard-buttons-container">
				<router-link
					v-for="button in dashboardButtons"
					:key="button.name"

					class="dashboard-button"
					:to="{ name: button.name }"
				>
					<span class="button-text">{{button.translation.trans()}}</span>
					<span class="button-icon">
						<img :alt="button.name" :src="button.source"/>
						<span class="count"
							:class="{'many-notifications': notificationsCount > 99}"
							v-if="button.name == 'messages' && (notificationsCount > 0)"
						>
							{{ notificationsCount > 99 ? '99+' : notificationsCount }}
						</span>
					</span>
				</router-link>
			</div>
		</div>

    	<Footer/>
	</div>
</template>

<script setup>
	import Header from '@/components/Common/Header.vue'
	import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import Announcement from '@/components/Common/Announcement.vue'
	import Loader from '@/components/Common/Loader.vue'
	import ContractCard from '@/components/Dashboard/ContractCard.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Swal from 'sweetalert2'
	import '@/utils/slick/slick.min.js'
	import { ref, computed, onMounted, onBeforeUnmount, watchEffect, onBeforeMount, watch } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import { makeRequest } from '@/utils/makeRequest'
	import { showErrorPopup } from '@/utils/globalSwalPopups.js'
	import { checkTermsOfServiceChange } from '@/utils/dashboard/termsOfServiceChange.js'
	import { bellIcon } from '@/utils/svgIcons'

	const store = useStore()
	const router = useRouter()

	const pageTitle = 'on_site.page-title.dashboard'.trans()

	const showAnnouncement = computed(() => store.state.announcement.doneRequesting && store.state.announcement.showAnnouncement)

	// Recent contracts loading flag
	const isRequestingContracts = computed(() => store.state.contracts.isContractsRequesting)

	// The request for data was fired in the route guard
	const contracts = computed(() => store.state.contracts.recentContracts)
	const buttonPermissions = computed(() => store.state.user.buttonPermissions)
	const buttonOrder = computed(() => store.state.user.dashboardButtons)

	// The request for notifications is already firing in the footer
	const notificationsCount = computed(() => store.state.messages.count)

	// Create dashboard buttons' necessary data for html
	const dashboardButtons = computed(() => {
		return buttonOrder.value.map(function(buttonName) {
			return {
				'name': buttonName == 'qr-code-scanner' ? 'qr-scanner' : buttonName == 'notifications' ? 'messages' : buttonName,
				'translation': `on_site.dashboard.button.${buttonName}`,
				'source': require(`@/assets/icons/${buttonName}.svg`)
			}
		})
	})

	onMounted(() => {
		// Fetch the global notification/message that appears like a popup on entrance and show it if necessary
		store.dispatch('announcement/fetchAnnouncementData')
		
		// Logic for checking terms of use change
		checkTermsOfServiceChange()

		const installEvent = computed(() => store.state.user.installPromptEvent)

		// If install event exists, trigger it and then remove it
		if (installEvent.value) {
			try {
				// Try to show prompt to user
				installEvent.value.prompt && installEvent.value.prompt()
				store.commit('user/setInstallPromptEvent', null)
			} catch {
				throw err
			}
		}

		// When the isRequestingContracts changes from true to false trigger the initialisation
		// setTimeout is necessary because the watcher triggers while there is still no slider components
		watch(isRequestingContracts, () => {
			setTimeout(() => {
				$('.contract-slider').slick({
					arrows: false,
					dots: true
				})
			})
		})

		// Logic for checking system theme setting and changing the app theme
		const currentTheme = store.state.user.data.theme
		const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)
		const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

		if(currentTheme == 10) {
			if(!(darkModeActivated.value == isDarkMode)) {
				store.commit('settings/setDarkMode', isDarkMode)
			}
		} else if (currentTheme == 20) {
			store.commit('settings/setDarkMode', false)
		} else if(currentTheme == 30) {
			store.commit('settings/setDarkMode', true)
		}
	})

	onBeforeUnmount(() => {
		// Remove slick instance, called before unmount while the html is still reachable
		$('.contract-slider').slick('unslick')
	})
</script>

<style lang="sass" scoped>
.home
	padding-top: 51px + 48px // header + subheader
	padding-bottom: 81px // footer + some extra height
	min-height: calc(100vh - 51px - 48px - 81px)

.contract-slider-container
	margin: 13px auto 0px auto
	padding: 13px 5px 0px 5px
	max-width: 394px

	.contract-slider
		.construction-site-card
			max-width: initial

.contract-slider-title
	margin: 0px
	margin-left: 8px
	text-align: left
	font-family: PoppinsMedium
	font-size: 12px
	letter-spacing: 0.02px
	color: $text-gray

	&.empty
		max-width: 394px
		margin: 26px auto 26px auto
		padding: 0px 13px 0px 13px

.dashboard-buttons-container
	margin: 0px auto 0px auto
	padding: 0px 13px 0px 13px
	max-width: 394px

	.dashboard-button
		width: 100%
		height: 55px
		margin-bottom: 15px
		display: flex
		justify-content: space-between
		align-items: center
		border: none
		border-radius: 3px
		filter: drop-shadow(0px 2px 2px #01000029)
		text-decoration: none
		background-color: $button-gray

		.button-text
			padding-left: 10px
			font-family: MontserratMedium
			font-size: 16px
			color: $text-blue

		.button-icon
			display: flex
			padding-right: 20px
			position: relative
			width: 55px

			.count
				top: -3px
				right: 20px
				position: absolute

				background: $red
				border-radius: 100%

				width: 23px
				height: 23px

				text-align: center

				font-size: 14px
				color: $white
				font-family: 'PoppinsRegular'
				line-height: 23px
				letter-spacing: 0

				&.many-notifications
					font-size: 12px

.dark-mode
	.contract-slider-title
		color: $dark-text-color

	.dashboard-buttons-container
		.dashboard-button
			background-color: $dark-button-gray
			border: 1px solid $component-border

			.button-text
				color: $dark-text-color
</style>