import { makeRequest } from '@/utils/makeRequest'

const state = {
    data: {}
}

const getters = {
    getCalloffData: (state) => state.data
}

const actions = {
    fetchDetailData: async ({ commit, state }, id) => {
        try {
            const response = await makeRequest('get', 'api_calloff_detail', {'id': id})

            commit('setCalloffData', response)
        } catch(err) {
            throw err
        }
    },
    fetchContractItemData: async ({ commit, state }, id) => {
        try {
            const response = await makeRequest('get', 'api_get_contract_item_single', {'contract_item_id': id})
            const { data } = response

            commit('setCalloffData', data)
            
        } catch(err) {
            throw err
        }
    },
    submitFirstStepData: async ({ commit, state }, data) => {
        try {
            await makeRequest('post', 'api_init_call_off_cart', data)
        } catch(err) {
            throw err
        }
    },
    submitSecondStepData: async ({ commit, state }, data) => {
        try {
            await makeRequest('post', 'api_call_off_cart_update_contact_person', data)
            
        } catch(err) {
            throw err
        }
    }
}

const mutations = {
    setCalloffData: (state, data) => {
        state.data = {
            ...state.data,
            ...data
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}