<template>
	<div id="maintenance">
		<div class="maintenance-reload">
			<img @click="checkMaintenance" alt="Reload" src="@/assets/icons/refresh-gray.svg">
		</div>
		<div class="fetched-data-container" v-html="htmlContent">
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const htmlContent = ref('')


async function checkMaintenance() {
	const url = Routing.generate('api_terms_of_use', {}, true)

	try {
		await axios.post(url)
		
		router.push({ name: 'login' })

	} catch(error) {
		if (error.response.status == 503) {
			htmlContent.value = error.response.data.data.html
		}
	}
}
checkMaintenance()
</script>

<style lang="sass">
#maintenance
	position: relative
	padding: 60px 30px 60px 30px
	text-align: left
	color: $text-dark-gray
	max-width: 1024px
	margin: auto

	.maintenance-reload
		position: absolute
		top: 15px
		right: 15px

		img
			padding: 10px

	.fetched-data-container
		h1
			margin-top: 0px

		img
			width: 100% !important
			max-width: 250px

.dark-mode
	#maintenance
		.fetched-data-container
			h1, h2, h3, h4, h5, h6
				color: $dark-text-color

			ul li, p
				color: $white
</style>