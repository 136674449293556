<template>
    <div class="step-card">
        <div class="calloff-data">
            <table class="calloff-data-table">
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.incoterm'.trans()}}:</td>
                    <td class="data">{{incoterm.title}}</td>
                </tr>
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.vehicle'.trans()}}:</td>
                    <td class="data">{{vehicle.title}}</td>
                </tr>
                <tr>
                    <td  class="data-name">{{'on_site.calloff_creation.quantity'.trans()}}:</td>
                    <td class="data">{{quantity}} t</td>
                </tr>
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.quantity_hourly'.trans()}}:</td>
                    <td class="data">{{hourly}} t/h</td>
                </tr>
                <tr>
                    <td class="data-name note">{{'on_site.calloff_creation.note'.trans()}}:</td>
                    <td class="data">{{note || ''}}</td>
                </tr>
            </table>
            <div class="delivery">
                <p class="data-name">{{'on_site.calloff_creation.delivery_start'.trans()}}:</p>
                <p class="data">
                    <span class="date-part">
                        <img alt=" " src="@/assets/icons/calendar-blue.svg"/>
                        <span>{{dateStart}}</span>
                    </span>
                    <span class="date-part">
                        <img alt=" " src="@/assets/icons/clock.svg"/>
                        <span>{{timeStart}}</span>
                    </span>
                </p>
            </div>
            <div class="delivery">
                <p class="data-name">{{'on_site.calloff_creation.delivery_end'.trans()}}:</p>
                <p class="data">
                    <span class="date-part">
                        <img alt=" " src="@/assets/icons/calendar-blue.svg"/>
                        <span>{{dateEnd}}</span>
                    </span>
                    <span class="date-part">
                        <img alt=" " src="@/assets/icons/clock.svg"/>
                        <span>{{timeEnd}}</span>
                    </span>
                </p>
            </div>
        </div>

        <Contact :contact="contactPerson" border />

        <div class="button-container">
            <Button
                green
                full-width
                bold
                :click-event="createCalloff"
            >
                {{'on_site.calloff_creation.confirm_calloff'.trans()}}
            </Button>
            <Button
                gray-blue
                bold
                full-width
                :click-event="goToStepOne"
            >
                {{'on_site.calloff_creation.change_calloff'.trans()}}
            </Button>
        </div>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex'
    import { useRoute, useRouter } from 'vue-router'
    import { makeRequest } from '@/utils/makeRequest'
    import Swal from 'sweetalert2'
    import Contact from "@/components/CalloffCreation/components/Contact.vue";
    import Button from "@/components/Common/Button.vue";

    const store = useStore()
    const { data: calloffCreationData } = store.state.calloffCreation
    const { selectedContract: { contract_id: contractId } } = store.state.contracts
    const route = useRoute()
    const router = useRouter()
    const emit = defineEmits(['changeStep'])

    const cartId = calloffCreationData.id
    const contactPerson = calloffCreationData.contact_person
    const incoterm = calloffCreationData.incoterm
    const vehicle = calloffCreationData.truck_type
    const quantity = calloffCreationData.cart_item_quantity_raw
    const hourly = calloffCreationData.hourly_amount_raw
    const note = calloffCreationData.note
    const nightShift = calloffCreationData.night_shift
    const dateStart = calloffCreationData.date_start
    const dateEnd = calloffCreationData.date_end
    const timeStart = calloffCreationData.date_start_time
    const timeEnd = calloffCreationData.date_end_time
    const material = calloffCreationData.material_number

    const goToStepOne = () => emit('changeStep', 1)

    async function createCalloff() {
        if (!cartId) {
            goToStepOne()

        } else {
            $('.calloff-creation-main-central-content').trigger('disable-lock-calloff')
            $('.calloff-creation-main-central-content').trigger('enable-lock-backlink')

            try {
                await makeRequest('post', 'api_call_off_cart_update_contact_person', {
                    'call_off_cart_item_id': cartId,
                    'contact_person_id': contactPerson.id
                })
                await makeRequest('post', 'api_init_call_off_cart', {
                    'contract_item_id': route.params.id,
                    'incoterm': incoterm.id,
                    'truck_type': vehicle.id,
                    'amount': quantity,
                    'hourly_amount': hourly,
                    'delivery_start_date': dateStart,
                    'delivery_start_time': timeStart,
                    'delivery_end_date': dateEnd,
                    'delivery_end_time': timeEnd,
                    'note': note,
                    'call_off_cart_item_id': cartId,
                    'material': material
                })

                await makeRequest('post', 'api_call_off_cart_create_calloff', {'call_off_cart_item_id': cartId}).then(() => {
                    store.commit('calloffCreation/setCalloffCreationData', {
                        incoterm: {'id': null},
                        truck_type: {'id': null},
                        cart_item_quantity_raw: null,
                        amount_warning_limit_raw: 0,
                        hourly_amount_raw: null,
                        note: null,
                        night_shift: false,
                        date_start_pretty: null,
                        date_end_pretty: null,
                        date_start_time: null,
                        date_end_time: null,
                        contact_person: {'id': null},
                        id: null
                    })
                })

                Swal.fire({
                    icon: 'success',
                    title: 'on_site.calloff_creation.success.title'.trans(),
                    text: 'on_site.calloff_creation.success.text'.trans(),
                    confirmButtonText: 'on_site.calloff_creation.success.button_text'.trans()

                }).then(result => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location = '/contract-items/' + contractId
                    }
                })

            } catch (err) {
                $('.calloff-creation-main-central-content').trigger('enable-lock-calloff')
                $('.calloff-creation-main-central-content').trigger('disable-lock-backlink')
                throw err
            }
        }
    }
</script>

<style lang="sass" scoped>
.step-card
    background-color: $white
    border-radius: 5px
    display: flex
    flex-direction: column
    gap: 15px
    margin-bottom: 17px
    padding: 3px 11px 11px 11px
    text-align: left

    @at-root .dark-mode &
        background-color: $text-black

    .delivery
        padding: 0 4px

    .button-container
        display: flex
        flex-direction: column
        gap: 10px

    .data,
    .data-name
        font-size: 12px
        letter-spacing: 0.02px
        line-height: 26px
        margin: 0


    .data-name
        color: $text-blue
        font-family: PoppinsSemiBold
        padding-right: 7px
        vertical-align: bottom

        @at-root .dark-mode &
            color: $dark-text-color

        &.note
            vertical-align: baseline

    .data
        align-items: center
        color: $text-black
        display: flex
        font-family: PoppinsRegular
        gap: 70px

        @at-root .dark-mode &
            color: $white

        .date-part
            display: flex
            gap: 10px
</style>

<style lang="sass">
.step-card
    .calloff-data-table
        width: 100%

        .data
            .moon-icon-container
                svg
                    height: 23px
                    width: auto
                    vertical-align: middle
                    fill: $white
                    stroke: $button-blue

                &.active
                    svg
                        fill: $button-blue
                        stroke: none

.dark-mode
    .calloff-data-table
        .data
            .moon-icon-container
                svg
                    stroke: $text-black
</style>