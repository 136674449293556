<template>
    <div class="contract-detail-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader :currentPage="currentPage"/>

        <Loader v-if="isRequestingDailyAmounts || isRequestingDeliveries" />
        <div v-else>
            <template v-if="(deliveryItems && deliveryItems.length) || (dailyAmountItems && dailyAmountItems.length)">
                <div class="delivery-slider-container">
                    <p class="delivery-slider-title">{{'on_site.contract_detail.delivery_slider.title'.trans()}}</p>

                    <DeliveryCardSlider v-if="recentDeliveryItems && recentDeliveryItems.length" :items="recentDeliveryItems" />

                    <div class="delivery-slider-empty" v-else>
                        {{'on_site.recent_deliveries.empty'.trans()}}
                    </div>
                </div>

                <div class="daily-amount-slider-container" v-show="dailyAmountItems && dailyAmountItems.length">
                    <p class="daily-amount-slider-title">{{'on_site.contract_detail.daily_amount_slider.title'.trans()}}</p>
                    <AmountCardSlider v-if="dailyAmountItems && dailyAmountItems.length" :items="dailyAmountItems" />
                </div>
            </template>
            <div class="contract-detail-empty-page" v-else>
                <div class="empty-page-title">{{'on_site.contract_detail.empty_page.title'.trans()}}</div>
                <img class="empty-page-image" src="@/assets/icons/truck.svg">
                <div class="empty-page-text">{{'on_site.contract_detail.empty_page.text'.trans()}}</div>
            </div>

            <div class="contract-detail-buttons-container" v-if="user.buttonPermissions.includes('call-off-creation')">
                <router-link :to="{ name: 'contract-items', params: { id: contractId }}" class="dashboard-button">
                    <span class="button-text">{{'on_site.contract_detail.calloff_creation'.trans()}}</span>
                    <span class="button-icon">
                        <img alt="..." src="@/assets/icons/amount-blue.svg"/>
                    </span>
                </router-link>
            </div>
            <div class="contract-detail-buttons-container overview" v-if="user.buttonPermissions.includes('call-off-list')">
                <router-link :to="{ name: 'calloff-list', params: {}}" class="dashboard-button">
                    <span class="button-text">{{'on_site.menu.calloff_list'.trans()}}</span>
                    <span class="button-icon">
                        <img alt="..." src="@/assets/icons/calendar-blue.svg"/>
                    </span>
                </router-link>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import { useStore } from 'vuex'

    import Header from '@/components/Common/Header.vue'
    import SubHeader from '@/components/SubHeader/SubHeader.vue'
    import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'

    import DeliveryCardSlider from '@/components/ContractDetails/DeliveryCardSlider.vue'
    import AmountCardSlider from '@/components/ContractDetails/AmountCardSlider.vue'

    const store = useStore()

    store.dispatch('user/fetchButtonPermissions')

    const {contracts, user, amountItems, deliveryItems} = store.state

    const pageTitle = computed(() => contracts.selectedContract.contract_name)
    const currentPage = 'on_site.menu.contract_detail_dashboard'.trans()
    const contractId = computed(() => contracts.selectedContract.contract_id)

    // Recent items loading flag
    const isRequestingDailyAmounts = computed(() => amountItems.isAmountsRequesting)
    const isRequestingDeliveries = computed(() => deliveryItems.isDeliveryItemsRequesting)

    // The request for data was fired in the route guard
	const dailyAmountItems = computed(() => amountItems.recentDailyAmounts.items)
    const recentDeliveryItems = computed(() => deliveryItems.recentDeliveryItems.items)
</script>

<style lang="sass" scoped>
.contract-detail-page
    padding-top: 51px + 48px // header + subheader
    padding-bottom: 81px // footer + some extra height
    min-height: calc(100vh - 51px - 48px - 81px)

    .contract-detail-empty-page
        margin: 25px 13px 0px 13px
        text-align: center
        letter-spacing: 0.02px
        color: $text-gray

        .empty-page-title
            max-width: 368px
            margin: 0px auto 60px auto
            font-size: 16px
            font-family: PoppinsBold

        .empty-page-text
            max-width: 368px
            margin: 60px auto 100px auto
            font-size: 12px
            font-family: PoppinsMedium
            line-height: 15px

    .contract-detail-buttons-container
        margin: 0px auto 0px auto
        padding: 0px 13px 0px 13px
        max-width: 394px

        .dashboard-button
            width: 100%
            height: 55px
            margin-bottom: 15px
            display: flex
            justify-content: space-between
            align-items: center
            border: none
            border-radius: 3px
            filter: drop-shadow(0px 2px 2px #01000029)
            text-decoration: none
            background-color: $button-gray

            .button-text
                padding-left: 10px
                font-family: MontserratMedium
                font-size: 16px
                color: $text-blue

            .button-icon
                display: flex
                padding-right: 20px
                position: relative
                width: 55px

        &.overview
            .dashboard-button
                .button-icon
                    height: 30px

.delivery-slider-container, .daily-amount-slider-container
    margin: 0 auto
    padding: 13px 5px 0px 5px
    max-width: 394px

    .delivery-slider, .daily-amount-slider
        .construction-site-card, .daily-amount-card
            max-width: initial

    .delivery-slider-empty
        margin: 5px 8px 35px 8px
        padding: 65px 0px
        border-radius: 3px
        filter: drop-shadow(0px 2px 2px #01000029)
        font-family: PoppinsBold
        font-size: 16px
        letter-spacing: .02px
        background-color: $white
        color: $text-gray

.daily-amount-slider-container
    padding-top: 0px

.delivery-slider-title, .daily-amount-slider-title
    margin: 0px
    margin-left: 8px
    text-align: left
    font-family: PoppinsMedium
    font-size: 12px
    letter-spacing: 0.02px
    color: $text-gray

.dark-mode
    .contract-detail-page 
        .contract-detail-buttons-container 
            .dashboard-button
                background-color: $dark-button-gray
                border: 1px solid $border-gray

                .button-text
                    color: $dark-text-color
</style>