<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { imageResize } from '@/utils/imageResize'

const store = useStore()

// This is in "user" store
const userName = computed(() => store.state.user.data && store.state.user.data.name)

// Settings store values
const storeValues = store.state.settings

const userEmail = storeValues.data && storeValues.data.email
const firstRegister = storeValues.data && storeValues.data.first_registration

const userRole = storeValues.data && storeValues.data.role
const userId = storeValues.data && storeValues.data.id

// User image
const defaultImage = require('@/assets/icons/user-big.svg')
const isImageSet = computed(() => storeValues.data && storeValues.data.img_url)
const userImage = computed(() => storeValues.data && storeValues.data.img_url || defaultImage)

// Requesting flag
const isRequesting = computed(() => storeValues.isUserImageRequesting)

const updateImage = async (event) => {
    const input = event.target

    if (input.files.length > 0) {
        const file = input.files[0]

        // Resize & keep aspect-ratio
        const imageBlob = await imageResize(file, {
            maxWidth: 420,
            maxHeight: 420,
        })

        // Convert blob back to file
        const resizedImageFile = new File(
            [ imageBlob ],
            `resized-${file.name}`,
            {
                type: file.type, // Original mime-type
                lastModified: new Date().getTime()
            }
        )

        store.dispatch('settings/updateUserImage', resizedImageFile)
    }
}

const removeImage = () => {
    store.dispatch('settings/updateUserImage', '')
}

</script>

<template>
    <div class="user-info-container">
        <div class="profile-picture-container" :class="{ 'is-Requesting': isRequesting }">
            <div class="image-container">
                <button
                    v-if="isImageSet"
                    @click="removeImage"
                    class="image-remove"
                    title="Remove"
                >
                    <img src="@/assets/icons/close-red.svg">
                </button>
                <img
                    :src="userImage"
                    alt="avatar_image"
                    class="profile-picture-placeholder"
                >
            </div>
            <label class="file-input-container">
                <input
                    type="file"
                    accept="image/*"
                    @input="updateImage"
                />
                <span>{{ 'on_site.profile.change_profile_picture'.trans() }}</span>
            </label>
        </div>
        <div class="profile-info-container">
            <p class="name" v-if="userName">{{ userName }}</p>
            <p class="email" v-if="userEmail">{{ userEmail }}</p>
            <p class="date" v-if="firstRegister">
                {{ 'on_site.profile.first_registration'.trans() }}: {{ firstRegister }}
            </p>

            <table v-if="userRole || userId">
                <tr v-if="userRole">
                    <td>
                        {{ 'on_site.profile.role_label'.trans() }}:
                    </td>
                    <td>
                        {{ userRole }}
                    </td>
                </tr>
                <tr v-if="userId">
                    <td>
                        {{ 'on_site.profile.user_id_label'.trans() }}:
                    </td>
                    <td>
                        {{ userId }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.user-info-container
    display: flex
    flex-direction: row
    margin-bottom: 25px !important

    .profile-picture-container
        margin-right: 25px

        .image-container
            position: relative

            img
                height: 80px
                width: 80px
                object-fit: cover

                border-radius: 100%

            .image-remove
                top: 0
                right: 0
                position: absolute

                border: 0
                outline: 0
                padding: 0
                background: transparent

                opacity: 0.8
                transition: 0.3s opacity

                img
                    width: 25px
                    height: auto

            &:hover, &:focus, &:active
                .image-remove
                    opacity: 1

        .file-input-container
            cursor: pointer
            position: relative

            input
                top: 0
                left: 0
                position: absolute

                opacity: 0
                visibility: hidden

            span
                margin: 0px
                font-family: PoppinsRegular
                font-size: 12px
                letter-spacing: 0.12px
                text-align: center
                color: $button-blue

                display: block

        &.is-Requesting
            opacity: 0.68
            cursor: wait

            button, input, label
                pointer-events: none

    .profile-info-container
        margin-top: 5px

        p
            margin: 0px

        .name
            font-family: PoppinsMedium
            font-size: 16px
            line-height: 25px
            letter-spacing: 0.46px
            color: $text-black

        .email
            font-family: PoppinsRegular
            font-size: 13px
            line-height: 20px
            letter-spacing: 0.08px
            color: $text-black

        .date
            font-family: PoppinsRegular
            font-size: 11px
            line-height: 17px
            color: $text-gray

        table
            margin-top: 14px

            td
                padding: 0
                font-family: PoppinsRegular
                font-size: 13px
                line-height: 20px
                letter-spacing: 0.13px
                color: $text-black

                &:first-child
                    padding-right: 8px

.dark-mode
    .user-info-container
        .profile-picture-container
            .file-input-container
                span
                    color: $dark-text-color

        .profile-info-container
            .name, .email
                color: $white

            .date
                color: $dark-text-color

            table
                td
                    color: $dark-button-text
</style>