const state = {
    type: 'success',
    message: null
}

const getters = {

}

const actions = {
    showFlash: ({ commit, state }, payload) => {
        commit('setData', payload)
    },
    hideFlash: ({ commit }) => {
        commit('setData', {})
    }
}

const mutations = {
    setData: (state, { type ='sucess', message = null }) => {
        state.type = type
        state.message = message
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}