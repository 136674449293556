<template>
    <div class="step-card">
        <Contact :contact="selectedPerson" text="Gewählte Kontaktperson" v-if="selectedPerson"/>
        <Button
            blue
            full-width
            :click-event="toggleLightbox"
        >
            Kontaktperson wählen
        </Button>
        <Lightbox ref="personLightbox" :all-options="contactPersons" title="Kontaktperson wählen">
            <div class="section" v-if="selectedPerson">
                <div class="contact-wrapper">
                    <div class="contact">
                        <Contact :contact="selectedPerson" text="Vorgeschlagene Kontaktperson" />
                        <Button
                            light-blue
                            bold
                        >
                            gewählt
                        </Button>
                    </div>
                </div>
            </div>
            <div class="section">
                <p class="select-title">Kontaktperson ändern</p>
                <div class="contact-person-search-container">
                    <Select2 id="calloff-contact-person-search" class="select-filters-container"
                             :options="contactPersons.map(object => ({
                            'id': object.id,
                            'text': `${object.first_name} ${object.last_name}`
                         }))"
                             :settings="selectSettings"
                             @open="focusInput"
                    />
                </div>
            </div>
            <div class="section flex-1">
                <div class="contact-wrapper multiple">
                    <template v-for="contact in contactPersons" :key="contact.id">
                        <div class="contact" :data-id="contact.id" v-if="contact.id !== selectedPerson.id">
                            <Contact :contact="contact" />
                            <Button
                                blue
                                bold
                                :click-event="() => toggleLightbox(contact.id)"
                            >
                                zuweisen
                            </Button>
                        </div>
                    </template>
                </div>
            </div>
        </Lightbox>

        <div class="button-container">
            <Button
                gray-blue
                bold
                :click-event="() => changeStepAndSubmit(1)"
            >
                {{'on_site.calloff_creation.previous_step'.trans()}}
            </Button>
            <Button
                green
                bold
                :disabled="goToStepThreeButtonDisabled"
                :key="goToStepThreeButtonDisabled"
                :click-event="() => changeStepAndSubmit(3)"
            >
                {{'on_site.calloff_creation.next_step'.trans()}}
            </Button>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onUnmounted} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Lightbox from "@/components/Common/Lightbox.vue";
import Button from "@/components/Common/Button.vue";
import Contact from "@/components/CalloffCreation/components/Contact.vue";
import Select2 from "vue3-select2-component";

const store = useStore()
const { data: calloffCreationData } = store.state.calloffCreation
const route = useRoute()
const emit = defineEmits(['changeStep'])
const personLightbox = ref(null)

const isPlatformIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

const contactPersons = calloffCreationData.available_contact_persons

const selectedPerson = ref(calloffCreationData.contact_person)

const goToStepThreeButtonDisabled = computed(() => !selectedPerson.value)

const selectDropdownCssClass = `contact-person-select-dropdown ${isPlatformIOS && "ios-platform"} ${darkModeActivated.value && "select2-dark-mode"}`

const toggleLightbox = (id) => {
    selectedPerson.value = personLightbox.value.toggleLightbox(id, selectedPerson, 'contact_person')
    filterContactPerson('all')
}

const selectSettings = {
    minimumInputLength: 1,
    placeholder: 'on_site.contact_person.search_filter.placeholder'.trans(),
    containerCssClass: 'contact-person-select-container',
    dropdownCssClass: selectDropdownCssClass,
    //filterWithoutAjax: true,
    language: {
        inputTooShort: () => 'on_site.search.input_too_short'.trans(),
        noResults: () => 'on_site.search.no_results'.trans()
    }
}

// TODO: Uncomment all if screen needs to be filtered, add @searchInput="searchInput" to Select2 component
// function searchInput(event) {
//     console.log(event)
// }

$(document).on('select2:open', '#calloff-contact-person-search', function() {
    focusInput()
    filterContactPerson('all')
})

$(document).on('select2:select', '#calloff-contact-person-search', function() {
    const selectedValue = $(this).val()
    filterContactPerson(selectedValue)
})

// Focus the search input when opening select (search)
function focusInput() {
    $('.select2-search__field')[0].focus()

    // iOS doesnt like focus
    if (isPlatformIOS) {
        $('input::-webkit-textfield-decoration-container').click()
    }
}

function filterContactPerson(value) {
    $('.contact-wrapper.multiple .contact').each(function() {
        const id = $(this).attr('data-id')

        if(value == 'all' || value == id) {
            $(this).show()
        } else {
            $(this).hide()
        }
    })
}

const changeStepAndSubmit = (stepNumber) => {
    emit('changeStep', stepNumber)
}

onUnmounted(() => {
    $('#calloff-contact-person-search').select2('open')
})
</script>

<style lang="sass" scoped>
.step-card
    background-color: $white
    border-radius: 5px
    display: flex
    flex-direction: column
    gap: 30px
    padding: 10px

    > :not(:first-child):before
        border-bottom: 1px solid $border-gray
        content: ""
        position: absolute
        top: -15px
        width: 100%

.button-container
    display: flex
    gap: 40px
    justify-content: space-between
    margin-bottom: 8px
    position: relative

    button.loader
        flex: 1 !important

.dark-mode
    .step-card
        background-color: $text-black

        .data-container
            .contact-person-card
                .contact-person-container
                    .contact-person-data
                        p
                            color: $dark-text-color

.contact-wrapper
    display: flex
    flex-direction: column

    &.multiple
        margin: -15px 0

.contact
    align-items: center
    display: flex
    gap: 8px
    justify-content: space-between

    &:not(:last-child)
        border-bottom: 1px solid $border-gray

.contact-person-select-container
    .select2-selection__rendered
        width: 100% !important
        padding-top: 1px !important
        color: $text-lighter-gray !important

.contact-person-select-dropdown
    background-color: transparent

    &.select2-dropdown--below
        top: -36px
        z-index: 10000

    &.select2-dropdown--above
        top: 37px
        display: flex
        flex-direction: column-reverse

    .select2-search--dropdown
        padding-left: 35px

        .select2-search__field
            font-family: PoppinsRegular
            font-size: 14px
            border: none
            color: $text-lighter-gray
            background-color: $white

        input::-webkit-search-decoration
            display: none

    .select2-results
        background-color: white

        .select2-results__options
            border-bottom-left-radius: 4px
            border-bottom-right-radius: 4px

            .select2-results__option
                padding-top: 10px
                padding-bottom: 10px
                padding-left: 12px
                font-family: PoppinsRegular
                font-size: 14px
                color: $placeholder-gray

                &[aria-selected=true]
                    background-color: $white

                &.select2-results__option--highlighted
                    background-color: $light-blue
                    color: $white

    &.select2-dark-mode
        .select2-search__field,
        .select2-results__message
            background-color: $dark-button-gray !important
            color: $dark-text-color !important
</style>
<style lang="sass">
.contact-person-select-container
    .select2-selection__rendered
        width: 100% !important
        padding-top: 1px !important
        color: $text-lighter-gray !important

.contact-person-select-dropdown
    background-color: transparent

    &.select2-dropdown--below
        top: -36px
        z-index: 10000

    &.select2-dropdown--above
        top: 37px
        display: flex
        flex-direction: column-reverse

    .select2-search--dropdown
        padding-left: 35px

        .select2-search__field
            font-family: PoppinsRegular
            font-size: 14px
            border: none
            color: $text-lighter-gray
            background-color: $white

        input::-webkit-search-decoration
            display: none

    .select2-results
        background-color: white

        .select2-results__options
            border-bottom-left-radius: 4px
            border-bottom-right-radius: 4px

            .select2-results__option
                padding-top: 10px
                padding-bottom: 10px
                padding-left: 12px
                font-family: PoppinsRegular
                font-size: 14px
                color: $placeholder-gray

                &[aria-selected=true]
                    background-color: $white

                &.select2-results__option--highlighted
                    background-color: $light-blue
                    color: $white

    &.select2-dark-mode
        .select2-search__field,
        .select2-results__message
            background-color: $dark-button-gray !important
            color: $dark-text-color !important

    &.ios-platform
        &.select2-dropdown--below
            top: -39px

.contact-person-search-container
    width: 100%

    .select-filter-box
        position: relative

        .select-filter-reset
            position: absolute
            top: 6px
            right: 10px
            cursor: pointer

    .select-filters-container
        text-align: left

        .select2-container
            width: 100% !important

            .select2-selection
                display: flex
                align-items: center
                height: 39px
                width: 100%

                background-image: url("@/assets/icons/search.svg")
                background-repeat: no-repeat
                background-position: 10px 9px

                .select2-selection__rendered
                    margin-left: 30px
                    width: 70%

                .select2-selection__arrow
                    display: none

.dark-mode
    .step-card
        .contact-person-select-container
            background-color: $dark-button-gray
            border: 1px solid $component-border
</style>