import Swal from 'sweetalert2/src/sweetalert2.js'
import store from '@/store/index'

export function showErrorPopup(
    title = '',
    text = 'on_site.global_error_popup.text'.trans(),
    confirmButtonText = 'on_site.global_error_popup.button_text'.trans()
) {
    Swal.fire({
        customClass: {
            container: store.state.settings.dark_mode_activated ? 'swal-popups-dark' : 'swal-popups-light',
        },
        icon: 'error',
        title,
        html: text,
        confirmButtonText
    })
}