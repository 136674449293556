<template>
    <div class="calloff-detail-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader :currentPage="currentPage"/>
        <div v-if="calloffChangeData">
            <div class="calloff-header-container">
                <div class="calloff-button-container">
                    <Button
                        blue
                        medium-text
                        big
                        :to="{ name: 'calloff-list', params: { id : calloffChangeData.contract_id } }"
                    >
                        {{ "on_site.calloff_detail.button.show_calloff_list".trans() }}
                    </Button>
                </div>
                <div class="calloff-status" :data-status-id="calloffChangeData.status">
                    <img v-if="calloffChangeData.status == 0" alt=" " src="../../assets/icons/icon-planned.svg"/>
                    <span v-if="calloffChangeData.status == 0" >{{ 'on_site.calloff_detail.status.planned'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 1" alt=" " src="../../assets/icons/icon-binding-request.svg"/>
                    <span v-if="calloffChangeData.status == 1" >{{ 'on_site.calloff_detail.status.binding_request'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 2" alt=" " src="../../assets/icons/icon-cancellation-request.svg"/>
                    <span v-if="calloffChangeData.status == 2" >{{ 'on_site.calloff_detail.status.cancellation_request'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 3" alt=" " src="../../assets/icons/icon-delivery-refused.svg"/>
                    <span v-if="calloffChangeData.status == 3" >{{ 'on_site.calloff_detail.status.delivery_refused'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 4" alt=" " src="../../assets/icons/icon-delivery-confirmed.svg"/>
                    <span v-if="calloffChangeData.status == 4" >{{ 'on_site.calloff_detail.status.delivery_confirmed'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 5" alt=" " src="../../assets/icons/icon-delivery-cancelled.svg"/>
                    <span v-if="calloffChangeData.status == 5" >{{ 'on_site.calloff_detail.status.delivery_cancelled'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 20" alt=" " src="../../assets/icons/icon-in-review.svg"/>
                    <span v-if="calloffChangeData.status == 20" >{{ 'on_site.calloff_detail.status.in_review'.trans() }}</span>

                    <img v-if="calloffChangeData.status == 30" alt=" " src="../../assets/icons/icon-change-request.svg"/>
                    <span v-if="calloffChangeData.status == 30" >{{ 'on_site.calloff_detail.status.change_request'.trans() }}</span>
                </div>
                <div class="calloff-headline-container">
                    <img src="../../assets/icons/amount-dark-white.svg" v-if="darkModeActivated"/>
                    <img src="../../assets/icons/amount-dark-blue.svg" v-if="!darkModeActivated"/>
                    <h3>{{ ('on_site.' + calloffChangeData.material.group_text).trans() || '—' }}</h3>
                </div>
                <table class="calloff-detail-table">
                    <tr>
                        <td>{{ 'on_site.calloff_detail.material_number'.trans() }}:</td>
                        <td>{{ calloffChangeData.material.number || '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'on_site.calloff_detail.factory_name'.trans() }}:</td>
                        <td>{{ calloffChangeData.factory.name || '—' }}</td>
                    </tr>
                </table>
                <div class="calloff-amounts-table-container">
                    <p class="amounts-title">{{ 'on_site.calloffs.amounts-title'.trans() }}</p>
                    <table class="calloff-table">
                        <tr>
                            <td class="icon-container">
                                <img alt=" " src="../../assets/icons/circle-dark-green-full.svg"/>
                                {{ 'on_site.calloffs.amount'.trans() }}
                            </td>
                            <td >{{ calloffChangeData.target_quantity_display || '—' }}</td>
                        </tr>
                        <tr>
                            <td class="icon-container">
                                <img alt=" " src="../../assets/icons/circle-dark-gray-full.svg"/>
                                {{ 'on_site.calloffs.delivered'.trans() }}
                            </td>
                            <td>{{ calloffChangeData.delivered_quantity_display || '—' }}</td>
                        </tr>
                        <tr>
                            <td class="icon-container open-amount">
                                <img alt=" " src="../../assets/icons/circle-dark-blue-full.svg"/>
                                {{ 'on_site.calloffs.open'.trans() }}
                            </td>
                            <td class="open-amount">{{ calloffChangeData.remaining_quantity_display || '—' }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="form">
                <h5>{{ 'on_site.calloff_change.choose_delivery_conditions'.trans() }}</h5>
                <div class="form-inner">
                    <div class="section">
                        <div class="form-group">
                            <h5>{{ 'on_site.calloff_detail.amount'.trans() }}:</h5>
                            <div class="quantity-container">
                                <input type="number" inputmode="numeric" pattern="[0-9]*"
                                       :placeholder="quantityPlaceholder"
                                       :data-value-to-send="quantityValue"
                                       :data-original-value="quantityValue"
                                       :data-warning-limit="quantityLimit"
                                       :data-unit="quantityUnit"
                                       v-model="quantityPlaceholder"
                                       @keydown="validateInputCharacter"
                                       @blur="validateQuantityInput"
                                >
                                <div :class="{'warning quantity-warning': true, 'hidden': parseFloat(quantityValue) <= parseFloat(quantityLimit)}">
                                    <img src="../../assets/icons/error_warning-red.svg" v-if="darkModeActivated"/>
                                    <img src="../../assets/icons/error_warning-dark-red.svg" v-if="!darkModeActivated"/> {{ 'on_site.calloff_change.quantity_warning'.trans() }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <h5>{{ 'on_site.calloff_detail.delivery_start'.trans() }}:</h5>
                            <div class="date-container" >
                                <div class="flatpickr-container">
                                    <flat-pickr id="date-start-input"
                                                class="date-input date-start-input"
                                                :config="datepickerStartConfig"
                                                :data-value-to-send="deliveryStartDate"
                                                :data-original-value="deliveryStartDate"
                                                v-model="dateStart"
                                                @change="detectChanges"
                                    />
                                </div>
                                <div class="flatpickr-container">
                                    <ClockTimePicker id="time-start-input"
                                                     :options="clockTimePickerStartOptions"
                                                     :data-value-to-send="timeStart"
                                                     inputCssClass="calloff-creation-time-start-input"
                                                     v-model="timeStart"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <h5>{{ 'on_site.calloff_detail.delivery_end'.trans() }}:</h5>
                            <div class="date-container">
                                <div class="flatpickr-container">
                                    <flat-pickr id="date-end-input"
                                                class="date-input date-end-input"
                                                :config="datepickerEndConfig"
                                                :data-value-to-send="deliveryEndDate"
                                                :data-original-value="deliveryEndDate"
                                                v-model="dateEnd"
                                                @change="detectChanges"
                                    />
                                </div>
                                <div class="flatpickr-container">
                                    <ClockTimePicker id="time-end-input"
                                                     :options="clockTimePickerEndOptions"
                                                     :data-value-to-send="timeEnd"
                                                     inputCssClass="calloff-creation-time-end-input"
                                                     v-model="timeEnd"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group columns">
                            <h5>{{ 'on_site.calloff_change.note'.trans() }}</h5>
                            <div class="note-container">
                                <textarea :maxlength="noteMaxLength"
                                          v-model="noteText"
                                          :placeholder="'on_site.calloff_change.note.placeholder'.trans()"
                                          @input="detectChanges">
                                </textarea>
                                <div class="character-counter-container" id="character-counter-container">{{noteText ? noteText.length : '0'}}/{{noteMaxLength}}</div>
                            </div>
                        </div>
                        <div class="form-group contact-person-form-group">
                            <div class="calloff-headline-container">
                                <h3>{{ 'on_site.calloff_change.selected_contact_person'.trans() }}</h3>
                            </div>
                            <Contact edit :contact="selectedPerson" text="" v-if="selectedPerson"/>
                            <Button
                                blue
                                big
                                full-width
                                :click-event="toggleLightbox"
                            >
                                {{ 'on_site.calloff_change.change_contact_person'.trans() }}
                            </Button>
                            <Lightbox ref="personLightbox" :all-options="contactPersons" title="Kontaktperson wählen">
                                <div class="section" v-if="selectedPerson">
                                    <div class="contact-wrapper">
                                        <div class="contact">
                                            <Contact :contact="selectedPerson" text="Vorgeschlagene Kontaktperson" />
                                            <Button
                                                light-blue
                                                bold
                                            >
                                                gewählt
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div class="section">
                                    <p class="select-title">Kontaktperson ändern</p>
                                    <div class="contact-person-search-container">
                                        <Select2 id="calloff-contact-person-search" class="select-filters-container"
                                                 :options="contactPersons.map(object => ({
                                                    'id': object.id,
                                                    'text': `${object.first_name} ${object.last_name}`
                                                 }))"
                                                 :settings="selectSettings"
                                                 @open="focusInput"
                                        />
                                    </div>
                                </div>
                                <div class="section flex-1">
                                    <div class="contact-wrapper multiple">
                                        <template v-for="contact in contactPersons" :key="contact.id">
                                            <div class="contact" :data-id="contact.id" v-if="contact.id !== selectedPerson.id">
                                                <Contact :contact="contact" />
                                                <Button
                                                    blue
                                                    bold
                                                    :click-event="() => toggleLightbox(contact.id)"
                                                >
                                                    zuweisen
                                                </Button>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </Lightbox>
                        </div>
                        <div class="form-group">
                            <div class="calloff-headline-container">
                                <img src="../../assets/icons/icon-pencil-white.svg" v-if="darkModeActivated"/>
                                <img src="../../assets/icons/icon-pencil.svg" v-if="!darkModeActivated"/>
                                <h3>{{ 'on_site.calloff_change.options'.trans() }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="button-container">
                        <Button
                            blue
                            big
                            bold
                            full-width
                            disabled=""
                            :click-event="submitAction"
                        >
                            {{'on_site.calloff_change.submit'.trans()}}
                        </Button>
                        <Button
                            text-gray
                            medium-text
                            big
                            :to="{ name: 'calloff-list', params: { id : calloffChangeData.contract_id } }"
                        >
                            {{'on_site.calloff_change.cancel'.trans()}}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="loader-container" title="Loading...">
                <svg version="1.1" class="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                    <path opacity="0.4" fill="#fff" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                        s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                        c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
                    <path fill="#fff" class="animated-bag-spinner-small" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                        C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(224.883 20 20)">
                    </path>
                </svg>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script setup>
import {computed, ref, onUnmounted, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import { isProxy, toRaw } from 'vue';

import router from '@/router/index'
import Header from '@/components/Common/Header.vue'
import SubHeader from '@/components/SubHeader/SubHeader.vue'
import Footer from '@/components/Common/Footer.vue'
import Button from "@/components/Common/Button.vue";
import Lightbox from "@/components/Common/Lightbox.vue";
import Contact from "@/components/CalloffCreation/components/Contact.vue";
import Select2 from "vue3-select2-component";
import flatPickr from 'vue-flatpickr-component'
import {German} from 'flatpickr/dist/l10n/de'
import ClockTimePicker from "@/components/ClockTimePicker/ClockTimePicker.vue";
import { makeRequest } from '@/utils/makeRequest'
import Swal from "sweetalert2";

const route = useRoute()
const store = useStore()
const id = ref(route.params.id)

const pageTitle = 'on_site.menu.calloff_change'.trans()
const currentPage = 'on_site.menu.calloff_change'.trans()

let initialized = false

const calloffChangeData = ref(null)

const isPlatformIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

const calloffIsBinding = ref(true)
const nightShiftValue = ref(null)

const quantityValue = ref(null)
const quantityUnit = ref(null)
const quantityPlaceholder = ref(null)
const quantityLimit = ref(null)

const dateStart = ref(null)
const deliveryStartDate = ref(null)
const timeStart = ref(null)

const dateEnd = ref(null)
const deliveryEndDate = ref(null)
const timeEnd = ref(null)

const dateStartFormatted = ref(null)
const dateEndFormatted = ref(null)

const noteText = ref('')
const noteMaxLength = computed(() => store.getters['calloffChange/getCalloffData'].max_note_length || 255)

const timeEndMinDate = ref('')

const personLightbox = ref(null)
const contactPersons = ref(null)
const selectedPerson = ref(null)
const initialPerson = ref(null)
const selectDropdownCssClass = `contact-person-select-dropdown ${isPlatformIOS && "ios-platform"} ${darkModeActivated.value && "select2-dark-mode"}`

const selectSettings = {
    minimumInputLength: 1,
    placeholder: 'on_site.contact_person.search_filter.placeholder'.trans(),
    containerCssClass: 'contact-person-select-container',
    dropdownCssClass: selectDropdownCssClass,
    //filterWithoutAjax: true,
    language: {
        inputTooShort: () => 'on_site.search.input_too_short'.trans(),
        noResults: () => 'on_site.search.no_results'.trans()
    }
}

// Global configuration for the datepickers
const datepickerGlobalConfig = {
    locale: German,
    dateFormat: 'D, d.m.y',
    minDate: 'today',
    disableMobile: true,
    onReady: (_, __, fp) => {
        $(fp.calendarContainer).addClass('default-flatpickr-style')

        if (darkModeActivated.value) $(fp.calendarContainer).addClass('dark-mode-style')
    },
    onDayCreate: function(dObj, dStr, fp, dayElem) {
        const dayOfWeek = dayElem.dateObj.getDay()
        const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

        if (isWeekend) {
            dayElem.classList.add('weekendDay')
        }
    }
}

// Configuration for date start datepicker
const datepickerStartConfig = ref({
    ...datepickerGlobalConfig,
    onChange: (date, dateStr, instance) => {
        const sameDayStr = flatpickr.formatDate(flatpickr.parseDate(date[0], 'd.m.y'), 'd.m.y', German)
        const sameDayStrPretty = flatpickr.formatDate(flatpickr.parseDate(date[0], 'D, d.m.y'), 'D, d.m.y', German)

        const nextDay = new Date(date)
        nextDay.setDate(nextDay.getDate() + 1)
        const nextDayStr = flatpickr.formatDate(flatpickr.parseDate(nextDay, 'd.m.y'), 'd.m.y', German)
        const nextDayStrPretty = flatpickr.formatDate(flatpickr.parseDate(nextDay, 'D, d.m.y'), 'D, d.m.y', German)

        if (calloffIsBinding.value && nightShiftValue.value) {
            setTimeout(function () {
                $('#date-end-input').val(nextDayStrPretty)
                $('#date-end-input').attr('data-value-to-send', nextDayStr)

                detectChanges()
            }, 25)
        } else if (calloffIsBinding.value) {
            setTimeout(function () {
                $('#date-end-input').val(sameDayStrPretty)
                $('#date-end-input').attr('data-value-to-send', sameDayStr)

                detectChanges()
            }, 25)
        }

        setTimeout(function () {
            $('#date-start-input').attr('data-value-to-send', sameDayStr)

            detectChanges()
        }, 25)
    }
})

// Configuration for date end datepicker
const datepickerEndConfig = ref({
    ...datepickerGlobalConfig,
    minDate: computed(() => dateStart.value ? dateStart.value : 'today'),
    enable: computed(() => {
        return dateEnd.value ? [dateEnd.value] : []
    }),
    onChange: (date, dateStr, instance) => {
        const sameDayStr = flatpickr.formatDate(flatpickr.parseDate(date[0], 'd.m.y'), 'd.m.y', German)

        $('#date-end-input').attr('data-value-to-send', sameDayStr)

        detectChanges()
    }
})

// Configuration used for both timepickers
const clockTimePickerGlobalOptions = {
    alwaysSelectHoursFirst: true,
    precision: 5,
    dropdownCssClass: `calloff-creation-timepicker-dropdown ${darkModeActivated.value ? 'dark-mode' : ''}`,
    i18n: {
        cancelButton: 'ABBRECHEN',
        okButton: 'OK'
    },
}

const clockTimePickerStartOptions = ref({
    ...clockTimePickerGlobalOptions,
    defaultTime: '07:00',
    onChange: function() {
        if(!nightShiftValue.value) {
            const currentTime = $('#time-start-input').val()
            const endTime = $('#time-end-input').val()

            const currentDate = $('#date-start-input').attr('data-value-to-send')
            const endDate = $('#date-end-input').attr('data-value-to-send')

            if(currentDate === endDate && currentTime > endTime) {
                setTimeout(function () {
                    $('#time-end-input').attr('data-value-to-send', $('#time-start-input').val())
                    $('#time-end-input').val($('#time-start-input').val())
                }, 25)
            }
        }

        $('#time-start-input').attr('data-value-to-send', $('#time-start-input').val())

        detectChanges()
    }
})

// Configuration for time end timepicker
const clockTimePickerEndOptions = ref({
    ...clockTimePickerGlobalOptions,
    minimum: computed(() => timeEndMinDate.value),
    defaultTime: '15:00',
    onChange: function() {
        setTimeout(function () {
            $('#time-end-input').attr('data-value-to-send', $('#time-end-input').val())

            detectChanges()
        }, 25)
    }
})

const toggleLightbox = (id) => {
    selectedPerson.value = personLightbox.value.toggleLightbox(id, selectedPerson, 'contact_person')
    filterContactPerson('all')
    detectChanges()
}

async function fetchChangeData(id) {
    try {
        const response = await makeRequest('get', 'api_calloff_detail', {'id': id})
        calloffChangeData.value = response
        contactPersons.value = response.available_contact_persons
        selectedPerson.value = response.contact_person
        initialPerson.value = response.contact_person

        nightShiftValue.value = response.night_shift

        quantityValue.value = response.daily_tonnage.raw
        quantityUnit.value = response.daily_tonnage.unit
        quantityPlaceholder.value = response.daily_tonnage.value + ' ' + quantityUnit.value
        quantityLimit.value = response.target_quantity.raw

        dateStart.value = response.date_start_pretty
        deliveryStartDate.value = response.delivery_start_date
        timeStart.value = response.delivery_start_time

        setTimeout(function () {
            $('#time-start-input').attr('data-original-value', toRaw(timeStart).value)
        }, 25)

        setTimeout(function () {
        $('#time-end-input').attr('data-original-value', toRaw(timeEnd).value)
        }, 25)

        dateEnd.value = response.date_end_pretty
        deliveryEndDate.value = response.delivery_end_date
        timeEnd.value = response.delivery_end_time

        dateStartFormatted.value = flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y'), 'd.m.Y')
        dateEndFormatted.value = flatpickr.formatDate(flatpickr.parseDate(dateEnd.value, 'D, d.m.y'), 'd.m.Y')

        noteMaxLength.value = response.max_note_length || 255

        timeEndMinDate.value = dateStart.value && timeStart.value ? timeStart.value : ''
    } catch (err) {
        console.error('Error fetching detail data:', err)
    }
}

function filterContactPerson(value) {
    $('.contact-wrapper.multiple .contact').each(function() {
        const id = $(this).attr('data-id')

        if(value == 'all' || value == id) {
            $(this).show()
        } else {
            $(this).hide()
        }
    })
}

function focusInput() {
    $('.select2-search__field')[0].focus()

    // iOS doesnt like focus
    if (isPlatformIOS) {
        $('input::-webkit-textfield-decoration-container').click()
    }
}

$(document).on('select2:open', '#calloff-contact-person-search', function() {
    focusInput()
    filterContactPerson('all')
})

$(document).on('select2:select', '#calloff-contact-person-search', function() {
    const selectedValue = $(this).val()
    filterContactPerson(selectedValue)
})

async function submitAction() {
    const saveData = {
        'call_off_id': calloffChangeData.value.id,
        'amount': $('.quantity-container input').attr('data-value-to-send'),
        'delivery_start_date': $('#date-start-input').attr('data-value-to-send'),
        'delivery_start_time': $('#time-start-input').attr('data-value-to-send'),
        'delivery_end_date': $('#date-end-input').attr('data-value-to-send'),
        'delivery_end_time': $('#time-end-input').attr('data-value-to-send'),
        'customer_note': $('.note-container textarea').val(),
        'contact_person': selectedPerson.value.id
    }

    try {
        const response = await makeRequest('post', 'api_call_off_change_request', saveData)
        showChangeRequestSuccessPopup()
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            showChangeRequestErrorPopup(error.response.data.message)
        }
    }
}

function showChangeRequestSuccessPopup() {
    $('.calloff-detail-page .calloff-header-container').hide()
    $('.calloff-detail-page .form').hide()

    Swal.fire({
        title: 'on_site.calloff_change.success.title'.trans(),
        html: 'on_site.calloff_change.success.text'.trans(),
        confirmButtonText: 'on_site.calloff_change.success.button'.trans(),
        customClass: {
            popup: 'calloff-change-success-popup'
        }
    }).then(function () {
        router.push({name: 'calloff-list', params: { id : calloffChangeData.value.contract_id }})
    })
}

function showChangeRequestErrorPopup(message) {
    $('.calloff-detail-page .calloff-header-container').hide()
    $('.calloff-detail-page .form').hide()

    Swal.fire({
        title: 'on_site.calloff_change.error.title'.trans(),
        html: message.trans(),
        confirmButtonText: 'on_site.calloff_change.error.button'.trans(),
        customClass: {
            popup: 'calloff-abort-popup'
        }
    }).then(result => {
        $('.calloff-detail-page .calloff-header-container').show()
        $('.calloff-detail-page .form').show()
    })
}

onMounted(async() => {
    window.onscroll = () => {}

    $('#calloff-contact-person-search').select2('open')
    fetchChangeData(id.value)
})

onUnmounted(() => {
})

// Forbid the input of decimal signs . and , on quantity inputs, also + and - signs
// Trigger blur on pressing enter
function validateInputCharacter(event) {
    if (event.keyCode === 13 || event.key === 'Enter') {
        $(event.target).trigger('blur')
    }

    const forbiddenCharacterCodes = [107, 108, 109, 110, 187, 188, 189, 190, 229]
    const forbiddenCharacters = [',', '.', '+', '-', 'e', "Unidentified"]

    if (forbiddenCharacterCodes.includes(event.keyCode) || forbiddenCharacters.includes(event.key)) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        event.target.value = event.target.value.replace(/[.]+/g, "")
    }

    detectChanges()
}

function validateQuantityInput() {
    const input = $('.quantity-container input')

    const { warningLimit, unit, originalValue } = input.data()
    const quantityInputValue = parseInt(input.val())

    input.removeClass('empty-value')

    if (quantityInputValue && quantityInputValue >= 1) {
        const formattedValue = quantityInputValue.toLocaleString('de-DE')

        input.attr('placeholder', `${formattedValue} ${unit}`)
        input.attr('data-value-to-send', quantityInputValue)
        input.val('')

        if (quantityInputValue > warningLimit && warningLimit > 0) {
            input.siblings('.quantity-warning').removeClass('hidden')
        } else {
            input.siblings('.quantity-warning').addClass('hidden')
        }
    } else {
        input.val('')
    }

    detectChanges()
}

const initInterval = setInterval(initLoading, 1000)

function initLoading() {
    const loading = $('.calloff-detail-page > .loader-container').length
    const quantityInput = $('.quantity-container input')
    const quantityValue = quantityInput.attr('data-value-to-send')
    const quantityUnit = quantityInput.attr('data-unit')

    if(!loading && quantityValue != undefined && quantityUnit != undefined) {
        clearInterval(initInterval)
        initialized = true
        const submitButton = $('.form-inner .button-container > button:first-child')
        $('.form-inner input').removeClass('changed')
        submitButton.addClass('disabled')

        if(quantityValue && quantityUnit) {
            quantityInput.attr('placeholder', quantityValue + ' ' + quantityUnit)
            validateQuantityInput()
        }
    }
}

function detectChanges() {
    if(!initialized) {
        return
    }

    const input = $('.quantity-container input')
    const quantityWarning = $('.quantity-container .quantity-warning')
    const valueToSend = parseFloat(input.attr('data-value-to-send'))
    const warningLimit = parseFloat(input.attr('data-warning-limit'))
    const submitButton = $('.form-inner .button-container > button:first-child')

    if(valueToSend > warningLimit) {
        quantityWarning.removeClass('hidden')
    } else {
        quantityWarning.addClass('hidden')
    }

    let canSubmit = false
    let hasError = false

    $('.form-inner input').each(function() {
        const originalValue = $(this).attr('data-original-value')
        const currentValue = $(this).attr('data-value-to-send')

        if(originalValue != currentValue) {
            if(currentValue) {
                canSubmit = true
            } else {
                hasError = true
                $(this).addClass('is-empty')
            }

            $(this).addClass('changed')
        } else {
            $(this).removeClass('changed')
        }
    })

    if($('.note-container textarea').val() != '') {
        canSubmit = true
    }

    if(selectedPerson.value && initialPerson.value && selectedPerson.value.id != initialPerson.value.id) {
        canSubmit = true
    }

    if(canSubmit && !hasError) {
        submitButton.removeClass('disabled')
    } else {
        submitButton.addClass('disabled')
    }

    // if(input.hasClass('changed')) {
    //     setTimeout(function () {
    //         $('.quantity-container input').val('')
    //     }, 25)
    // }
}

initLoading()
</script>

<style lang="sass" scoped>
.loader-container
    display: flex
    align-items: center
    justify-content: center

    svg
        width: 120px
        height: 120px

        path
            fill: $text-blue

        .animated-bag-spinner-small
            transform-origin: 20px 20px
            animation: loading-spinner 0.9s linear infinite

    &.small
        svg
            width: 80px
            height: 80px

    @keyframes loading-spinner
        from
            transform: rotate(0deg)

        to
            transform: rotate(360deg)

.calloff-detail-page
    padding: 99px 0 51px
    min-height: calc(100vh - 51px - 48px - 81px)

    .calloff-header-container
        padding: 20px 25px 0

        .calloff-status
            align-items: center
            display: flex

            img
                max-height: 28px
                max-width: 28px
                position: relative

            span
                font-family: PoppinsMedium
                font-size: 15px
                letter-spacing: 0.07px
                margin-left: 10px
                text-transform: uppercase

            &[data-status-id='0']
                color: $button-blue

            &[data-status-id='1']
                color: $green

            &[data-status-id='3']
                color: $dark-red

            &[data-status-id='4']
                color: $green

            &[data-status-id='5']
                color: $dark-red

            &[data-status-id='20']
                color: $dark-blue

                img
                    top: -2px

            &[data-status-id='30']
                color: $orange

                img
                    top: -3px

        .calloff-headline-container
            align-items: center
            display: flex
            margin: 3px 0 0
            position: relative
            width: 100%

            img
                left: 0
                margin: 0 5px
                position: absolute
                top: 8px
                z-index: 3

            h3
                color: $dark-blue
                font-family: PoppinsMedium
                font-size: 15px
                font-weight: normal
                letter-spacing: 0.15px
                line-height: 24px
                margin: 0
                padding: 5px 5px 0 38px
                position: relative
                z-index: 2

        .calloff-detail-table
            tr
                td
                    font-family: PoppinsMedium
                    font-size: 12px
                    letter-spacing: 0.06px
                    line-height: 18px
                    text-align: left
                    vertical-align: top

                    &:first-child
                        padding-right: 10px

                &.night-shift
                    td
                        &:first-child
                            padding-top: 4px

        .calloff-button-container
            margin-bottom: 20px

        .calloff-amounts-table-container
            border-top: 1px solid $button-blue
            margin: 10px 0
            padding: 10px 0

            .amounts-title
                color: $text-gray
                font-family: PoppinsMedium
                font-size: 12px
                letter-spacing: 0.02px
                line-height: 16px
                margin: 0
                padding: 0 3px
                text-align: left

            .calloff-table
                font-family: PoppinsMedium
                font-size: 13px
                letter-spacing: 0.03px
                line-height: 20px

                .icon-container
                    min-width: 160px
                    padding-right: 30px
                    text-align: left

                    img
                        margin-right: 4px

                .open-amount
                    color: $button-blue

                td
                    &:last-child
                        text-align: right

    .form
        background: $dark-blue
        padding: 10px

        > h5
            color: $white
            font-family: PoppinsMedium
            font-size: 12px
            font-weight: normal
            letter-spacing: 0.02px
            line-height: 16px
            margin: 0 0 8px
            padding: 0 15px
            text-align: left

        .form-inner
            background: $white
            border-radius: 4px

        .section
            padding: 0 8px

            .form-group
                display: flex
                margin: 10px 0
                width: 100%

                > h5
                    font-family: PoppinsMedium
                    font-size: 14px
                    font-weight: normal
                    letter-spacing: 0.08px
                    line-height: 21px
                    margin: 0
                    text-align: left
                    width: 160px

                > div
                    text-align: left
                    width: calc(100% - 160px)

                    .flatpickr-container
                        #date-start-input,
                        #date-end-input
                            margin-bottom: 4px

                    input, textarea
                        width: 100%
                        border: 1px solid $text-lighter-gray
                        border-radius: 4px
                        resize: none
                        font-family: PoppinsRegular
                        font-size: 14px
                        letter-spacing: 0.03px
                        color: $text-lighter-gray
                        max-width: 150px

                        /* Hide placeholder on input focus */
                        &:focus::-webkit-input-placeholder
                            color: transparent !important
                        &:focus::-moz-placeholder
                            color: transparent !important
                        &:focus:-moz-placeholder
                            color: transparent !important
                        &:focus::placeholder
                            color: transparent !important

                        /* Change the color of placeholder */
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        &::placeholder
                            color: $text-lighter-gray
                            opacity: 1
                        &:-ms-input-placeholder
                            color: $text-lighter-gray
                        &::-ms-input-placeholder
                            color: $text-lighter-gray

                        &.changed
                            color: $orange

                    input
                        padding: 0 8px

                    textarea
                        color: $text-black
                        padding: 5px 8px
                        max-width: 100%
                        min-height: 112px

                    .character-counter-container
                        color: $text-lighter-gray
                        font-size: 12px
                        text-align: right
                        width: 100%

                    /* Remove arrows from input type number */
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button
                        -webkit-appearance: none
                        margin: 0
                    /* Firefox */
                    input[type=number]
                        -moz-appearance: textfield

                .quantity-container
                    input
                        &.changed
                            color: $text-lighter-gray

                            &::placeholder
                                color: $orange

                    .warning
                        align-items: center
                        color: $dark-red
                        display: flex
                        font-family: PoppinsRegular
                        font-size: 12px
                        gap: 4px
                        margin: 5px 0 0

                        img
                            height: 16px
                            width: 16px

                        &.hidden
                            display: none

                &.columns
                    flex-direction: column

                    > div
                        width: 100%

                &.contact-person-form-group
                    flex-direction: column

                    .lightbox-container
                        width: 100%

                    .calloff-headline-container
                        h3
                            padding-left: 0

        .calloff-headline-container
            align-items: center
            display: flex
            margin: 3px 0 0
            position: relative
            width: 100% !important

            &:after
                border-bottom: 1px solid $light-blue
                bottom: 7px
                content: ''
                height: 1px
                left: 0
                position: absolute
                width: 100%

            img
                left: 0
                margin-right: 4px
                position: absolute
                top: 8px
                z-index: 3

            h3
                background: $white
                color: $dark-blue
                font-family: PoppinsMedium
                font-size: 15px
                font-weight: normal
                letter-spacing: 0.15px
                line-height: 24px
                margin: 0
                padding: 5px 5px 0 30px
                position: relative
                z-index: 2

        .button-container
            display: flex
            flex-direction: column
            gap: 10px
            padding: 10px 8px

.dark-mode
    .loader-container
        path
            fill: $white

    .calloff-detail-page
        color: $white

        .calloff-header-container
            .calloff-headline-container
                h3
                    color: $white

            .calloff-amounts-table-container
                border-color: $white

        .form
            background: none

            .calloff-headline-container
                h3
                    background: $text-black
                    color: $white

                &:after
                    border-color: $white

            .form-inner
                background: none

                .section
                    border-top: 1px solid $white
                    margin: 15px auto
                    padding: 5px 0 0
                    width: calc(100% - 30px)

                    .form-group
                        .quantity-container
                            .warning
                                color: $red

                        > div
                            input,
                            textarea
                                background: $dark-button-gray
                                border: 1px solid rgba(98, 112, 134, 0.5)
                                color: $white

                                &::placeholder
                                    color: $white
                                    opacity: 1

                                &.changed
                                    color: $orange

                                    &::placeholder
                                        color: $orange

</style>
