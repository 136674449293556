<script setup>
const { log } = console
import {computed, onUnmounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import flatPickr from 'vue-flatpickr-component'
import {German} from 'flatpickr/dist/l10n/de'
import moment from 'moment'
import ClockTimePicker from '@/components/ClockTimePicker/ClockTimePicker.vue'
import Lightbox from "@/components/Common/Lightbox.vue";
import Button from "@/components/Common/Button.vue";

const store = useStore()
const calloffCreationData = computed(() => store.getters['calloffCreation/getCalloffData'])
const route = useRoute()
const emit = defineEmits(['changeStep'])
const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

const vehicleLightbox = ref(null)

const toggleLightbox = (id) => {
    selectedVehicle.value = vehicleLightbox.value.toggleLightbox(id, selectedVehicle, 'truck_type')
}

const selectedIncotermId = ref(calloffCreationData.value.incoterm ? calloffCreationData.value.incoterm.id : '')
const selectedVehicle = ref(calloffCreationData.value.truck_type ?? null)

const selectedIncotermName = computed(() => calloffCreationData.value.available_incoterms.filter(x => x.id === selectedIncotermId.value)[0].title)

// We need quantityValueCached to save the quantityValue after the blur because it gets deleted in order to show placeholder
const quantityValue = ref(null)
const quantityValueCached = ref(calloffCreationData.value.cart_item_quantity_raw)
const quantityUnit = ref(calloffCreationData.value.cart_item_quantity_unit)
const quantityLimit = ref(calloffCreationData.value.amount_warning_limit_raw)
const quantityPlaceholder = ref(calloffCreationData.value.cart_item_quantity)
const showQuantityWarning = computed(() => quantityValueCached.value > quantityLimit.value)

const quantityHourlyValue = ref(null)
const quantityHourlyValueCached = ref(calloffCreationData.value.hourly_amount_raw)
const quantityHourlyPlaceholder = ref(calloffCreationData.value.hourly_amount)

// initially it can be null coming from backend, that's why we manually set it to empty here in that case
const noteText = ref(calloffCreationData.value.note || '')
const noteMaxLength = computed(() => store.state.configuration.configurationData.note_max_letters || 255)

const calloffIsBinding = ref(true)

// initially it can be null coming from backend, that's why we manually set it to false here in that case
const nightShiftValue = ref(calloffCreationData.value.night_shift || false)

const dateStart = ref(calloffCreationData.value.date_start_pretty)
const dateEnd = ref(calloffCreationData.value.date_end_pretty)
const timeStart = ref(calloffCreationData.value.date_start_time)
const timeEnd = ref(calloffCreationData.value.date_end_time)
const timeEndMinDate = ref(dateStart.value && timeStart.value ? timeStart.value : '')

const dateStartFormatted = computed(() => flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y'), 'd.m.Y'))
const dateEndFormatted = computed(() => flatpickr.formatDate(flatpickr.parseDate(dateEnd.value, 'D, d.m.y'), 'd.m.Y'))

// if the default (from backend) date start is older than today, empty it so that the datepicker doesn't break (when you open it but don't select anything, so it takes that bad value)
if (dateStart.value) {
    if (moment(dateStartFormatted.value, 'DD.MM.YYYY').add(1, 'days') < moment()) {
        dateStart.value = ''
    }
}

const dateStartNextDay = computed(() => flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y').fp_incr(1), 'D, d.m.y', German))
const isSameDay = computed(() => dateStart.value && (dateStart.value === dateEnd.value))
const wrongHours = computed(() => timeStart.value && timeEnd.value && (moment(timeStart.value, 'HH:mm') > moment(timeEnd.value, 'HH:mm')))

const goToStepTwoButtonDisabled = computed(() => {
    const calloffPlanningValidation = !selectedIncotermId.value || !selectedVehicle.value || !quantityValueCached.value || !dateStart.value || !dateEnd.value

    if (!calloffIsBinding.value) return calloffPlanningValidation
    else return calloffPlanningValidation || !timeStart.value || !timeEnd.value
})

// Global configuration for the datepickers
const datepickerGlobalConfig = {
    locale: German,
    dateFormat: 'D, d.m.y',
    minDate: 'today',
    disableMobile: true,
    onReady: (_, __, fp) => {
        $(fp.calendarContainer).addClass('default-flatpickr-style')

        if (darkModeActivated.value) $(fp.calendarContainer).addClass('dark-mode-style')
    },
    onDayCreate: function(dObj, dStr, fp, dayElem) {
        const dayOfWeek = dayElem.dateObj.getDay()
        const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

        if (isWeekend) {
            dayElem.classList.add('weekendDay')
        }
    }
}


// Configuration for date start datepicker
const datepickerStartConfig = ref({
    ...datepickerGlobalConfig,
    onChange: (date) => {
        if (calloffIsBinding.value && nightShiftValue.value) {
            dateEnd.value = dateStartNextDay.value

        } else if (calloffIsBinding.value) {
            dateEnd.value = flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y'), 'D, d.m.y', German)
        }

        if (isSameDay.value && wrongHours.value) {
            timeEnd.value = timeStart.value
        }
    }
})


// Configuration for date end datepicker
const datepickerEndConfig = ref({
    ...datepickerGlobalConfig,
    minDate: computed(() => dateStart.value ? dateStart.value : 'today'),
    enable: computed(() => {
        if (calloffIsBinding.value && nightShiftValue.value) {
            return dateStart.value ? [dateStart.value, dateStartNextDay.value] : []

        } else if (calloffIsBinding.value) {
            return dateStart.value ? [dateStart.value] : []

        } else {
            return [() => true]
        }
    }),
    onChange: () => {
        if (isSameDay.value) {
            timeEndMinDate.value = timeStart.value

            if (wrongHours.value) {
                timeEnd.value = timeStart.value
            }

        } else {
            timeEndMinDate.value = ''
        }
    }
})


// Configuration used for both timepickers
const clockTimePickerGlobalOptions = {
    alwaysSelectHoursFirst: true,
    precision: 5,
    dropdownCssClass: `calloff-creation-timepicker-dropdown ${darkModeActivated.value ? 'dark-mode' : ''}`,
    i18n: {
        cancelButton: 'ABBRECHEN',
        okButton: 'OK'
    },
}


// Configuration for time start timepicker
const clockTimePickerStartOptions = ref({
    ...clockTimePickerGlobalOptions,
    defaultTime: '07:00',
    onChange: function() {
        if (isSameDay.value && timeStart.value) {
            timeEndMinDate.value = timeStart.value

            if (wrongHours.value) timeEnd.value = timeStart.value

        } else {
            timeEndMinDate.value = ''
        }
    }
})


// Configuration for time end timepicker
const clockTimePickerEndOptions = ref({
    ...clockTimePickerGlobalOptions,
    minimum: computed(() => timeEndMinDate.value),
    defaultTime: '15:00',
    onChange: function() {
        if (isSameDay.value && wrongHours.value) {
            timeEnd.value = timeStart.value
        }
    }
})


// Forbid the input of decimal signs . and , on quantity inputs, also + and - signs
// Trigger blur on pressing enter
function validateInputCharacter(event) {
    if (event.keyCode === 13 || event.key === 'Enter') {
        $(event.target).trigger('blur')
    }

    const forbiddenCharacterCodes = [107, 108, 109, 110, 187, 188, 189, 190, 229]
    const forbiddenCharacters = [',', '.', '+', '-', 'e', "Unidentified"]

    if (forbiddenCharacterCodes.includes(event.keyCode) || forbiddenCharacters.includes(event.key)) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        event.target.value = event.target.value.replace(/[.]+/g, "")
    }
}


// Logic for interactions between quantity and hourly quantity inputs
function validateInputValue() {
    if (quantityValue.value && quantityValue.value >= 1) {

        // update the placeholder, save the value, then delete it in order for placeholder to appear
        quantityPlaceholder.value = `${parseInt(quantityValue.value).toLocaleString('de-DE')} ${quantityUnit.value}`
        quantityValueCached.value = quantityValue.value
        quantityValue.value = null

        if (quantityHourlyValueCached.value > parseInt(quantityValueCached.value)) {
            quantityHourlyValueCached.value = null
            quantityHourlyPlaceholder.value = ''
        }
    } else {
        quantityValue.value = null
    }
}

function validateHourlyInputValue() {
    if (quantityHourlyValue.value && quantityHourlyValue.value >= 1) {

        if (quantityHourlyValue.value > parseInt(quantityValueCached.value)) {
            quantityHourlyValue.value = null

        } else {
            // update the placeholder, save the value, then delete it in order for placeholder to appear
            quantityHourlyPlaceholder.value = `${parseInt(quantityHourlyValue.value).toLocaleString('de-DE')} ${quantityUnit.value}/h`
            quantityHourlyValueCached.value = quantityHourlyValue.value
            quantityHourlyValue.value = null
        }

    } else {
        quantityHourlyValue.value = null
    }
}


// When the night shift value is changed, also set the appropriate end date
function toggleNightShiftDependencies() {
    if (calloffIsBinding.value && dateStart.value) {
        if (nightShiftValue.value) {
            dateEnd.value = dateStartNextDay.value
            timeEndMinDate.value = ''

        } else {
            dateEnd.value = dateStart.value
            timeEndMinDate.value = timeStart.value
        }
    }
}

function setNotesLength(text) {
    const characterCounter = document.querySelector('#character-counter-container')
    characterCounter.innerHTML = text.length + '/' + noteMaxLength.value
}

async function goToStepTwo() {
    // needed because the data in response (saved in store) isn't named the same as in this submit request
    const dataToSaveInStore = {
        'incoterm': {'id': selectedIncotermId.value, 'title': selectedIncotermName.value},
        'cart_item_quantity_raw': quantityValueCached.value,
        'cart_item_quantity': quantityPlaceholder.value,
        'hourly_amount_raw': quantityHourlyValueCached.value,
        'hourly_amount': quantityHourlyPlaceholder.value,
        'date_start': dateStartFormatted.value,
        'date_start_pretty': dateStart.value,
        'date_start_time': timeStart.value,
        'date_end': dateEndFormatted.value,
        'date_end_pretty': dateEnd.value,
        'date_end_time': timeEnd.value,
        'note': noteText.value,
        'night_shift': nightShiftValue.value
    }
    quantityPlaceholder.value = null
    quantityHourlyPlaceholder.value = null

    store.commit('calloffCreation/setCalloffCreationData', dataToSaveInStore)

    emit('changeStep', 2)
}

onUnmounted(() => {
    quantityPlaceholder.value = null
    quantityHourlyPlaceholder.value = null
})
</script>

<template v-if="calloffCreationData.available_incoterms.length > 0 && calloffCreationData.truck_type_options.length > 0">
    <div class="step-card">
        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.incoterm'.trans()}}</p>
            <div class="button-wrapper">
                <div class="button-container" v-for="incoterm in calloffCreationData.available_incoterms" :key="incoterm.id">
                    <input type="radio" :id="incoterm.id" name="incoterm" :value="incoterm.id" v-model="selectedIncotermId">
                    <label :for="incoterm.id">{{incoterm.title}}</label>
                </div>
            </div>
        </div>

        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.vehicle'.trans()}}</p>
            <div class="button-wrapper">
                <Button
                    light-blue
                >
                    <span style="display: none">{{ selectedVehicle = calloffCreationData.truck_type }}</span>
                    {{selectedVehicle.title || calloffCreationData.truck_type.title}}
                </Button>
                <Button
                    blue
                    :click-event="toggleLightbox"
                >
                    Fahrzeug ändern
                </Button>
            </div>
            <Lightbox ref="vehicleLightbox" :all-options="calloffCreationData.truck_type_options" title="Fahrzeugart wählen">
                <div class="section">
                    <p>Vorgeschlagenes Fahrzeug</p>
                    <div class="button-wrapper">
                        <Button
                            light-blue
                        >
                            <span style="display: none">{{ selectedVehicle = calloffCreationData.truck_type }}</span>
                            {{selectedVehicle.title || calloffCreationData.truck_type.title}}
                        </Button>
                    </div>
                </div>
                <div class="section">
                    <p>Optionale Fahrzeuge</p>
                    <div class="button-wrapper">
                        <template v-for="vehicle in calloffCreationData.truck_type_options" :key="vehicle.id">
                            <Button
                                white
                                v-if="vehicle.id !== selectedVehicle.id"
                                :click-event="() => toggleLightbox(vehicle.id)"
                            >
                                {{vehicle.title}}
                            </Button>
                        </template>
                    </div>
                </div>
            </Lightbox>
        </div>

        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.quantity'.trans()}} ({{quantityUnit}})</p>
            <div class="quantity-container">
                <input type="number" inputmode="numeric" pattern="[0-9]*" :placeholder="quantityPlaceholder" v-model="quantityValue"
                    @keydown="validateInputCharacter"
                    @blur="validateInputValue"
                >
            </div>
            <div class="quantity-warning" v-if="showQuantityWarning">
                <img src="@/assets/icons/error_warning.svg" alt="warning">
                {{'on_site.calloff_creation.quantity_warning'.trans()}}
            </div>
        </div>

        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.quantity_hourly'.trans()}} ({{quantityUnit}}/h)</p>
            <div class="quantity-container hourly">
                <input type="number" inputmode="numeric" pattern="[0-9]*" :placeholder="quantityHourlyPlaceholder" v-model="quantityHourlyValue"
                    @keydown="validateInputCharacter"
                    @blur="validateHourlyInputValue"
                >
            </div>
        </div>

        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.note'.trans()}}</p>
            <div class="note-container">
                <textarea :maxlength="noteMaxLength" v-model="noteText" :placeholder="'on_site.calloff_creation.note.placeholder'.trans()" @input="setNotesLength($event.target.value)"></textarea>
                <div class="character-counter-container" id="character-counter-container">{{noteText ? noteText.length : '0'}}/{{noteMaxLength}}</div>
            </div>
        </div>

<!--        <p class="input-name">{{'on_site.calloff_creation.night_shift'.trans()}}</p>
        <div class="night-shift-container" :class="{'night-shift-active': nightShiftValue}">
            <input type="checkbox" id="night-shift" v-model="nightShiftValue" @change="toggleNightShiftDependencies">
            <label for="night-shift" v-html="moonIcon"></label>
        </div>-->

        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.delivery_start'.trans()}}</p>
            <div class="delivery-container">
                <div class="flatpickr-container">
                    <label for="date-start-input">
                        <img src="@/assets/icons/calendar-blue.svg" alt="calendar">
                    </label>
                    <flat-pickr id="date-start-input" class="date-input date-start-input" :config="datepickerStartConfig" v-model="dateStart"/>
                </div>
                <div class="flatpickr-container">
                    <label for="time-start-input">
                        <img src="@/assets/icons/clock.svg" alt="clock">
                    </label>
                    <ClockTimePicker id="time-start-input" :options="clockTimePickerStartOptions" inputCssClass="calloff-creation-time-start-input" v-model="timeStart"/>
                </div>
            </div>
        </div>

        <div class="info-block">
            <p class="input-name">{{'on_site.calloff_creation.delivery_end'.trans()}}</p>
            <div class="delivery-container">
                <div class="flatpickr-container">
                    <label for="date-end-input">
                        <img src="@/assets/icons/calendar-blue.svg" alt="calendar">
                    </label>
                    <flat-pickr id="date-end-input" class="date-input date-end-input" :config="datepickerEndConfig" v-model="dateEnd"/>
                </div>
                <div class="flatpickr-container">
                    <label for="time-end-input">
                        <img src="@/assets/icons/clock.svg" alt="clock">
                    </label>
                    <ClockTimePicker id="time-end-input" :options="clockTimePickerEndOptions" inputCssClass="calloff-creation-time-end-input" v-model="timeEnd"/>
                </div>
            </div>
        </div>

        <Button green bold full-width :disabled="goToStepTwoButtonDisabled" :key="goToStepTwoButtonDisabled" :click-event="goToStepTwo" >
            {{'on_site.calloff_creation.next_step'.trans()}}
        </Button>
    </div>
</template>

<style lang="sass" scoped>
.step-card
    border-radius: 5px
    background-color: $white
    display: flex
    flex-direction: column
    gap: 20px
    padding: 15px 10px 50px 10px

.info-block
    display: flex
    flex-direction: column
    gap: 8px

    .input-name
        margin: 0
        font-family: PoppinsSemiBold
        font-size: 12px
        letter-spacing: 0.02px
        text-align: left
        color: $text-blue

    .button-wrapper
        display: flex
        flex-wrap: wrap
        gap: 8px

        input
            position: absolute
            opacity: 0
            visibility: hidden

            &:checked
                + label
                    color: $white
                    background-color: $button-blue
                    border-color: $button-blue

        label,
        button
            background-color: $white
            border: 1px solid rgba(98, 112, 134, 0.5)
            border-radius: 5px
            color: $text-lighter-gray
            display: inline-block
            font-size: 13px
            letter-spacing: 0.03px
            line-height: 19px
            padding: 9px 12px

    .quantity-container,
    .note-container
        text-align: left

        input, textarea
            width: 100%
            padding: 11px 12px
            border: 1px solid rgba(98, 112, 134, 0.5)
            border-radius: 5px
            resize: none
            font-family: PoppinsRegular
            font-size: 14px
            letter-spacing: 0.03px
            color: $text-black

            /* Hide placeholder on input focus */
            &:focus::-webkit-input-placeholder
                color: transparent !important
            &:focus::-moz-placeholder
                color: transparent !important
            &:focus:-moz-placeholder
                color: transparent !important
            &:focus::placeholder
                color: transparent !important
            
            /* Change the color of placeholder */
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            &::placeholder
                color: $text-black
                opacity: 1
            &:-ms-input-placeholder
                color: $text-black
            &::-ms-input-placeholder
                color: $text-black

        /* Remove arrows from input type number */
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button
            -webkit-appearance: none
            margin: 0
        /* Firefox */
        input[type=number]
            -moz-appearance: textfield

    .note-container
        position: relative

        textarea
            min-height: 110px
            margin-bottom: 13px

        .character-counter-container
            position: absolute
            bottom: -10px
            right: 10px
            font-size: 14px
            letter-spacing: 0.03px
            color: $text-lighter-gray

    .night-shift-container
        text-align: left
        margin-bottom: 9px

        input
            position: absolute
            opacity: 0
            visibility: hidden

    .delivery-container
        display: flex

        .flatpickr-container
            display: flex
            align-items: center

            label
                cursor: pointer

                img
                    height: 18px
                    margin-bottom: -3px
                    margin-left: 2px
                    margin-right: 10px
                    width: auto

            input
                width: 100%
                border: none
                font-family: PoppinsRegular
                font-size: 14px
                letter-spacing: 0.03px
                color: $text-black

    .quantity-warning
        display: flex
        font-size: 12px

        img
            margin-right: 5px
            width: 13px
            height: auto

    .next-button
        width: 100%
        padding-top: 13px
        padding-bottom: 13px
        margin-top: 10px
        border: none
        border-radius: 3px
        font-family: PoppinsMedium
        font-size: 16px
        color: $white
        background-color: $green

        &.disabled
            background-color: $text-gray
            pointer-events: none

.dark-mode
    .step-card
        background-color: $text-black

        .input-name
            color: $dark-text-color

        .button-wrapper
            label
                background-color: $dark-button-gray
                color: $white

        .quantity-container,
        .note-container
            input, textarea
                background-color: $dark-button-gray
                color: $white

                /* Change the color of placeholder */
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                &::placeholder
                    color: $white
                &:-ms-input-placeholder
                    color: $white
                &::-ms-input-placeholder
                    color: $white

            .character-counter-container
                color: $white

        .delivery-container
            input
                background-color: $text-black
                color: $white

        .quantity-warning
            color: $dark-text-color
</style>

<style lang="sass">
.calloff-creation-main-central-content
    .night-shift-container
        label
            svg
                cursor: pointer

                .b
                    fill: $white
                    stroke: $button-blue

                // enable hover only on devices that support it
                // because on touch devices that dont support hover, the hover effect persists after touch
                @media (hover: hover)
                    &:hover
                        .b
                            fill: $button-blue
                            transition: all 0.2s ease-in-out

        &.night-shift-active
            label
                svg
                    .b
                        fill: $button-blue

                    @media (hover: hover)
                        &:hover
                            .b
                                fill: $green
                                transition: all 0.2s ease-in-out

.dark-mode
    .calloff-creation-main-central-content
        .night-shift-container
            svg
                .b
                    stroke: $text-black
</style>