<template>
    <input type="text" class="clock-time-picker" :class="inputCssClass" :value="modelValue" @change="emitChange">
</template>

<script setup>
    import { onMounted, onUnmounted, watchEffect } from 'vue'
    import './clockTimePicker'

    const props = defineProps({
        options: Object,
        inputCssClass: {
            type: String,
            required: true
        },
        modelValue: String
    })

    const emit = defineEmits(['update:modelValue'])

    const inputCssClassFormatted = props.inputCssClass.replaceAll(' ', '.')

    function emitChange() {
        emit('update:modelValue', event.target.value)
    }

    onMounted(() => {

        // had to use watchEffect to force component rerender on props change
        watchEffect(() => {
            const timepickerContainer = $(`.${inputCssClassFormatted}`).closest('.clock-timepicker')

            // If it already exists, destroy it before initialising
            if (timepickerContainer.find('.clock-timepicker-popup').length > 0) {
                $(`.${inputCssClassFormatted}`).clockTimePicker('dispose')
            }
            
            $(`.${inputCssClassFormatted}`).clockTimePicker(props.options)
        })
    })

    onUnmounted(() => {
        $(`.${inputCssClassFormatted}`).clockTimePicker('dispose')
    })
</script>

<style lang="sass">
.clock-time-picker
    cursor: pointer

    &.calloff-creation-time-start-input,
    &.calloff-creation-time-end-input
        margin-bottom: 2px

.clock-timepicker-popup
    &.dark-mode
        background-color: $text-black !important

        a
            color: $white !important
</style>