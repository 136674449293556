<template>
	<div class="main-header">
		<span class="hamburger is-Active" @click="handleClick">
			<span class="line"></span>
			<span class="line"></span>
			<span class="line"></span>
		</span>
		<span class="title">{{ pageTitle }}</span>
	</div>
</template>

<script setup>
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import { nextTick } from 'vue'

	const store = useStore()
	const router = useRouter()

	const props = defineProps({
		pageTitle: String,
		qrPage: {
			type: Boolean,
			default: false
		},
		messagesPage: {
			type: Boolean,
			default: false
		}
	})

	$('html').removeClass('no-scroll')
	$('html').removeClass('no-pull-to-refresh')

	const handleClick = () => {
		let lastAccessedRoute = store.state.lastAccessedRoute.any[0]

		if (props.qrPage) {
			store.commit('setQuasiOverlayStatus', {'qrIsOpen': false})
			store.dispatch('removeTheRouteFromHistory', '/qr-scanner')
			lastAccessedRoute = store.state.lastAccessedRoute.qr[0]

		} else if (props.messagesPage) {
			store.commit('setQuasiOverlayStatus', {'messagesIsOpen': false})
			store.dispatch('removeTheRouteFromHistory', '/messages')
			lastAccessedRoute = store.state.lastAccessedRoute.messages[0]

		} else {
			store.commit('setQuasiOverlayStatus', {'helpIsOpen': false})
			store.dispatch('removeTheRouteFromHistory', '/help')
			lastAccessedRoute = store.state.lastAccessedRoute.help[0]
		}
		
		nextTick(() => router.push(lastAccessedRoute))
	}
</script>

<style lang="sass" scoped>
.main-header
	display: flex
	align-items: center

	text-align: left
	background-color: #063973
	box-shadow: 0 3px 6px #01000029

	z-index: 9999

	top: 0
	position: fixed

	height: 51px
	width: 100%
	min-width: 250px
	max-width: 900px

	.hamburger
		cursor: pointer !important
		border: 0 !important
		outline: 0 !important
		background: transparent !important

		padding-left: 24px

		outline: none
		-webkit-tap-highlight-color: rgba(0,0,0,0)

		.line
			width: 18px
			height: 3px

			border-radius: 8px

			background-color: $white
			display: block
			margin: 3px auto

			transition: all 0.3s ease-in-out

		&.is-Active
			.line
				&:nth-child(2)
					opacity: 0

				&:nth-child(1)
					transform: translateY(6px) rotate(45deg)

				&:nth-child(3)
					transform: translateY(-6px) rotate(-45deg)

	.title
		color: $white
		font-family: "MontserratSemiBold"
		margin-left: 18px
		font-size: 12px
		line-height: 15px
</style>
