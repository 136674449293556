<template>
    <div class="calloff-card">
        <div class="calloff-date">
            <img alt="..." src="@/assets/icons/calendar-white.svg"/>
            <span>{{ item.delivery_date_pretty || '—' }}</span>
        </div>
        <div class="calloff-header">
            <div class="calloff-status" :data-status-id="item.status">
                <img v-if="item.status == 0" alt=" " src="../../assets/icons/icon-planned.svg"/>
                <span v-if="item.status == 0" >{{ "on_site.calloff_detail.status.planned".trans() }}</span>

                <img v-if="item.status == 1" alt=" " src="../../assets/icons/icon-binding-request.svg"/>
                <span v-if="item.status == 1" >{{ "on_site.calloff_detail.status.binding_request".trans() }}</span>

                <img v-if="item.status == 2" alt=" " src="../../assets/icons/icon-cancellation-request.svg"/>
                <span v-if="item.status == 2" >{{ "on_site.calloff_detail.status.cancellation_request".trans() }}</span>

                <img v-if="item.status == 3" alt=" " src="../../assets/icons/icon-delivery-refused.svg"/>
                <span v-if="item.status == 3" >{{ "on_site.calloff_detail.status.delivery_refused".trans() }}</span>

                <img v-if="item.status == 4" alt=" " src="../../assets/icons/icon-delivery-confirmed.svg"/>
                <span v-if="item.status == 4" >{{ "on_site.calloff_detail.status.delivery_confirmed".trans() }}</span>

                <img v-if="item.status == 5" alt=" " src="../../assets/icons/icon-delivery-cancelled.svg"/>
                <span v-if="item.status == 5" >{{ "on_site.calloff_detail.status.delivery_cancelled".trans() }}</span>

                <img v-if="item.status == 20" alt=" " src="../../assets/icons/icon-in-review.svg"/>
                <span v-if="item.status == 20" >{{ "on_site.calloff_detail.status.in_review".trans() }}</span>

                <img v-if="item.status == 30" alt=" " src="../../assets/icons/icon-change-request.svg"/>
                <span v-if="item.status == 30" >{{ "on_site.calloff_detail.status.change_request".trans() }}</span>
            </div>
        </div>
        <div class="calloff-headline-container">
            <img src="../../assets/icons/amount-dark-white.svg" v-if="darkModeActivated"/>
            <img src="../../assets/icons/amount-dark-blue.svg" v-if="!darkModeActivated"/>
            <h3>{{ ('on_site.' + item.material.hierachy_title).trans() || '—' }}</h3>
        </div>
        <div class="calloff-table-container">
            <table class="calloff-material-table" v-if="item.id">
                <tr>
                    <td>{{ "on_site.calloff_detail.amount".trans() }}:</td>
                    <td>{{ item.daily_tonnage.value ? (item.daily_tonnage.value + ' ' + item.daily_tonnage.unit) : '-' }}</td>
                </tr>
                <tr>
                    <td>{{ "on_site.calloff_detail.material".trans() }}:</td>
                    <td>{{ item.material.designation || '—' }}</td>
                </tr>
                <tr>
                    <td>{{ "on_site.calloff_detail.delivery_start_date".trans() }}:</td>
                    <td>{{ item.delivery_date + ' - ' + item.delivery_start_time || '—' }}</td>
                </tr>
                <tr>
                    <td>{{ "on_site.calloff_detail.delivery_end_date".trans() }}:</td>
                    <td>{{ item.delivery_date + ' - ' + item.delivery_end_time || '—' }}</td>
                </tr>
            </table>
        </div>
        <div class="calloff-headline-container">
            <img src="../../assets/icons/icon-pencil-white.svg" v-if="darkModeActivated"/>
            <img src="../../assets/icons/icon-pencil.svg" v-if="!darkModeActivated"/>
            <h3>{{ "on_site.calloff_detail.options".trans() }}</h3>
        </div>
        <div class="calloff-button-container">
            <Button
                v-if="!darkModeActivated"
                gray2
                medium-text
                big
                :to="{ name: 'calloff-detail', params: { 'id': item.id } }"
            >
                {{ "on_site.calloff_detail.button.show_detail".trans() }}
            </Button>
            <Button
                v-if="darkModeActivated"
                blue
                medium-text
                big
                :to="{ name: 'calloff-detail', params: { 'id': item.id } }"
            >
                {{ "on_site.calloff_detail.button.show_detail".trans() }}
            </Button>
            <Button
                v-if="item.can_request_change == true && item.status == '4' && !darkModeActivated"
                gray2
                medium-text
                big
                :to="{ name: 'calloff-change', params: { 'id': item.id } }"
            >
                {{ "on_site.calloff_detail.button.change_request".trans() }}
            </Button>
            <Button
                v-if="item.can_request_change == true && item.status == '4' && darkModeActivated"
                blue
                medium-text
                big
                :to="{ name: 'calloff-change', params: { 'id': item.id } }"
            >
                {{ "on_site.calloff_detail.button.change_request".trans() }}
            </Button>
        </div>
    </div>
</template>

<script setup>
import {useStore} from 'vuex'
import Button from "@/components/Common/Button.vue";
import {computed} from "vue";

const store = useStore()
const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

const {item} = defineProps({
    item: Object
})
</script>

<style lang="sass" scoped>
.calloff-creation-card
    filter: none !important
    background-color: $background-gray !important

.calloff-card
    background-color: $white
    border: 1px solid $button-blue
    border-radius: 3px
    border-top-left-radius: 0
    box-sizing: border-box
    filter: drop-shadow(0px 3px 3px #01000029)
    margin: 28px 0 0
    padding: 8px 10px 10px 10px
    text-align: left

    &.cancelled
        background-color: #eed8d9

    .calloff-date
        align-items: center
        background: $button-blue
        border-top-left-radius: 4px
        border-top-right-radius: 4px
        color: $white
        display: flex
        font-family: PoppinsRegular
        font-size: 14px
        height: 28px
        left: -1px
        letter-spacing: 0
        line-height: 24px
        min-width: 200px
        padding: 2px 10px
        position: absolute
        top: -28px

        img
            height: 18px
            margin-right: 12px

    .calloff-header
        .calloff-status
            align-items: center
            display: flex

            img
                max-height: 28px
                max-width: 28px
                position: relative

            span
                font-family: PoppinsMedium
                font-size: 15px
                letter-spacing: 0.07px
                margin-left: 10px
                text-transform: uppercase

            &[data-status-id='0']
                color: $button-blue

            &[data-status-id='1']
                color: $green

            &[data-status-id='3']
                color: $dark-red

            &[data-status-id='4']
                color: $green

            &[data-status-id='5']
                color: $dark-red

            &[data-status-id='20']
                color: $dark-blue

                img
                    top: -2px

            &[data-status-id='30']
                color: $orange

                img
                    top: -3px

        .calloff-material
            clear: both
            .title, .name
                margin-left: 10px
                font-family: PoppinsSemiBold
                font-size: 15px
                letter-spacing: 0.07px
                line-height: 23px
                color: $text-lighter-gray

    .calloff-headline-container
        align-items: center
        display: flex
        margin: 3px 0 0
        position: relative

        &:after
            border-bottom: 1px solid $light-blue
            bottom: 7px
            content: ''
            height: 1px
            left: 0
            position: absolute
            width: 100%

        img
            left: 0
            margin-right: 4px
            position: absolute
            top: 8px
            z-index: 3

        h3
            background: $white
            color: $dark-blue
            font-family: PoppinsMedium
            font-size: 15px
            font-weight: normal
            letter-spacing: 0.15px
            line-height: 24px
            margin: 0
            padding: 5px 5px 0 30px
            position: relative
            z-index: 2

    .calloff-table-container
        margin: 0 0 5px
        padding: 0 0 0 30px

        .calloff-material-table
            tr
                td
                    font-family: PoppinsMedium
                    font-size: 12px
                    letter-spacing: 0.03px
                    line-height: 24px
                    padding: 0
                    vertical-align: top

                    &:first-child
                        min-width: 86px
                        padding-right: 10px

    .calloff-button-container
        align-items: flex-end
        display: flex
        gap: 10px
        flex-direction: column
        margin: 10px 0 0

        a
            align-items: center
            background: $button-background-gray !important
            border-radius: 4px
            color: $text-lighter-gray !important
            display: flex
            font-family: PoppinsMedium
            font-size: 16px
            font-weight: normal
            justify-content: center
            letter-spacing: 0.03px
            line-height: 16px
            padding: 17px 10px
            text-decoration: none
            transition: 0.3s
            width: 100%

            &:hover
                background: $dark-blue
                color: $white

.dark-mode
    .calloff-creation-card
        background-color: $text-black !important
        border: none !important

    .calloff-card
        background-color: $dark-button-gray
        border: 1px solid $component-border
        color: $white

        .calloff-date
            background: $text-blue
            border: 1px solid $component-border
            border-bottom: none

        .calloff-header
            .calloff-material
                .title, .name
                    color: $gray-blue

        .calloff-headline-container
            &:after
                border-color: $white

            h3
                background: $dark-button-gray
                color: $white

</style>