<template>
	<div id="terms-of-use">
		<router-link :to="{ name: backRoute, params: backRouteParams }">
            <img class="terms-page-close" alt="Back" src="@/assets/icons/close-gray.svg">
        </router-link>
		<div class="fetched-data-container" v-html="htmlContent">
		</div>
		<div class="close-button-container">
			<router-link :to="{ name: backRoute, params: backRouteParams }" v-if="htmlContent">
				<button class="light-blue">{{'on_site.tos.return'.trans()}}</button>
			</router-link>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { makeRequest } from '@/utils/makeRequest.js'
import { showErrorPopup } from '@/utils/globalSwalPopups.js'

const route = useRoute()
const htmlContent = ref('')
const backRoute = ref('register')
const backRouteParams = ref(route.params)

async function getPageContent() {
	try {
		const data = await makeRequest('post', 'api_terms_of_use', {}, false)
		htmlContent.value = data.data.html

		// if terms of use has changed, the user was redirected here from dashboard
		if (route.params.changed == 'true') {
			backRoute.value = 'dashboard'
			backRouteParams.value = {}
		}

	} catch {
		showErrorPopup()
	}
}
getPageContent()
</script>

<style lang="sass">
#terms-of-use
	position: relative
	padding: 60px 30px 60px 30px
	text-align: left
	color: $text-dark-gray

	.terms-page-close
		position: absolute
		top: 15px
		right: 15px

	.fetched-data-container
		h1
			margin: 0px
			font-size: 24px

		h2
			font-size: 22px

		ul
			margin-top: 0px

			li
				font-size: 13px

		p
			font-size: 14px

	.close-button-container
		text-align: center

		button
			font-family: PoppinsMedium

.dark-mode
	#terms-of-use
		.fetched-data-container
			h1, h2, h3, h4, h5, h6
				color: $dark-text-color

			ul li, p
				color: $white

</style>
