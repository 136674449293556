<script setup>
    import { useStore } from 'vuex'
    import { ref, computed } from 'vue'
    import Button from "@/components/Common/Button.vue"
    import Select2 from 'vue3-select2-component'

    const store = useStore()

    const {settings: storeValues, user} = store.state

    const selectedCompanyNumber =
        user.selectedCompany
            ? user.selectedCompany.company_nr
            : null

    const submitButtonText = 'on_site.profile.save_button'.trans()
    const isRequesting = computed(() => storeValues.isUserFormRequesting)

    const fields = ref({
        first_name: {
            required: true,
            hasError: false,
            input: storeValues.data.first_name,
            label: 'on_site.profile.first_name_label'.trans()
        },
        last_name: {
            required: true,
            hasError: false,
            input: storeValues.data.last_name,
            label: 'on_site.profile.last_name_label'.trans()
        },
        phone: {
            required: true,
            hasError: false,
            input: storeValues.data.phone && storeValues.data.phone.number,
            label: 'on_site.profile.phone_label'.trans()
        },
        phone_country: {
            required: true,
            hasError: false,
            input: storeValues.data.phone && storeValues.data.phone.country_code,
        },
        mobile: {
            required: false,
            hasError: false,
            input: storeValues.data.mobile.number,
            label: 'on_site.profile.mobile_label'.trans()
        },
        mobile_country: {
            required: false,
            hasError: false,
            input: storeValues.data.mobile && storeValues.data.mobile.country_code,
        },/*
        fax: {
            required: false,
            hasError: false,
            input: storeValues.data.fax.number,
            label: 'on_site.profile.fax_label'.trans()
        },
        fax_country: {
            required: false,
            hasError: false,
            input: storeValues.data.fax && storeValues.data.fax.country_code,
        },*/
    })

    const submitButtonAvailable = computed(() => validateForm())

    const selectOptions = {
        phoneCountry: formatOption(storeValues.options.phone),
        mobileCountry: formatOption(storeValues.options.mobile),
        //faxCountry: formatOption(storeValues.options.fax)
    }

    const selectSettings = {
        dropdownCssClass: 'country-select-dropdown',
        minimumResultsForSearch: -1
    }

    const submitForm = () => {
        const isValid = validateForm()
        if(isValid) {
            const payload = createPayload()

            store.dispatch('settings/updateUserSettings', {
                payload,
                company_number: selectedCompanyNumber,
            })
        }
    }

    function createPayload() {
        const payload = {}
        const fieldValues = fields.value

        Object.keys(fieldValues).forEach(fieldName => {
            payload[fieldName] = fieldValues[fieldName].input
        })

        return payload
    }

    function validateForm(alterFormField = true) {
        let isFormValid = true
        const fieldValues = fields.value

        Object.keys(fieldValues).forEach(fieldName => {
            const singleField = fieldValues[fieldName]

            let valid = true
            if(singleField.required && !singleField.input) {
                valid = false
            }

            if (alterFormField) singleField.hasError = !valid

            if (!valid) {
                isFormValid = false
            }
        })

        return isFormValid
    }

    function formatOption(option) {
        return option.country_code && option.country_code.selector
            ? option.country_code.selector : []
    }
    
    function validatePhoneNumbers() {
        fields.value.phone.input = fields.value.phone.input.replace(/[^0-9]/g, '')
        fields.value.mobile.input = fields.value.mobile.input.replace(/[^0-9]/g, '')
    }
</script>

<template>
    <div class="user-form-container">
        <div class="user-name-container">
            <p>{{'on_site.profile.customer_info'.trans()}}</p>
            <div class="form-group material">
                <input
                    id="first_name"
                    :class="{ 'has-error': fields.first_name.hasError }"
                    v-model="fields.first_name.input"
                    :placeholder="fields.first_name.label"
                />
                <label for="name">{{ fields.first_name.label }}</label>
            </div>
            <div class="form-group material">
                <input
                    id="last_name"
                    :class="{ 'has-error': fields.last_name.hasError }"
                    v-model="fields.last_name.input"
                    :placeholder="fields.last_name.label"
                />
                <label for="name">{{ fields.last_name.label }}</label>
            </div>
        </div>

        <div class="user-numbers-container">
            <div class="profile-number-input">
                <Select2
                    class="select-container"
                    :class="{ 'has-error': fields.phone_country.hasError }"
                    v-model="fields.phone_country.input"
                    :settings="selectSettings"
                    :options="selectOptions.phoneCountry"
                />
                <div class="form-group material">
                    <input
                        id="phone"
                        :class="{ 'has-error': fields.phone.hasError }"
                        v-model="fields.phone.input"
                        :placeholder="fields.phone.label"
                        @input="validatePhoneNumbers()"
                    />
                    <label for="phone">{{ fields.phone.label }}</label>
                </div>
            </div>
            <div class="profile-number-input">
                <Select2
                    class="select-container"
                    :class="{ 'has-error': fields.mobile_country.hasError }"
                    v-model="fields.mobile_country.input"
                    :settings="selectSettings"
                    :options="selectOptions.mobileCountry"
                />
                <div class="form-group material">
                    <input
                        id="mobile"
                        :class="{ 'has-error': fields.mobile.hasError }"
                        v-model="fields.mobile.input"
                        :placeholder="fields.mobile.label"
                        @input="validatePhoneNumbers()"
                    />
                    <label for="mobile">{{ fields.mobile.label }}</label>
                </div>
            </div>
            <!-- Hide for now -->
            <div class="profile-number-input" v-if="false">
                <Select2
                    class="select-container"
                    v-model="fields.fax_country.input"
                    :class="{ 'has-error': fields.fax_country.hasError }"
                    :settings="selectSettings"
                    :options="selectOptions.faxCountry"
                />
                <div class="form-group material">
                    <input
                        id="fax"
                        :class="{ 'has-error': fields.fax.hasError }"
                        v-model="fields.fax.input"
                        :placeholder="fields.fax.label"
                    />
                    <label for="fax">{{ fields.fax.label }}</label>
                </div>
            </div>
        </div>

        <Button
            blue
            bold
            :disabled="!submitButtonAvailable"
            :key="submitButtonAvailable"
            :click-event="submitForm"
        >{{ submitButtonText }}</Button>
    </div>
</template>