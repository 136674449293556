<script setup>
    const props = defineProps({
        data: Object,
        showFull: Boolean
    })

    const showFull = props.showFull
    const data = props.data

    const id = data.id
    const type = data.type

    const title = data.title
    const creationDate = data.creation_display_date

    const constructionSiteName = data.construction_site_name

    const materialName = data.material_name
    const quantity = data.quantity
    const quantity_unit = data.quantity_unit

    const materialCategory = data.material_category
    const materialNumber = data.material_number

    const deliveryStartDate = data.delivery_start_date
    const deliveryStartTime = data.delivery_start_time

    const deliveryEndDate = data.delivery_end_date
    const deliveryEndTime = data.delivery_end_time

    const isSeen = data.is_seen
</script>

<template>
    <div class="list-item notification-calloff" :class="{ 'preview': !showFull }">
        <p class="creation-date">
            <img src="@/assets/icons/circle-weak-red-full.svg" v-if="!isSeen">
            {{ creationDate }}
        </p>

        <h4 class="title" :class="{'bold': !isSeen}">{{ title }}</h4>
        <p class="sub-title" v-if="constructionSiteName">{{ constructionSiteName }}</p>

        <div class="material">
            <p class="material-title">
                <span class="name">{{ materialName }}</span>
                <span class="quantity" v-if="quantity">
                    <img src="@/assets/icons/amount-gray.svg">
                    {{ quantity }} {{ quantity_unit }}
                </span>
            </p>
            <table class="material-table" v-if="showFull">
                <tr v-if="materialCategory">
                    <td>
                        {{ 'on_site.notification.delivery_note.material_category_label'.trans() }}:
                    </td>
                    <td>
                        {{ materialCategory }}
                    </td>
                </tr>
                <tr v-if="materialNumber">
                    <td>
                        {{ 'on_site.notification.delivery_note.material_number_label'.trans() }}:
                    </td>
                    <td>
                        {{ materialNumber }}
                    </td>
                </tr>
            </table>
        </div>
        <div class="delivery-date">
            <div class="delivery-start" v-if="deliveryStartDate || deliveryStartTime">
                <p>{{ 'on_site.notification.calloff.delivery_start_label'.trans() }}</p>
                <div class="delivery">
                    <div class="date" v-if="deliveryStartDate">
                        <img src="@/assets/icons/calendar-blue.svg">
                        {{ deliveryStartDate }}
                    </div>
                    <div class="time" v-if="deliveryStartTime">
                        <img src="@/assets/icons/clock.svg">
                        {{ deliveryStartTime }}
                    </div>
                </div>
            </div>
            <div class="delivery-end" v-if="(deliveryEndDate || deliveryEndTime) && showFull">
                <p>{{ 'on_site.notification.calloff.delivery_end_label'.trans() }}</p>
                <div class="delivery">
                    <div class="date" v-if="deliveryEndDate">
                        <img src="@/assets/icons/calendar-blue.svg">
                        {{ deliveryEndDate }}
                    </div>
                    <div class="time" v-if="deliveryEndTime">
                        <img src="@/assets/icons/clock.svg">
                        {{ deliveryEndTime }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.dark-mode
    .notification-calloff
        background-color: $dark-button-gray
        border: 1px solid $component-border

        .creation-date
            color: $dark-text-color

        .title
            color: $white

        .sub-title
            color: $dark-text-color

        .material
            .material-title
                .name
                    color: $white

                .quantity
                    color: $dark-text-color

            .material-table
                tr
                    td
                        color: $white

        .delivery-date
            .delivery-start, .delivery-end
                p
                    color: $white

            .delivery
                color: $white

.notification-calloff
    background: $white
    text-decoration: none

    padding-left: 12px
    padding-right: 15px
    padding-bottom: 20px
    padding-top: 2px

    text-align: left
    border-radius: 4px
    box-shadow: 0 2px 2px #00000012

    .creation-date
        margin: 0
        margin-right: -8px
        text-align: right

        color: $gray-blue
        font-family: 'PoppinsRegular'
        font-size: 12px
        line-height: 24px

        img
            margin-right: 3px

    .title
        margin-top: 0
        margin-bottom: 4px

        color: $text-black
        font-size: 18px
        font-weight: normal
        font-family: 'PoppinsMedium'
        line-height: 24px
        text-transform: uppercase

        &.bold
            font-family: PoppinsBold

    .sub-title
        margin-top: 0
        margin-bottom: 6px

        color: $gray-blue
        font-size: 14px
        font-weight: normal
        font-family: 'PoppinsBold'
        line-height: 21px
        letter-spacing: 0

    .material
        padding-left: 4px
        padding-right: 4px

        padding-top: 12px
        padding-bottom: 8px

        border-top: 1px solid $border-gray
        border-bottom: 1px solid $border-gray

        .material-title
            margin-top: 0
            margin-bottom: 3px

            display: flex
            flex-direction: row

            .name
                font-family: 'PoppinsSemiBold'
                color: $text-black
                font-size: 13px
                line-height: 20px

                flex-grow: 1

                vertical-align: top

            .quantity
                float: right

                font-family: 'PoppinsSemiBold'
                color: $gray-blue
                font-size: 13px
                line-height: 20px

                img
                    height: 12px
                    width: auto
                    vertical-align: middle

                    margin-right: 5px

        .material-table
            border-collapse: collapse

            tr
                td
                    padding: 0
                    color: $text-black
                    font-family: 'PoppinsMedium'
                    font-size: 12px
                    line-height: 22px

                    vertical-align: top

                    &:first-child
                        padding-right: 12px

    .delivery-date
        padding-left: 4px
        padding-right: 4px

        padding-top: 12px

        p
            margin-top: 0
            margin-bottom: 5px

            color: $text-black
            font-size: 12px
            line-height: 21px
            font-weight: normal
            font-family: 'PoppinsSemiBold'

        .delivery-start
            margin-bottom: 12px

        .delivery
            display: flex
            flex-direction: row

            color: $text-black
            font-size: 14px
            line-height: 24px
            font-weight: normal
            font-family: 'PoppinsRegular'

            .date
                width: 50%

                display: flex
                flex-direction: row

                img
                    width: 20px
                    height: auto

                    margin-right: 12px

            .time
                width: 50%

                display: flex
                flex-direction: row

                img
                    width: 17px
                    height: auto

                    margin-right: 12px

    &.preview
        padding-bottom: 16px

        .material
            padding-bottom: 0
            border-bottom: none

        .delivery-date
            padding-top: 0

</style>