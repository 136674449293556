// Logic for updating url with multiselects
export function updateUrlParameterMulti(uri, key, value) {

    // split the uri into url + hash if it exists
    let i = uri.indexOf('#')
    let hash = i == -1 ? '' : uri.substr(i)
    let url = i == -1 ? uri : uri.substr(0, i)

    // create key-value string pair
    let keyValue = key + '[]=' + value

    // if the value already exists remove it
    if (url.includes(keyValue)) {

        // escape '[' and ']' characters
        let keyValueEscaped = keyValue.replace(/\[|\]|\+/g, x => `\\${x}`)

        // create regex and remove it from url
        let keyValueRegex = new RegExp('([?&]?)' + keyValueEscaped, 'i')
        url = url.replace(keyValueRegex, '')

    // if the value doesnt exist append it with a separator
    } else {
        url = url + '&' + keyValue
    }

    return url + hash
}