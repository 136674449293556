<template>
	<div class="calloff-card" :class="{'calloff-creation-card': creationCard, 'cancelled': item.cancelled}">
		<div class="calloff-header">
			<div class="calloff-material">
				<img alt=" " src="../../assets/icons/amount-dark-gray.svg"/>
				<span class="title">{{ "on_site.daily_amount.material".trans() }}</span>
				<span class="name">{{ item.material_designation || '—' }}</span>
			</div>

			<div class="calloff-table-container" v-if="item.factory_number">
				<table class="calloff-material-table">
					<tr>
						<td class="material">{{ "on_site.daily_amount.material_number".trans() }}</td>
						<td>{{ item.material_number || '—' }}</td>
					</tr>
					<tr>
						<td class="material">{{ "on_site.daily_amount.factory".trans() }}</td>
						<td>
							<span v-for="(factoryNum, index) in item.factory_number.split(', ')"
								:key="factoryNum.id">
								{{ factoryNum ? factoryNum + ' — ' + item.factory_name.split(", ")[index] : '—' }}<br/>
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="spacer"></div>

		<div class="calloff-bottom-container">
			<div class="calloff-amounts-table-container">
				<p class="amounts-title" v-if="creationCard">{{ "on_site.calloffs.amounts-title".trans() }}</p>
				<table class="calloff-table">
					<tr>
						<td class="icon-container">
							<img alt=" " src="../../assets/icons/circle-green-full.svg"/>
							{{ "on_site.calloffs.amount".trans() }}
						</td>
						<td >{{ `${(parseInt(item.delivered_quantity_raw) + parseInt(item.remaining_quantity_raw)).toLocaleString('de-DE') || '0'} ${item.quantity_unit}`}}</td>
					</tr>
					<tr>
						<td class="icon-container">
							<img alt=" " src="../../assets/icons/circle-gray-full.svg"/>
							{{ "on_site.calloffs.delivered".trans() }}
						</td>
						<td>{{ item.delivered_quantity_raw ? `${parseInt(item.delivered_quantity_raw).toLocaleString('de-DE')} ${item.quantity_unit}` : '—' }}</td>
					</tr>
					<tr>
						<td class="icon-container open-amount">
							<img alt=" " src="../../assets/icons/circle-blue-full.svg"/>
							{{ "on_site.calloffs.open".trans() }}
						</td>
						<td class="open-amount">{{ item.remaining_quantity_raw ? `${parseInt(item.remaining_quantity_raw).toLocaleString('de-DE')} ${item.quantity_unit}` : '—' }}</td>
					</tr>
				</table>
			</div>

			<div class="calloff-button-container" v-if="!creationCard && !item.cancelled">
				<Button
						blue
						medium-text
						small
						:to="{ name: 'calloff-creation', params: { 'id': item.contract_item_id } }"
				>
						{{ "on_site.calloffs.go-to-calloff-creation".trans() }}
						<img alt=" " src="../../assets/icons/go-to-arrow.svg"/>
				</Button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useStore } from 'vuex'
    import Button from "@/components/Common/Button.vue";
	
	const store = useStore()

	const { creationCard, item } = defineProps({
		creationCard: {
			type: Boolean,
			default: false
		},
		item: Object
	})
</script>

<style lang="sass" scoped>
.calloff-creation-card
	filter: none !important
	background-color: $background-gray !important

.calloff-card
	border-radius: 3px
	padding: 15px 10px 10px 10px
	background-color: $white
	filter: drop-shadow(0px 3px 3px #01000029)
	box-sizing: border-box
	text-align: left

	&.cancelled
		background-color: #eed8d9

	.calloff-header
		.calloff-material
			.title, .name
				margin-left: 10px
				font-family: PoppinsSemiBold
				font-size: 15px
				letter-spacing: 0.07px
				line-height: 23px
				color: $text-lighter-gray

		.calloff-table-container
			font-family: PoppinsMedium
			font-size: 12px
			letter-spacing: 0.06px
			line-height: 18px

			.material
				padding-right: 10px

	.calloff-table-container
		.calloff-material-table
			.material
				vertical-align: top

	.spacer
		margin-top: 15px
		margin-bottom: 10px
		border-top: 1px solid rgba(98, 112, 134, 0.5)

	.calloff-bottom-container
		display: flex

		.calloff-amounts-table-container
			.amounts-title
				margin: 0
				font-family: PoppinsMedium
				font-size: 12px
				letter-spacing: 0.02px
				line-height: 16px
				color: $text-gray

			.calloff-table
				font-family: PoppinsMedium
				font-size: 13px
				letter-spacing: 0.03px
				line-height: 22px

				.icon-container
					padding-right: 30px

				.open-amount
					color: $button-blue

		.calloff-button-container
			align-items: flex-end
			display: flex
			margin-left: auto

			.calloff-creation-button
				display: flex
				justify-content: center
				align-items: center
				margin-top: auto
				border-radius: 5px
				height: 32px
				width: 81px
				font-family: PoppinsMedium
				font-size: 12px
				letter-spacing: 0.02px

				img
					margin-left: 10px
					height: 18px

.dark-mode
	.calloff-creation-card
		background-color: $text-black !important
		border: none !important

	.calloff-card	
		background-color: $dark-button-gray
		border: 1px solid $component-border
		color: $white

		.calloff-header
			.calloff-material
				.title, .name
					color: $gray-blue

		.calloff-bottom-container
			.calloff-amounts-table-container
				.calloff-table
					.open-amount
						color: $light-blue
</style>