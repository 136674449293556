import NetworkHelper from './NetworkHelper'
import { makeRequest } from '@/utils/makeRequest.js'
import { showErrorPopup } from './globalSwalPopups'

window.appConfig = {
    'locale': 'de_DE',
    'shortLocale': 'de'
}

const Translations = {
    init: async function () {
        const isOnline = NetworkHelper.checkStatus()

        this.setStringPrototype()

        if (isOnline) {
            try {
                // Fetch newest translations from API
                const translations = await this.fetchTranslations()

                // Update localStorage values (So we can re-use them in offline)
                window.localStorage.setItem('bag_on_site_translations', JSON.stringify(translations))
                window.appConfig.siteTranslations = translations

                return

            } catch (err) {
                throw err
            }

        } else {
            const translations = JSON.parse(window.localStorage.getItem('bag_on_site_translations')) || {}
            window.appConfig.siteTranslations = translations

            return
        }
    },
    fetchTranslations: async function() {
        try {
            const data = await makeRequest('post', 'app_translations', {}, false)
            return data
        } catch (err) {
            throw err
        }

    },
    setStringPrototype: function() {
        const _self = this

        // Prototype function on strings used for creating pimcore translations
        String.prototype.trans = function (locale = window.appConfig.locale) {
            const key = this.toString()
            const { siteTranslations } = window.appConfig

            if (siteTranslations) {
                if (key in siteTranslations) {
                    return siteTranslations[key][locale] != '' ? siteTranslations[key][locale] : key

                // if it's a new key, create it
                } else {
                    _self.createTranslation(key)
                    return key
                }
            }
        }
    },
    createTranslation: async function(translationKey) {
        try {
            const { siteTranslations } = window.appConfig

            if(NetworkHelper.checkStatus()) {
                const url = Routing.generate('create_translation', { translationKey }, true)
                await axios.get(url)
            }

            siteTranslations[translationKey] == ''

        } catch (error) {
            showErrorPopup()
            throw error
        }
    }
}

export default Translations
