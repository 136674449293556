<template>
    <div class="sub-header-navigation">
        <div class="navigation-menu-toggler-container">
            <div class="navigation-menu-toggler"
                @click="toggleNavigation"
            >
                <span>{{ props.currentPage }}</span>
                <span class="open-navigation">
                    <img 
                        class="navigation-toggler"
                        alt="Open" src="@/assets/icons/arrow-blue.svg"
                    >
                </span>
            </div>
            <FilterMenu
                :border="true"
                :class="!filterParameter && 'hide'"
                :contract-items="contractItems"
                :filter-action="filterAction"
                :filter-parameter="filterParameter"
                :filter-route="filterRoute"
                :key="parameterValue"
                :page-name="pageName"
                :parameter-name="parameterName"
                :parameter-value="parameterValue"
                :show-datepicker="showDatepicker"
                :sub-filter="subFilter"
                v-if="subFilter"
            />
        </div>

        <div class="navigation-menu">
            <a class="navigation-button"
                v-if="route.name == 'contract-detail'"
                :href="router.resolve({ name: 'contract-detail', params: { id: contractId }}).href"
                @click="closeMenuAndShowLoader('amountItems/setAmountItemsRequestingFlag')"
            >
                {{ "on_site.menu.contract_detail_dashboard".trans() }}
            </a>
            <router-link :to="{ name: 'contract-detail', params: { id: contractId }}" class="navigation-button" v-else>
                {{ "on_site.menu.contract_detail_dashboard".trans() }}
            </router-link>
            <div class="spacer"></div>
            
            <a class="navigation-button"
                v-if="route.name == 'delivery-incoming'"
                :href="router.resolve({ name: 'delivery-incoming', params: { id: contractId }}).href"
                @click="closeMenuAndShowLoader('deliveryItems/setDeliveryItemsRequestingFlag')"
            >
                {{ "on_site.menu.deliveries_in_progress".trans() }}
            </a>
            <router-link :to="{ name: 'delivery-incoming', params: { id: contractId }}" class="navigation-button" v-else>
                {{ "on_site.menu.deliveries_in_progress".trans() }}
            </router-link>
            <div class="spacer"></div>

            <a class="navigation-button"
                v-if="route.name == 'delivery-confirmed'"
                :href="router.resolve({ name: 'delivery-confirmed', params: { id: contractId }}).href"
                @click="closeMenuAndShowLoader('deliveryItems/setDeliveryItemsRequestingFlag')"
            >
                {{ "on_site.menu.confirmed_deliveries".trans() }}
            </a>
            <router-link :to="{ name: 'delivery-confirmed', params: { id: contractId }}" class="navigation-button" v-else>
                {{ "on_site.menu.confirmed_deliveries".trans() }}
            </router-link>
            <div class="spacer"></div>

            <a class="navigation-button"
                v-if="route.name == 'daily-amount'"
                :href="router.resolve({ name: 'daily-amount', params: { id: contractId }}).href"
                @click="closeMenuAndShowLoader('amountItems/setAmountItemsRequestingFlag')"
            >
                {{ "on_site.menu.daily_quantity".trans() }}
            </a>
            <router-link :to="{ name: 'daily-amount', params: { id: contractId }}" class="navigation-button" v-else>
                {{ "on_site.menu.daily_quantity".trans() }}
            </router-link>
            <div class="spacer"></div>

            <a class="navigation-button"
                v-if="route.name == 'total-amount'"
                :href="router.resolve({ name: 'total-amount', params: { id: contractId }}).href"
                @click="closeMenuAndShowLoader('amountItems/setAmountItemsRequestingFlag')"
            >
                {{ "on_site.menu.total_quantity".trans() }}
            </a>
            <router-link :to="{ name: 'total-amount', params: { id: contractId }}" class="navigation-button" v-else>
                {{ "on_site.menu.total_quantity".trans() }}
            </router-link>
            <div class="spacer"></div>

            <template v-if="buttonPermissions.includes('call-off-creation')">
                <a class="navigation-button"
                    v-if="route.name == 'contract-items'"
                    :href="router.resolve({ name: 'contract-items', params: { id: contractId }}).href"
                    @click="closeMenuAndShowLoader('contractItems/setContractItemsRequestingFlag')"
                >
                    {{ "on_site.menu.calloff_creation".trans() }}
                </a>
                <router-link :to="{ name: 'contract-items' , params: { id: contractId }}" class="navigation-button" v-else>
                    {{ "on_site.menu.calloff_creation".trans() }}
                </router-link>
                <div class="spacer"></div>
            </template>

            <template v-if="buttonPermissions.includes('call-off-list')">
                <a class="navigation-button"
                   v-if="route.name == 'calloff-list'"
                   :href="router.resolve({ name: 'calloff-list', params: { id: contractId }}).href"
                   @click="closeMenuAndShowLoader('contractItems/setContractItemsRequestingFlag')"
                >
                    {{ "on_site.menu.calloff_list".trans() }}
                </a>
                <router-link :to="{ name: 'calloff-list' , params: {}}" class="navigation-button" v-else>
                    {{ "on_site.menu.calloff_list".trans() }}
                </router-link>
                <div class="spacer"></div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex'
    import { computed } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import FilterMenu from "@/components/Common/FilterMenu.vue";

    const props = defineProps({
        currentPage: String,
        subFilter: Boolean,
        contractItems: Boolean,
        filterAction: String,
        filterParameter: Function,
        filterRoute: String,
        pageName: String,
        parameterName: String,
        parameterValue: Number,
        showDatepicker: Boolean
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const buttonPermissions = computed(() => store.state.user.buttonPermissions)

    const contractId = computed(() => store.state.contracts.selectedContract.contract_id)

    function toggleNavigation() {
        $('.navigation-menu').toggleClass('open')
        $('.navigation-toggler').toggleClass('open')

        let menuIsOpen = $('.navigation-menu').hasClass('open')

        // Toggle scroll on body
        if (menuIsOpen) $('html').addClass('no-scroll')
        else $('html').removeClass('no-scroll')
    }

    function closeMenuAndShowLoader(loaderStorePath) {
        $('.navigation-menu').removeClass('open')
        $('.navigation-toggler').removeClass('open')

        store.commit(loaderStorePath, true)
    }
</script>

<!--NOTE: REMOVE ON REDESIGN-->
<style lang="sass">
.navigation-menu-toggler-container
    .overview-filters-page > .loader
        padding: 0 5px !important
</style>

<style lang="sass" scoped>
.sub-header-navigation
    align-items: center
    display: flex

    .navigation-menu-toggler-container
        align-items: center
        background-color: $dark-blue
        display: flex
        gap: 10px
        height: 100%
        padding: 0 24px
        position: relative
        width: 100%
        z-index: 5

        .navigation-menu-toggler
            display: flex
            border: 1px solid $dark-blue
            border-radius: 5px
            margin: 8px 0
            padding-left: 10px
            padding-top: 5px
            height: 30px
            width: 100%
            background-color: $white
            cursor: pointer

            font-family: PoppinsSemiBold
            font-size: 12px
            letter-spacing: 0.02px
            text-align: left
            color: $text-blue

            .open-navigation
                margin-top: -10px
                padding: 10px
                margin-left: auto

                img 
                    width: 6px
                    transition: 0.1s

                    &.open
                        transform: rotate(90deg)

    .navigation-menu
        position: absolute
        display: flex
        flex-direction: column
        width: 100%
        min-width: 210px
        height: calc(100vh - 51px - 55px - 40px)
        top: -100vh
        padding-top: 20px
        padding-bottom: 130px
        transition: 0.5s
        overflow-y: auto
        opacity: 0
        box-shadow: 0 3px 6px #01000029
        background-color: $white
        z-index: -10

        &.open
            top: 46px
            opacity: 1

        a
            text-decoration: none

        .spacer
            margin: 7px auto
            width: 88%
            height: 1px
            min-height: 1px
            background-color: $gray-blue

        .navigation-button
            display: flex
            align-items: center
            cursor: pointer
            margin: 0 auto
            border: none
            border-radius: 4px
            height: 50px
            min-height: 50px
            width: 88%
            padding-left: 11px
            text-align: left
            font-family: PoppinsMedium
            font-size: 16px
            box-sizing: border-box
            justify-content: space-between
            padding-right: 15px
            background-color: $button-background-gray
            color: $text-lighter-gray

.dark-mode
    .navigation-menu
        background-color: $dark-button-gray

        .navigation-button
            background-color: $text-black
            color: $dark-button-text
            
</style>