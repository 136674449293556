<template>
	<div class="help-page">
		<MessagesHeader :pageTitle="pageTitle"/>

		<Loader class="help-loader" v-if="isPageRequesting"/>
		<template v-else>
			<div class="fetched-data-container" v-if="helpPageHTML" v-html="helpPageHTML">
			</div>
			<div class="empty-data" v-else>
				{{'on_site.help_page.no_data'.trans()}}
			</div>
		</template>

		<Footer/>
	</div>
</template>

<script setup>
	import MessagesHeader from '@/components/Messages/MessagesHeader.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Loader from "@/components/Common/Loader.vue"
	import { useStore } from 'vuex'
	import { computed } from 'vue'

	const store = useStore()

	const pageTitle = 'on_site.help_page.menu_title'.trans()
	const helpPageHTML = computed(() => store.state.help.helpPageHTML)
	const isPageRequesting = computed(() => store.state.help.isRequesting)

	store.commit('setQuasiOverlayStatus', { 'helpIsOpen': true} )
</script>

<style lang="sass">
.help-page
	.help-loader
		padding-top: 80px

	.fetched-data-container,
	.empty-data
		padding: 60px 24px 120px 24px
		text-align: left
		color: $text-dark-gray

		img
			width: 100% !important
			height: auto !important

	.empty-data
		padding-top: 80px

.dark-mode
	.help-page
		.fetched-data-container,
		.empty-data
			color: $white
</style>