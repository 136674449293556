import store from '@/store/index'
import { useOneSignal } from '@onesignal/onesignal-vue3'

const OneSignalHelper = {
    /**
     * Show notifications prompt to user
     * @param {*} oneSignalInstance
     * @param {*} vuexStoreInstance
     * @returns
     */
    setUserSubscription: async () => {
        const oneSignalInstance = useOneSignal()
        const userData = store.state.user.data

        if(!userData) {
            return
        }

        const { has_notification_subscription } = userData

        // Show prompt
        // If user has allowed notifications for browser already, native prompt won't be visible
        if (has_notification_subscription) {
            await oneSignalInstance.Notifications.requestPermission()
        }
    },
    /**
     * Subscribe user
     * @param {*} oneSignalInstance
     * @param {*} vuexStoreInstance
     */
    subscribeUser: () => {
        const oneSignalInstance = useOneSignal()

        oneSignalInstance.User.PushSubscription.optIn()
    },
    /**
     * Unsubscribe user
     * @param {*} oneSignalInstance
     * @param {*} vuexStoreInstance
     */
    unSubscribeUser: () => {
        const oneSignalInstance = useOneSignal()

        oneSignalInstance.User.PushSubscription.optOut()
    },
    /**
     * Trigger for notifications permission change handler
     * @param {*} oneSignalInstance
     * @param {*} vuexStoreInstance
     */
    notificationPermissionsChangedListener: function() {
        const oneSignalInstance = useOneSignal()

        oneSignalInstance.Notifications.addEventListener('permissionChange', (permission) => {
            this[permission ? "subscribeUser" : "unSubscribeUser"]()
        })
    },
    /**
     * Get options for initialization
     * @returns Object
     */
    getInitOptions: () => {
        const appId = process.env.VUE_APP_ONESIGNAL_APP_ID
        const safari_web_id = process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID
        const localhost_as_origin = process.env.VUE_APP_ONESIGNAL_LOCALHOST_AS_SECURE_ORIGIN

        // Service worker isn't served in root, because it will be in conflict with PWA one
        // Since Onesignal serviceworker doesn't actually need site control, it can be in different scope which he controls ("/push/onesignal")
        let options = {
            notifyButton: {
                enable: true
            },
            serviceWorkerParam: { scope: '/push/onesignal/' },
            serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js'
        }

        if (appId) {
            options = {
                ...options,
                appId
            }
        } else {
            console.error('Missing .env parameter for OneSignal: VUE_APP_ONESIGNAL_APP_ID \n https://documentation.onesignal.com/docs/accounts-and-keys#app-id')
        }

        if (safari_web_id) {
            options = {
                ...options,
                safari_web_id
            }
        } else {
            console.warn('Missing .env parameter for OneSignal: VUE_APP_ONESIGNAL_SAFARI_WEB_ID \n https://documentation.onesignal.com/v7.0/docs/safari-web-push-setup')
        }

        if (process.env.NODE_ENV === 'development' || localhost_as_origin === 'true') {
            options = {
                ...options,
                allowLocalhostAsSecureOrigin: true,
            }
        }

        return options
    },
    /**
     * Fetch some user data
     * @param {*} oneSignalInstance
     * @returns Object
     */
    getUserInfo: async () => {
        const oneSignalInstance = useOneSignal()

        const userTags = await oneSignalInstance.getTags()
        const userId = await oneSignalInstance.getUserId()
        const userSubscription = await oneSignalInstance.getSubscription()
        const externalUserId = await oneSignalInstance.getExternalUserId()

        return {
            id: userId,
            tags: userTags,
            externalUserId,
            subscription: userSubscription,
        }
    }
}

export default OneSignalHelper
