export function updateUrlParameter (uri, key, value) {
    // Remove the hash part before operating on the uri
    let i = uri.indexOf('#')
    let hash = i === -1 ? ''  : uri.substr(i)
    uri = i === -1 ? uri : uri.substr(0, i)

    // Key is array-like
    // E.g "filter[name]"...
    let escapedKey = key
    if (/\[(.*?)\]/.test(key)) {
        // We need to escape "[ & ]" characters
        escapedKey = key.replace(/\[|\]/g, function(x) {
            return `\\${x}`
        })
    }

    // Regex for existing key
    let re = new RegExp("([?&])" + escapedKey + "=.*?(&|$)", "i")
    let removeReg = new RegExp("([?&]?)" + escapedKey + "=[^&]*", "i")

    // Separator logic
    // let separator = uri.indexOf('?') !== -1 ? "&" : "?"

    // Remove key-value pair if value is empty
    if (!value) {
        uri = uri.replace(removeReg, '')

        // if (uri.slice(-1) === '?') {
        //     uri = uri.slice(0, -1)
        // }

        // Replace first occurrence of & by ? if no ? is present
        // if (uri.indexOf('?') === -1) {
        //     uri = uri.replace(/&/, '?')
        // }

    } else if (uri.match(re)) {
        // Key already exists - replace
        if(key == 'status' && uri.includes(key + "=" + value)) {
            uri = uri.replace('&' + key + "=" + value, '')
        } else {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2')
        }
    } else {
        // Simple "add" logic
        uri = uri + '&' + key + "=" + value
    }
    
    return uri + hash
}