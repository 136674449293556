const homeIcon = `
	<svg id="Home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27" height="27" viewBox="0 0 27 27">
		<defs>
			<clipPath id="clip-path">
			<rect id="Rechteck_1176" data-name="Rechteck 1176" width="27" height="27" fill="#547bcc"/>
			</clipPath>
		</defs>
		<g id="Gruppe_37299" data-name="Gruppe 37299" clip-path="url(#clip-path)">
			<path id="Pfad_29742" data-name="Pfad 29742" d="M0,13.284a3.918,3.918,0,0,1,.2-.656,3.461,3.461,0,0,1,.653-.934L9.929,2.252c.376-.391.752-.781,1.125-1.175a3.316,3.316,0,0,1,4.892,0Q18.786,4.044,21.637,7q2.263,2.356,4.526,4.712a2.951,2.951,0,0,1,.794,1.4A1.673,1.673,0,0,1,25.5,15.158c-.526.063-1.055.014-1.582.028-.064,0-.129,0-.192,0s-.1.019-.1.091c0,.04,0,.081,0,.122q0,3.944,0,7.889a3.865,3.865,0,0,1-.97,2.669,2.956,2.956,0,0,1-1.943,1.02.49.49,0,0,0-.078.022h-4.6q0-4.581,0-9.162c0-.1,0-.209,0-.312a.576.576,0,0,0-.6-.636,2.286,2.286,0,0,0-.288-.018q-1.645,0-3.291,0a2.994,2.994,0,0,0-.37.025.527.527,0,0,0-.5.467,2.184,2.184,0,0,0-.025.432q0,4.6,0,9.2h-4.6a.315.315,0,0,0-.064-.021,3.018,3.018,0,0,1-2.106-1.2,3.9,3.9,0,0,1-.821-2.491q0-3.964,0-7.929c0-.173,0-.173-.18-.173-.487,0-.974.012-1.459-.005a1.767,1.767,0,0,1-1.421-.709A1.924,1.924,0,0,1,0,13.745Zm21.93,5.3h0q0-2.465,0-4.929c0-.124.031-.156.158-.156.978.005,1.955,0,2.934,0,.157,0,.2-.072.136-.212a1.845,1.845,0,0,0-.338-.507q-1.76-1.826-3.516-3.655Q18.824,6.55,16.346,3.973c-.587-.611-1.169-1.227-1.768-1.827a1.435,1.435,0,0,0-1.316-.431,1.725,1.725,0,0,0-.96.543Q10.808,3.817,9.309,5.373q-3.52,3.66-7.042,7.32a2.192,2.192,0,0,0-.42.583c-.074.155-.031.226.144.226H4.87c.2,0,.2,0,.2.192q0,4.807,0,9.614a2.413,2.413,0,0,0,.166.928,1.568,1.568,0,0,0,1.6,1.083c.761-.029,1.524-.01,2.286,0,.145,0,.17-.041.17-.174q-.005-3.5,0-7.006a4.715,4.715,0,0,1,.166-1.274,2.121,2.121,0,0,1,1.006-1.381,2.544,2.544,0,0,1,1.23-.293q1.267-.01,2.534,0a13.675,13.675,0,0,1,1.417.024,2.016,2.016,0,0,1,1.746,1.2,3.971,3.971,0,0,1,.327,1.651c.009,1.557,0,3.114.005,4.671q0,1.222,0,2.444c0,.079,0,.134.111.133.858,0,1.717,0,2.576,0a1.229,1.229,0,0,0,.776-.272,1.941,1.941,0,0,0,.747-1.554c.018-1.634.006-3.267.006-4.9" transform="translate(0 0)" fill="#547bcc"/>
		</g>
	</svg>
`

const bellIcon = `
  	<svg id="Gruppe_37420" data-name="Gruppe 37420" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22.69" height="28.128" viewBox="0 0 22.69 28.128">
		<defs>
			<clipPath id="clip-path">
				<rect id="Rechteck_1302" data-name="Rechteck 1302" width="22.69" height="28.128" fill="#547bcc" stroke="#547bcc" stroke-width="0.25"/>
			</clipPath>
		</defs>
		<g id="Gruppe_37419" data-name="Gruppe 37419" clip-path="url(#clip-path)">
			<path id="Pfad_29823" data-name="Pfad 29823" d="M20.8,14.638c0,1.044.023,2.089,0,3.133a5.927,5.927,0,0,0,1.337,3.966,1.6,1.6,0,0,1,.373,1.9,1.743,1.743,0,0,1-1.765.854c-1.741-.014-3.482.024-5.222-.021-.622-.016-.815.181-.912.789a3.18,3.18,0,0,1-3.386,2.861,3.172,3.172,0,0,1-3.14-3.018c-.069-.626-.366-.622-.811-.62-1.741.008-3.482-.01-5.222.009a1.817,1.817,0,0,1-1.875-.851,1.7,1.7,0,0,1,.437-1.992,6.215,6.215,0,0,0,1.334-4.08c0-2.089-.01-4.178,0-6.266A9.3,9.3,0,0,1,8.271,2.174a1.433,1.433,0,0,0,.858-.807A2.3,2.3,0,0,1,11.3,0a2.275,2.275,0,0,1,2.27,1.366,1.634,1.634,0,0,0,1.063.876A9.429,9.429,0,0,1,20.8,10.983c.03,1.218,0,2.437.006,3.655m-9.518,8.578h4.6c1.567,0,3.133,0,4.7,0,.269,0,.617.1.751-.207.106-.246-.17-.432-.311-.62a7.4,7.4,0,0,1-1.5-4.676c.027-2.193.034-4.387,0-6.579a8.217,8.217,0,0,0-5.976-7.91c-.6-.159-.98-.3-1.039-1.022a1.032,1.032,0,0,0-1.194-.922,1.053,1.053,0,0,0-1.137,1,.859.859,0,0,1-.763.9A8.037,8.037,0,0,0,3.224,11.3c-.012,2.124,0,4.247,0,6.371a7.641,7.641,0,0,1-1.453,4.6c-.169.233-.551.449-.339.791.19.307.577.152.874.153,2.994.01,5.987.006,8.981.008m.032,1.274a3.27,3.27,0,0,0-.624.007c-.447.089-1.116-.3-1.309.243a1.563,1.563,0,0,0,.736,1.708,2.029,2.029,0,0,0,2.985-.576,1.281,1.281,0,0,0,.2-1.185c-.178-.375-.622-.162-.948-.19-.345-.03-.695-.007-1.042-.007" transform="translate(0 0.001)" fill="#547bcc" stroke="#547bcc" stroke-width="0.25"/>
		</g>
	</svg>
`

const questionMarkIcon = `
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27" height="27" viewBox="0 0 27 27">
	<defs>
		<clipPath id="clip-path">
		<rect id="Rechteck_1174" data-name="Rechteck 1174" width="27" height="27" fill="#547bcc"/>
		</clipPath>
	</defs>
	<g id="Gruppe_37294" data-name="Gruppe 37294" clip-path="url(#clip-path)">
		<path id="Pfad_29730" data-name="Pfad 29730" d="M13.5,0A13.5,13.5,0,1,1,0,13.472,13.5,13.5,0,0,1,13.5,0M13.5,25.915A12.414,12.414,0,1,0,1.086,13.491,12.42,12.42,0,0,0,13.5,25.915" transform="translate(0 0)" fill="#547bcc"/>
		<path id="Pfad_29731" data-name="Pfad 29731" d="M12.96,16.124c-.27,0-.54,0-.81,0-.067,0-.118,0-.119-.094a3.99,3.99,0,0,1,.319-1.844,4.262,4.262,0,0,1,.763-1c.348-.376.723-.725,1.1-1.068a2.753,2.753,0,0,0,.837-1.183,2.019,2.019,0,0,0-.632-2.193A2.314,2.314,0,0,0,12.8,8.41a1.966,1.966,0,0,0-1.814,2.016c-.009.09-.016.18-.023.269-.017.241-.017.241-.266.241H9.248c-.078,0-.157.015-.154-.113A3.878,3.878,0,0,1,10.865,7.4,4.441,4.441,0,0,1,13.5,6.791a3.976,3.976,0,0,1,2.252.7A3.06,3.06,0,0,1,17.043,9.8a3.7,3.7,0,0,1-.516,2.411,3.679,3.679,0,0,1-.659.7c-.308.281-.625.55-.936.827a2.73,2.73,0,0,0-.988,1.7,4.15,4.15,0,0,0-.025.471c-.005.223,0,.223-.233.223H12.96" transform="translate(0.421 0.314)" fill="#547bcc"/>
		<path id="Pfad_29732" data-name="Pfad 29732" d="M14.148,17.866a1.236,1.236,0,0,1-1.3,1.271,1.276,1.276,0,0,1-1.28-1.271,1.242,1.242,0,0,1,1.286-1.252,1.222,1.222,0,0,1,1.294,1.252" transform="translate(0.536 0.769)" fill="#547bcc"/>
	</g>
	</svg>
`

const qrIcon = `
	<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
		<g id="Scan" transform="translate(0.5 0.5)">
			<path id="Shape" d="M30,30H20V26.666h6.666v-5H30V30ZM10,30H0V21.667H3.333v5H10V30ZM30,16.667H0V13.333H30v3.334Zm0-8.334H26.666v-5H20V0H30V8.333Zm-26.667,0H0V0H10V3.333H3.333v5Z" fill="#547bcc" stroke="#ebedef" stroke-width="0"/>
		</g>
	</svg>
`

const shield = `
	<svg xmlns="http://www.w3.org/2000/svg" width="19.555" height="21.412" viewBox="0 0 19.555 21.412">
		<g transform="translate(0.82 0.634)">
		<g transform="translate(0 0.416)">
			<path d="M-10125,17817.1s4.451,3.4,8.91,3.969c.08.064-1.538,14.639-8.91,15.557" transform="translate(10134 -17817.104)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
			<path d="M-10116.086,17817.1s-4.451,3.4-8.91,3.969c-.08.064,1.538,14.639,8.91,15.557" transform="translate(10124.999 -17817.104)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
		</g>
			<path d="M0,.688S-1.144,1.965,4.269,3.93c2.452.89,4.069-.188,5.042,1.26C10.115,6.365,8.146,10,8.146,10H0Z" transform="translate(9 -0.688)" fill="#fff"/>
			<path d="M0,0H8V10.41A11.081,11.081,0,0,1,2.773,7.063C.773,4.563,0,0,0,0Z" transform="translate(1 9.313)" fill="#fff"/>
		</g>
	</svg>
`

const moonIcon = `
	<svg xmlns="http://www.w3.org/2000/svg" width="21.932" height="24.98" viewBox="0 0 21.932 24.98">
		<g class="a">
			<path class="b" d="M10695.465-4006.073a10.4,10.4,0,0,1-4.073-.823,10.44,10.44,0,0,1-3.326-2.243,10.424,10.424,0,0,1-2.242-3.326,10.415,10.415,0,0,1-.822-4.073,10.413,10.413,0,0,1,.822-4.073,10.429,10.429,0,0,1,2.242-3.326,10.448,10.448,0,0,1,3.326-2.242,10.4,10.4,0,0,1,4.073-.822,10.444,10.444,0,0,1,3.487.6,10.412,10.412,0,0,0-2.8,1.507,10.527,10.527,0,0,0-2.208,2.257,10.386,10.386,0,0,0-1.964,6.1,10.389,10.389,0,0,0,1.964,6.1,10.521,10.521,0,0,0,2.208,2.257,10.419,10.419,0,0,0,2.8,1.507A10.45,10.45,0,0,1,10695.465-4006.073Z" transform="matrix(0.891, -0.454, 0.454, 0.891, -7692.186, 8445.307)"/>
		</g>
	</svg>
`

const loader = `
	<svg version="1.1" class="submit-button-loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
		<defs>
			<style type="text/css"><![CDATA[
				.animated-bag-spinner-small {
					transform-origin: 20px 20px;
					animation: loading-spinner 0.9s linear infinite;
				}

				@keyframes loading-spinner {
					from {
						transform: rotate(0deg);
					}
					to {
						transform: rotate(360deg);
					}
				}
			]]></style>
		</defs>

		<path opacity="0.4" fill="#fff" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
			s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
			c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
		<path fill="#fff" class="animated-bag-spinner-small" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
			C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(224.883 20 20)">
		</path>
	</svg>
`

export {
    homeIcon,
    bellIcon,
    questionMarkIcon,
    qrIcon,
	shield,
	moonIcon,
	loader
}
