import { makeRequest } from '@/utils/makeRequest'

const state = {
    recentContracts: {},
    contracts: {
        items: [],
        pagination: {}
    },
    isContractsRequesting: false,
    isContractsPaginationRequesting: false,
    selectedContract: {}
}

const getters = {

}

const actions = {
    /**
     * Gets max 5 last contracts that user visited
     * 
     * @param {object} params - Optional extra parameters. By default it includes the selected company's number stored in user module.
     */
    fetchRecentContracts: async (
        { commit, rootState }, 
        params = { 'company_number': rootState.user.selectedCompany.company_nr }
    ) => {
        commit('setContractsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_recent_contracts', params)
            const { data } = response

            commit('setRecentContracts', data)
            commit('setContractsRequestingFlag', false)

        } catch(err) {
            commit('setContractsRequestingFlag', false)
            throw err
        }
    },
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchContractsOnLoad: async (
        { commit, rootState }, 
        params = { 'company_number': rootState.user.selectedCompany.company_nr }
    ) => {
        commit('setContractsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_contract_list', params )
            const { data } = response

            // This will also reset items & pagination
            commit('setContractsData', data)
            commit('setContractsRequestingFlag', false)
        } catch(err) {
            commit('setContractsRequestingFlag', false)
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchContractsPaginated: async ({ commit, rootState }, 
        params = { 'company_number': rootState.user.selectedCompany.company_nr }
    ) => {
        commit('setContractsPaginationRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_contract_list', params )
            const { data } = response

            // This will set merge items & update pagination
            commit('setContractsPaginatedData', data)
            commit('setContractsPaginationRequestingFlag', false)
        } catch(err) {
            commit('setContractsPaginationRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    // Onload
    setContractsRequestingFlag: (state, value) => {
        state.isContractsRequesting = value
    },
    setRecentContracts: (state, value) => {
        state.recentContracts = value
    },
    setSelectedContract: (state, contractData) => {
        state.selectedContract = contractData
    },
    setContractsData: (state, contracts) => {
        state.contracts = contracts
    },
    setContractsFavorite: (state, payload) => {
        const { position, value } = payload
        state.contracts.items[position].favorite = value 
    },
    // Pagination
    setContractsPaginationRequestingFlag: (state, value) => {
        state.isContractsPaginationRequesting = value
    },
    setContractsPaginatedData: (state, contracts) => {
        const { items, pagination } = contracts
        state.contracts = {
            pagination,
            items: [ ...state.contracts.items, ...items ]
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
