import { makeRequest, makeRequestWithGeneratedRoute } from '@/utils/makeRequest'

const state = {
    data: {
        Theme: '20'
    },
    dark_mode_activated: false,
    options: null,
    isRequesting: false, // For whole pages (profile & settings)
    isUserImageRequesting: false, // Image upload
    isUserFormRequesting: false, // User form
    isPasswordFormRequesting: false, // Password change form
}

const getters = {

}

const actions = {
    // Called on profile & settings
    fetchUserSettings: async ({ commit, state }, { company_number }) => {
        commit('setRequestingFlag', true)
        try {
            // Make request
            const response = await makeRequest('get', 'api_customer_profile', { company_number })

            // Update store values
            commit('setUserSettings', response)
            commit('setRequestingFlag', false)

        } catch(err) {
            commit('setRequestingFlag', false)
            throw err
        }
    },
    // Called on profile => User data submit
    updateUserSettings: async({ commit, state, dispatch }, { company_number, payload }) => {
        try {
            // Set requesting flag
            commit('setUserFormRequesting', true)

            // Generate route
            // [company_number is in query, payload will be form data]
            const route = Routing.generate('api_customer_profile', { company_number }, true)

            // Make update request
            const response = await makeRequestWithGeneratedRoute('post', route, payload)

            // Update store values (since we got it in response)
            commit('setUserSettings', response)

            // Change userName
            // => since both first & last name are required, no need for extra request
            const { first_name, last_name } = payload
            const userName = `${first_name} ${last_name}`
            dispatch('user/updateUserName', userName, { root:true })

            dispatch('flashMessage/showFlash', { message: 'on_site.profile.user_data.success_message'.trans() }, { root:true })

            // Remove requesting flag
            commit('setUserFormRequesting', false)

        } catch(err) {
            commit('setUserFormRequesting', false)
            dispatch('flashMessage/showFlash', { type: 'error', message: 'on_site.profile.user_data.error_message'.trans() }, { root:true })
            throw err
        }
    },
    // Called on profile => password change submit
    updateUserPassword: async({ commit, state, dispatch }, payload) => {
        commit('setPasswordFormRequesting', true)
        try {
            // Make request
            const response = await makeRequest('post', 'api-password-reset', payload)
            const { message } = response

            // Show flash message
            dispatch('flashMessage/showFlash', { message }, { root:true })

            // Remove flag
            commit('setPasswordFormRequesting', false)

            location.reload()

        } catch (error) {
            commit('setPasswordFormRequesting', false)
            if(error.response && error.response.data && error.response.data.errors) {
                const { errors } = error.response.data

                if (errors.length > 0) {
                    const { message } = errors[0]
                    dispatch('flashMessage/showFlash', { type: 'error', message }, { root:true })
                }
            } else {
                dispatch('flashMessage/showFlash', {
                    type: 'error',
                    message: 'on_site.profile.password_change.error_message'.trans() },
                    { root:true }
                )
            }

            throw error
        }
    },
    // Called on profile => avatar change
    updateUserImage: async ({ commit, state }, imageBlob) => {
        commit('setUserImageRequesting', true)
        try {
            // Make request
            await makeRequest('post', 'api_upload_profile_picture', {
                profile_image: imageBlob
            })

            // Update image preview
            let previewSrc = ''
            if (imageBlob) {
                previewSrc = URL.createObjectURL(imageBlob)
            }

            commit('setUserSetting', {
                settingName: 'img_url',
                value: previewSrc
            })

            commit('setUserImageRequesting', false)

        } catch (err) {
            commit('setUserImageRequesting', false)
            throw err
        }
    },
    // Called on setting change
    updateUserSingleSetting: async ({ commit, state }, { settingName, value }) => {
        // Update state
        commit('setUserSetting', { settingName, value })
    },
}

const mutations = {
    setRequestingFlag: (state, value = true) => {
        state.isRequesting = value
    },
    setUserImageRequesting: (state, value = true) => {
        state.isUserImageRequesting = value
    },
    setUserFormRequesting: (state, value = true) => {
        state.isUserFormRequesting = value
    },
    setPasswordFormRequesting: (state, value = true) => {
        state.isPasswordFormRequesting = value
    },
    setUserSettings: (state, settingsData) => {
        const { data, settings } = settingsData

        state.data = data
        state.options = settings
    },
    setUserSetting: (state, settingData) => {
        const { settingName, value } = settingData
        state.data[settingName] = value
    },
    setDarkMode: (state, darkModeValue) => {
        state.dark_mode_activated = darkModeValue
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
