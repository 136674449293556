<template>
    <div class="lightbox-container" :class="{ hide: !isLightboxOpen }">
        <p>{{ title }}</p>
        <div class="section-wrapper">
            <slot />
            <div class="section">
                <Button
                    :click-event="toggleLightbox"
                    gray-blue
                    bold
                    full-width
                >
                    Abbrechen
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup>
import Button from "@/components/Common/Button.vue"
import { ref } from 'vue'
import { useStore } from 'vuex'
const { allOptions, title } = defineProps(['allOptions', 'title'])

const { log } = console

const store = useStore()
const isLightboxOpen = ref(false)
const currentSelected = ref(null)

const toggleLightbox = (value, selected, payload) => {
    if (selected) currentSelected.value = selected.value
    // Toggle value & title
    isLightboxOpen.value = !isLightboxOpen.value
    if (typeof value === "number" && selected && payload) {
        selected.value = allOptions.find(vehicle => vehicle.id === value)
        store.commit('calloffCreation/setCalloffCreationData', {
            [payload]: selected.value
        })
    }
    return selected ? selected.value : currentSelected.value
}

defineExpose({
    toggleLightbox
})


</script>

<style scoped lang="sass">
.lightbox-container
    background-color: $text-blue
    bottom: 0
    left: 0
    position: fixed
    right: 0
    padding: 10px
    top: 53px
    transition: top 0.3s
    z-index: 9997

    &.hide
        top: 100%

    > p
        color: $white
        font-family: 'PoppinsMedium'

.section-wrapper
    background-color: $white
    border-radius: 5px
    display: flex
    flex-direction: column
    gap: 30px
    height: calc(100% - 100px)
    padding: 10px
    width: 100%

    @at-root .dark-mode &
        background-color: $text-black

.button-wrapper
    display: flex
    flex-wrap: wrap
    gap: 8px
    padding-top: 10px

.active-button
    color: $white
    background-color: $button-blue
    border-color: $button-blue

label,
button
    background-color: #FFFFFF
    border: 1px solid rgba(98, 112, 134, 0.5)
    border-radius: 5px
    color: #5F7088
    display: inline-block
    font-size: 13px
    letter-spacing: 0.03px
    line-height: 19px
    padding: 9px 12px
</style>

<style lang="sass">
.section
    flex: 0 0 min-content
    overflow: visible auto
    padding: 0 4px
    position: relative

    p
        font-size: 12px
        text-align: left

    &.flex-1
        flex: 1

    &:not(:first-child):before
        border-top: 1px solid $border-gray
        content: ""
        left: 0
        position: absolute
        top: -15px
        width: 100%

    > p
        color: $text-blue
        display: block
        font-family: "PoppinsSemiBold"
        margin-top: 0

        @at-root .dark-mode &
            color: $white
</style>