const imageResize = function (image, { maxWidth = 500, maxHeight = 500 }) {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()

        // Read the file
        reader.readAsDataURL(image)

        // Manage the `load` event
        reader.addEventListener('load', function (e) {
            try {
                // Create new image element
                const ele = new Image()
                ele.addEventListener('load', function () {
                    // Create new canvas
                    const canvas = document.createElement('canvas')

                    // Count propper ratio from options
                    const ratio = countResizeRatio(ele, { maxWidth, maxHeight })

                    // Draw the image that is scaled to `ratio`
                    const context = canvas.getContext('2d')
                    const w = ele.width * ratio
                    const h = ele.height * ratio
                    canvas.width = w
                    canvas.height = h
                    context.drawImage(ele, 0, 0, w, h)

                    // Get the data of resized image
                    'toBlob' in canvas
                        ? canvas.toBlob(function (blob) {
                            resolve(blob)
                        })
                        : resolve(dataUrlToBlob(canvas.toDataURL()))
                })

                // Set the source
                ele.src = e.target.result
            } catch(err) {
                reject(err)
            }
        })

        reader.addEventListener('error', function (e) {
            reject(e)
        })
    })
}

const countResizeRatio = (file, { maxWidth = 500, maxHeight = 500 }) => {
    const ratio = Math.min(maxWidth / file.width, maxHeight / file.height)

    return ratio < 1 ? ratio : 1
}

const dataUrlToBlob = function (url) {
    const arr = url.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const str = atob(arr[1])
    let length = str.length
    const uintArr = new Uint8Array(length)

    while (length--) {
        uintArr[length] = str.charCodeAt(length)
    }

    return new Blob([uintArr], { type: mime })
}

export {
    imageResize
}
