<script setup>
    const props = defineProps({
        data: Object,
        showFull: Boolean
    })

    const showFull = props.showFull
    const data = props.data

    const title = data.title
    const deliveryNoteNumber = data.delivery_note_number
    const creationDate = data.creation_display_date

    const materialName = data.material_name
    const quantity = data.quantity
    const quantity_unit = data.quantity_unit

    const materialCategory = data.material_category
    const materialNumber = data.material_number

    const constructionSiteName = data.construction_site_name
    const factoryName = data.factory_name
    const speditionName = data.spedition_name

    const weighingTime = data.weighing_time
    const weighingTimeUnit = data.weighing_time_unit

    const materials = data.materials

    const isSeen = data.is_seen
</script>

<template>
    <div class="list-item notification-delivery-note" :class="{ 'preview': !showFull }">
        <p class="creation-date">
            <img src="@/assets/icons/circle-weak-red-full.svg" v-if="!isSeen">
            {{ creationDate }}
        </p>

        <h4 class="title" :class="{'bold': !isSeen}">{{ title }}</h4>
        <p class="sub-title" v-if="deliveryNoteNumber">{{ 'on_site.notification.delivery_note.number_label'.trans() }}: {{ deliveryNoteNumber || '&mdash;' }}</p>

        <div class="material">
            <div class="delivery-note-material" v-for="item in materials" >
                <p class="material-title">
                    <span class="name">{{ item.material_name ? item.material_name : '—' }}</span>
                    <span class="quantity" v-if="quantity">
                    <img src="@/assets/icons/amount-gray.svg">
                    {{ item.quantity ? item.quantity + ' ' + item.quantity_unit : '—' }}
                </span>
                </p>
            </div>
            <table class="material-table" v-if="showFull">
                <tr v-if="materialCategory">
                    <td>
                        {{ 'on_site.notification.delivery_note.material_category_label'.trans() }}:
                    </td>
                    <td>
                        {{ materialCategory }}
                    </td>
                </tr>
                <tr v-if="materialNumber">
                    <td>
                        {{ 'on_site.notification.delivery_note.material_number_label'.trans() }}:
                    </td>
                    <td>
                        {{ materialNumber }}
                    </td>
                </tr>
            </table>
        </div>
        <div class="construction">
            <table class="construction-table">
                <tr v-if="constructionSiteName">
                    <td>
                        {{ 'on_site.notification.delivery_note.construction_site_label'.trans() }}:
                    </td>
                    <td>
                        {{ constructionSiteName }}
                    </td>
                </tr>
                <tr v-if="speditionName && showFull">
                    <td>
                        {{ 'on_site.notification.delivery_note.spedition_label'.trans() }}:
                    </td>
                    <td>
                        {{ speditionName }}
                    </td>
                </tr>
                <tr v-if="factoryName && showFull">
                    <td>
                        {{ 'on_site.notification.delivery_note.factory_label'.trans() }}:
                    </td>
                    <td>
                        {{ factoryName }}
                    </td>
                </tr>
                <tr v-if="weighingTime && showFull">
                    <td>
                        {{ 'on_site.notification.delivery_note.weigh_label'.trans() }}:
                    </td>
                    <td>
                        {{ weighingTime }} {{ weighingTimeUnit }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.dark-mode
    .notification-delivery-note
        background-color: $dark-button-gray
        border: 1px solid $component-border

        .creation-date
            color: $dark-text-color

        .title
            color: $white

        .sub-title
            color: $dark-text-color

        .material
            .material-title
                .name
                    color: $white

                .quantity
                    color: $dark-text-color

            .material-table
                tr
                    td
                        color: $white

        .construction
            .construction-table
                tr
                    td
                        color: $white

        .delivery-date
            .delivery-start, .delivery-end
                p
                    color: $white

            .delivery
                color: $white

.notification-delivery-note
    background: $white
    text-decoration: none

    padding-left: 12px
    padding-right: 15px
    padding-bottom: 20px
    padding-top: 2px

    text-align: left
    border-radius: 4px
    box-shadow: 0 2px 2px #00000012

    .creation-date
        margin: 0
        margin-right: -8px
        text-align: right

        color: $gray-blue
        font-family: 'PoppinsRegular'
        font-size: 12px
        line-height: 24px

        img
            margin-right: 3px

    .title
        margin-top: 0
        margin-bottom: 4px

        color: $text-black
        font-size: 18px
        font-weight: normal
        font-family: 'PoppinsMedium'
        line-height: 24px
        text-transform: uppercase

        &.bold
            font-family: PoppinsBold

    .sub-title
        margin-top: 0
        margin-bottom: 6px

        color: $gray-blue
        font-size: 14px
        font-weight: normal
        font-family: 'PoppinsBold'
        line-height: 21px
        letter-spacing: 0

    .material
        padding-left: 4px
        padding-right: 4px

        padding-top: 12px
        padding-bottom: 8px

        border-top: 1px solid $border-gray
        border-bottom: 1px solid $border-gray

        .material-title
            margin-top: 0
            margin-bottom: 3px

            display: flex
            flex-direction: row

            .name
                font-family: 'PoppinsSemiBold'
                color: $text-black
                font-size: 13px
                line-height: 20px

                flex-grow: 1

                vertical-align: top

            .quantity
                float: right

                font-family: 'PoppinsSemiBold'
                color: $gray-blue
                font-size: 13px
                line-height: 20px

                img
                    height: 12px
                    width: auto
                    vertical-align: middle

                    margin-right: 5px

        .material-table
            border-collapse: collapse

            tr
                td
                    padding: 0
                    color: $text-black
                    font-family: 'PoppinsMedium'
                    font-size: 12px
                    line-height: 22px

                    vertical-align: top

                    &:first-child
                        padding-right: 12px

    .construction
        padding-top: 7px
        padding-left: 3px
        padding-right: 3px

        .construction-table
            tr
                td
                    padding: 0
                    color: $text-black
                    font-family: 'PoppinsMedium'
                    font-size: 12px
                    line-height: 22px

                    vertical-align: top

                    &:first-child
                        padding-right: 12px

    // List only
    &.preview
        padding-bottom: 16px

        .material
            padding-bottom: 0
            border-bottom: none

        .construction
            padding-top: 0

</style>